import { Grid, makeStyles } from "@material-ui/core";
import { AccountBox } from "@material-ui/icons";
import React, { useState } from "react";
import CapacityNeedsUpdateForm from "./CapacityNeedsUpdateForm";
import ContactInformationUpdateForm from "./ContactInformationUpdateForm";
import PersonalDetailsForm from "./PersonalDetailsUpdateForm";
import QualificationsUpdateForm from "./QualificationsUpdateForm";
import WorkInformationUpdateForm from "./WorkInformationUpdateForm";
import SectionHeader from "../SectionHeader";
import { useTable } from "../useTable";
import CollapsableSection from "../CollapsableSection";

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(2),
  },
}));

export default function WorkerProfile(props) {
  const { profile, history, reloadPage } = props;
  const { TblContainer, SectionLabelValueMultiRow, SectionLongLabelValueRow } = useTable();
  const classes = useStyles();
  const [openPopup, setOpenPopup] = useState(false);
  const [openContactPopup, setOpenContactPopup] = useState(false);
  const [openQualificationPopup, setOpenQualificationPopup] = useState(false);
  const [openWorkInfoPopup, setOpenWorkInfoPopup] = useState(false);
  const [openCapacityPoup, setOpenCapacityPoup] = useState(false);


  const biodata = [
    { label: "AEAS ID", value: profile.PUID },
    { label: "Name", value: profile.FirstName + " " + profile.LastName },
    { label: "Category", value: profile.Category },
    { label: "D.O.B", value: profile.Dob },
    { label: "Email", value: profile.Email },
    { label: "NIN", value: profile.Nin },
    { label: "Telephone", value: profile.Telephone1 },
    { label: "Gender", value: profile.Sex },
  ];

  const contactInfo = [
    { label: "Telephone1", value: profile.Telephone1 },
    { label: "Telephone2", value: profile.Telephone2 },
    { label: "Email", value: profile.Email },
    {
      label: "District of registration",
      value: profile.DistrictOfRegistration,
    },
  ];

  const Qualifications = [
    {
      label: "Field of agriculture-related qualification",
      value: profile.AgricRelQual,
    },
    profile.HighestQual && profile.HighestQual !== "" && {
      label: "Highest qualification",
      value: profile.HighestQual,
    },
    profile.OtherHighestQual && profile.OtherHighestQual !== "" && {
      label: "Highest qualification",
      value: profile.OtherHighestQual,
    },
    { label: "Year of qualification", value: profile.YearOfQual },
    profile.SupportSkillA && profile.SupportSkillA  !== "" && {
      label: "Support skill A",
      value: profile.SupportSkillA,
    },
    profile.QualSupportSkillA &&  profile.QualSupportSkillA !== "" && {
      label: "Qualification suppor skill A",
      value: profile.QualSupportSkillA,
    },
    profile.DurationSkillA && profile.DurationSkillA !== "" && {
      label: "Duration skill A",
      value: profile.DurationSkillA,
    },
    profile.SupportSkillB && profile.SupportSkillB !== "" && {
      label: "Support skill B",
      value: profile.SupportSkillB,
    },
    profile.QualSupportSkillB && profile.QualSupportSkillB !== "" && {
      label: "Qualification suppor skill B",
      value: profile.QualSupportSkillB,
    },
    profile.DurationSkillB && profile.DurationSkillB !== "" && {
      label: "Duration skill B",
      value: profile.DurationSkillB,
    },
    profile.SupportSkillC && profile.SupportSkillC !== "" && {
      label: "Support skill C",
      value: profile.SupportSkillC,
    },
    profile.QualSupportSkillC && profile.QualSupportSkillC !== "" && {
      label: "Qualification suppor skill C",
      value: profile.QualSupportSkillC,
    },
    profile.DurationSkillC && profile.DurationSkillC !== "" && {
      label: "Duration skill C",
      value: profile.DurationSkillC,
    },
  ];

  const workInfo = [
    { label: "Work status", value: profile.WorkStatus },
    { label: "Work place organization/ firm", value: profile.WrkplaceOrg },
    { label: "Position", value: profile.Position },
    {
      label: "Sector of the organization/ firm",
      value: profile.SectorOfOrganization,
    },
    profile.LevOfOpnInPubSec && profile.LevOfOpnInPubSec !== "" && {
      label: "Level of operation in public sector",
      value: profile.LevOfOpnInPubSec,
    },
    { label: "Your agricultural focus", value: profile.AgricFocus },

    profile.AgricFocusValChainFocLev && profile.AgricFocusValChainFocLev !== "" && 
    {
      label: "Value chain focus level",
      value: profile.AgricFocusValChainFocLev,
    },
    profile.AgricFocusEnterp && profile.AgricFocusEnterp !== "" &&
    { label: "Focus enterprise", value: profile.AgricFocusEnterp },
    {
      label: "Area of operations (Regions)",
      value: profile.AreaOfOpsTargetDistricts,
    },
    profile.CentralDistricts && profile.CentralDistricts !== "" && {
      label: "Central districts",
      value: profile.CentralDistricts,
    },
    profile.EastDistricts && profile.EastDistricts !== "" && {
      label: "East districts",
      value: profile.EastDistricts,
    },
    profile.WestDistricts && profile.WestDistricts !== "" && {
      label: "Western districts",
      value: profile.WestDistricts,
    },
    profile.NorthDistricts && profile.NorthDistricts !== "" && {
      label: "Northern districts",
      value: profile.NorthDistricts,
    },
    { label: "Key beneficiaries", value: profile.KeyBenef },
    profile.KeyBenefFarmersCat && profile.KeyBenefFarmersCat !== "" && {
      label: "If farmers, which category",
      value: profile.KeyBenefFarmersCat,
    },
    profile.KeyBenefFarmersGender && profile.KeyBenefFarmersGender !== "" && {
      label: "If farmers, which gender",
      value: profile.KeyBenefFarmersGender,
    },
  ];

  const capacityNeeds = [
    {
      label: "Capacity needs 1st choice",
      value: profile.CapacityNeedFirstChoice,
    },
    {
      label: "Capacity needs 2nd choice",
      value: profile.CapacityNeedSecondChoice,
    },
    {
      label: "Capacity needs 3rd choice",
      value: profile.CapacityNeedThirdChoice,
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12} className={classes.section}>
          {/* Profile */}
          <h1>Personal Details</h1>
          <SectionHeader
            onClick={() => {
              setOpenPopup(true);
            }}
            title=""
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            popupTitle="Update personal details"
          >
            <PersonalDetailsForm
              closePopup={() =>{setOpenPopup(false)}}
              reloadPage={reloadPage}
              history={history}
              data={profile}
            />
          </SectionHeader>
          <Grid container>
            <SectionLabelValueMultiRow data={biodata} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} className={classes.section}>
          <CollapsableSection title="Contact information" defaultExpanded="true" icon="fa-solid fa-message">
            {/* Contact information */}
            <SectionHeader
                onClick={() => {
                  setOpenContactPopup(true);
                }}
                title=""
                openPopup={openContactPopup}
                setOpenPopup={setOpenContactPopup}
                popupTitle="Update contact information"
            >
              <ContactInformationUpdateForm
                  data={profile}
                  closePopup={()=>{ setOpenContactPopup(false); }}
                  reloadPage={reloadPage}
                  history={history}
              />
            </SectionHeader>
            <br/>
            <SectionLabelValueMultiRow data={contactInfo} />
            <br/><br/>
          </CollapsableSection>
        </Grid>
        <Grid item xs={12} md={12} className={classes.section}>
          <CollapsableSection title="Qualifications" defaultExpanded="true" icon="fa-solid fa-graduation-cap">
            <SectionHeader
                onClick={() => {
                  setOpenQualificationPopup(true);
                }}
                title=""
                openPopup={openQualificationPopup}
                setOpenPopup={setOpenQualificationPopup}
                popupTitle="Update qualifications"
            >
              <QualificationsUpdateForm
                  data={profile}
                  closePopup={()=>{ setOpenQualificationPopup(false); }}
                  reloadPage={reloadPage}
                  history={history}

              />
            </SectionHeader>
            <br/>
            <SectionLongLabelValueRow data={Qualifications} />
            <br/><br/>
          </CollapsableSection>
        </Grid>
        <Grid item xs={12} md={12} className={classes.section}>
          <CollapsableSection title="Work information" defaultExpanded="true" icon="fa-solid fa-briefcase">
            <SectionHeader
                onClick={() => {
                  setOpenWorkInfoPopup(true);
                }}
                title=""
                openPopup={openWorkInfoPopup}
                setOpenPopup={setOpenWorkInfoPopup}
                popupTitle="Update work information"
            >
              <WorkInformationUpdateForm
                  data={profile}
                  closePopup={()=>{ setOpenWorkInfoPopup(false); }}
                  reloadPage={reloadPage}
                  history={history}
              />
            </SectionHeader>
            <br />
            <SectionLongLabelValueRow data={workInfo} />
            <br/><br/>
          </CollapsableSection>
        </Grid>
        <Grid item xs={12} md={12} className={classes.section}>
          <CollapsableSection title="Capacity needs" defaultExpanded="true" icon="fa-solid fa-file-lines">
            <SectionHeader
                onClick={() => {
                  setOpenCapacityPoup(true);
                }}
                title=""
                openPopup={openCapacityPoup}
                setOpenPopup={setOpenCapacityPoup}
                popupTitle="Update capacity needs"
            >
              <CapacityNeedsUpdateForm
                  data={profile}
                  closePopup={()=>{ setOpenCapacityPoup(false); }}
                  reloadPage={reloadPage}
                  history={history}
              />
            </SectionHeader>
            <br/>
            <SectionLabelValueMultiRow data={capacityNeeds} />
            <br/><br/>
          </CollapsableSection>
        </Grid>
      </Grid>
    </>
  );
}
