import React from 'react'
import {  Button as MuiButton, makeStyles} from '@material-ui/core'


const useStyles = makeStyles(theme=>({
    root:{
        margin: theme.spacing(0.5)
    },
    label:{
        textTransform: 'none'
    }
}))

function Button(props) {
    const { text, size, color, variant, onClick, ...other } = props;
    const classes = useStyles();

    return (
        <MuiButton
            variant={variant || 'outlined'}
            size={size || "large"}
            color={color || "primary"}
            onClick={onClick}
            {...other}
            classes={{ root: classes.root, label: classes.label }}
        >
            {text}
            {props.children}
        </MuiButton>
    )
}

export default Button
