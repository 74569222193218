import authService from "../services/auth.service";
import userService from "../services/user.service";
import {
  LOGOUT,
  SET_MESSAGE,
  UPDATE_INDIVIDUAL_DETAILS_FAIL,
  UPDATE_INDIVIDUAL_DETAILS_SUCCESS,
  UPDATE_INDIVIDUAL_CONTACT_SUCCESS,
  UPDATE_INDIVIDUAL_CONTACT_FAIL,
  UPDATE_INDIVIDUAL_QUALIFICATIONS_SUCCESS,
  UPDATE_INDIVIDUAL_QUALIFICATIONS_FAIL,
  UPDATE_INDIVIDUAL_WORK_INFO_SUCCESS,
  UPDATE_INDIVIDUAL_WORK_INFO_FAIL,
  UPDATE_INDIVIDUAL_CAPACITY_NEEDS_SUCCESS,
  UPDATE_INDIVIDUAL_CAPACITY_NEEDS_FAIL,

} from "./types";

export const updatePersonalDetails = (id, formData) => (dispatch) => {
  if (!authService.tokenIsValid()) {
    console.log("USER LOGGED OUT FOR EXPIRED TOKEN");
    dispatch({
      type: LOGOUT,
    });
    return Promise.resolve();
  }

  return userService.UpdateIndividualPersonalDetails(id, formData).then(
    (data) => {
      dispatch({
        type: UPDATE_INDIVIDUAL_DETAILS_SUCCESS,
        payload: {
          userEmail: data,
        },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(error);
      console.log(message);
      dispatch({
        type: UPDATE_INDIVIDUAL_DETAILS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const actionUpdateWorkerContactInfo = (id, formData) => (dispatch) => {
  if (!authService.tokenIsValid()) {
    console.log("USER LOGGED OUT FOR EXPIRED TOKEN");
    dispatch({
      type: LOGOUT,
    });
    return Promise.resolve();
  }

  return userService.UpdateIndividualContactInfo(id, formData).then(
    (data) => {
      dispatch({
        type: UPDATE_INDIVIDUAL_CONTACT_SUCCESS,
        payload: {},
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(error);
      console.log(message);
      dispatch({
        type: UPDATE_INDIVIDUAL_CONTACT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const actionUpdateWorkerQualifications = (id, formData) => (
  dispatch
) => {
  if (!authService.tokenIsValid()) {
    console.log("USER LOGGED OUT FOR EXPIRED TOKEN");
    dispatch({
      type: LOGOUT,
    });
    return Promise.resolve();
  }

  return userService.UpdateIndividualQualifications(id, formData).then(
    (data) => {
      dispatch({
        type: UPDATE_INDIVIDUAL_QUALIFICATIONS_SUCCESS,
        payload: {},
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(error);
      console.log(message);
      dispatch({
        type: UPDATE_INDIVIDUAL_QUALIFICATIONS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const actionUpdateWorkerWorkInfo = (id, formData) => (dispatch) => {
  if (!authService.tokenIsValid()) {
    console.log("USER LOGGED OUT FOR EXPIRED TOKEN");
    dispatch({
      type: LOGOUT,
    });
    return Promise.resolve();
  }

  return userService.UpdateIndividualWorkInfo(id, formData).then(
    (data) => {
      dispatch({
        type: UPDATE_INDIVIDUAL_WORK_INFO_SUCCESS,
        payload: {},
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(error);
      console.log(message);
      dispatch({
        type: UPDATE_INDIVIDUAL_WORK_INFO_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const actionUpdateWorkerCapacityNeeds = (id, formData) => (dispatch) => {
    if (!authService.tokenIsValid()) {
      console.log("USER LOGGED OUT FOR EXPIRED TOKEN");
      dispatch({
        type: LOGOUT,
      });
      return Promise.resolve();
    }
  
    return userService.UpdateIndividualCapacityNeeds(id, formData).then(
      (data) => {
        dispatch({
          type: UPDATE_INDIVIDUAL_CAPACITY_NEEDS_SUCCESS,
          payload: {},
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(error);
        console.log(message);
        dispatch({
          type: UPDATE_INDIVIDUAL_CAPACITY_NEEDS_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };