import { Grid, makeStyles } from "@material-ui/core";
import { AccountBox } from "@material-ui/icons";
import React from "react";
import GuestSectionHeader from "../GuestSectionHeader";
import { useTable } from ".././useTable";
import CollapsableSection from "../CollapsableSection";

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(2),
  },
}));

function readableDate(dd) {
  let date = new Date(dd);
  let dateMDY = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  return dateMDY;
}

function OrganizationProfileForGuest(props) {
  const { profile } = props;
  const { TblContainer, SectionLabelValueMultiRow } = useTable();
  const classes = useStyles();

  const AboutOrg = [
    { label: "AEAS ID", value: profile.Id },
    { label: "Name of organization", value: profile.Name },
    { label: "Type of registration", value: profile.TypeOfReg },
    { label: "Date of registration", value: profile.DateOfIncorp != "" ? readableDate(profile.DateOfIncorp): "" },
    { label: "Registration No. of entity", value: profile.RegNoEntity },
    { label: "Email address", value: profile.Email },
    { label: "Country", value: profile.Country },
    { label: "Telephone", value: profile.Telephone },
    { label: "Website", value: profile.Website },
    { label: "Postal address", value: profile.PostalAddress },
  ];

  const HeadOfficeLoc = [
    { label: "District", value: profile.HeadOfficeDistrict },
    { label: "Sub-county/Division", value: profile.HeadOfficeDiv },
    { label: "Village", value: profile.HeadOfficeVillage },
    { label: "Street", value: profile.HeadOfficeStreet },
  ];

  const ContactPerson = [
    { label: "Contact name", value: profile.ContName },
    { label: "Position", value: profile.ContPosition },
    { label: "Contact telephone", value: profile.ContTelephone },
    { label: "Email", value: profile.ContEmail },
  ];

  const Staff = [
    { label: "Males", value: profile.NumStaffMale },
    { label: "Females", value: profile.NumStaffFemale },
    { label: "Males at PhD level", value: profile.PhdMales },
    { label: "Females at PhD level", value: profile.PhdFemales },
    { label: "Males at Masters level", value: profile.MastrsMales },
    { label: "Females at Masters level", value: profile.MastrsFemales },
    { label: "Males at Bsc level", value: profile.BscMale },
    { label: "Females at Bsc level", value: profile.BscFemale },
    { label: "Males at Diploma level", value: profile.DipMale },
    { label: "Females at Diploma level", value: profile.DipFemale },
    { label: "Males at Certificate level", value: profile.CertMale },
    { label: "Females at Certificate level", value: profile.CertFemale },
  ];

  const SectorInfo = [
    { label: "Sector", value: profile.Sector },
    { label: "Agricultural sector focus", value: profile.AgricSectorFocus },
    // optional
    profile.AgricFocusValChainFocLev !== "" && {
      label: "Value chain focus level",
      value: profile.AgricFocusValChainFocLev,
    },
    profile.AgricFocusEnterp !== "" && {
      label: "Focus enterprises",
      value: profile.AgricFocusEnterp,
    },
    // end optional
    { label: "Mission", value: profile.Mission },
    { label: "Area of operation", value: profile.AreaOpnTrgtDistricts },
    // optional
    profile.CentralDistricts !== "" && {
      label: "Central districts",
      value: profile.CentralDistricts,
    },
    profile.EastDistricts !== "" && {
      label: "Eastern districts",
      value: profile.EastDistricts,
    },
    profile.WestDistricts !== "" && {
      label: "Western districts",
      value: profile.WestDistricts,
    },
    profile.NorthDistricts !== "" && {
      label: "Northern districts",
      value: profile.NorthDistricts,
    },
    // end optional
    { label: "Key beneficiaries", value: profile.KeyBenefs },
    // optional
    profile.KeyBeneFrmrsCateg !== "" && {
      label: "If farmers, which category",
      value: profile.KeyBeneFrmrsCateg,
    },
    profile.KeyBeneFrmrsGender !== "" && {
      label: "If farmers, which gender",
      value: profile.KeyBeneFrmrsGender,
    },
    // end optional
  ];

  const CapacityNeeds = [
    {
      label: "Capacity needs 1st choice",
      value: profile.CapacityNeedFirstChoice,
    },
    {
      label: "Capacity needs 2nd choice",
      value: profile.CapacityNeedSecondChoice,
    },
    {
      label: "Capacity needs 3rd choice",
      value: profile.CapacityNeedThirdChoice,
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item sm={12} md={12} className={classes.section}>
          {/* About organization */}
          <h1>About organization</h1>
            <SectionLabelValueMultiRow data={AboutOrg} />
        </Grid>
        <Grid item sm={12} md={12} className={classes.section}>
          {/* Head office location */}
          <CollapsableSection title="Head office location" defaultExpanded="true" icon="fa-solid fa-location-pin">
            <SectionLabelValueMultiRow data={HeadOfficeLoc} />
            <br/><br/>
          </CollapsableSection>
        </Grid>
        <Grid item sm={12} md={12} className={classes.section}>
          {/* Contact person */}
          <CollapsableSection title="Contact person" defaultExpanded="true" icon="fa-solid fa-message">
            <SectionLabelValueMultiRow data={ContactPerson} />
            <br/><br/>
          </CollapsableSection>
        </Grid>
        <Grid item sm={12} md={12} className={classes.section}>
          {/* AEAS staff */}
          <CollapsableSection title="Staff" defaultExpanded="true" icon="fa-solid fa-users">
            <SectionLabelValueMultiRow data={Staff} />
            <br/><br/>
          </CollapsableSection>
        </Grid>
        <Grid item sm={12} md={12} className={classes.section}>
          {/* Sector info */}
          <CollapsableSection title="Sector information" defaultExpanded="true" icon="fa-solid fa-file-lines">
            <SectionLabelValueMultiRow data={SectorInfo} />
            <br/><br/>
          </CollapsableSection>
        </Grid>
        <Grid item sm={12} md={12} className={classes.section}>
          {/* Capacity needs */}
          <CollapsableSection title="Capacity needs" defaultExpanded="true" icon="fa-solid fa-file-lines">
            <SectionLabelValueMultiRow data={CapacityNeeds} />
            <br/><br/>
          </CollapsableSection>
        </Grid>
      </Grid>
    </>
  );
}

export default OrganizationProfileForGuest;
