import Input from './Input'
import Button from './Button'
import ActionButton from './ActionButton'
import FormikSelect from './FormikSelect'
import FormikRadioGroup from './FormikRadioGroup'
import ManageToolbar from './ManageToolbar'
import GuestToolbar from './GuestToolbar'
import SubmitButton from './SubmitButton'

const Controls = {
    Input,
    Button,
    ActionButton,
    FormikSelect,
    FormikRadioGroup,
    ManageToolbar,
    SubmitButton,
    GuestToolbar
}

export default Controls;