import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Field, Form } from 'formik';
import { KeyboardDatePicker } from "formik-material-ui-pickers";

import { TextField } from "formik-material-ui";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Controls from "../controls/Controls";
import { useDispatch } from "react-redux";
import { updatePersonalDetails } from "../../actions/worker";


const useStyles = makeStyles((theme) => ({
    tForm: {
        '& .MuiFormControl-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        }
    }
}))

function PersonalDetailsUpdateForm(props) {

    const { data, closePopup, reloadPage, history } = props;
    const dispatch = useDispatch();

    const classes = useStyles()

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
                <Grid md={2} xs={12} item />
                <Grid md={8} xs={12} item>
                    <>
                        <Formik
                            initialValues={{
                                // category: data.category,
                                // nin: data.nin,
                                // passportNo: data.passportNo,
                                // workPermitNo: data.workPermitNo,
                                // workPermitExpiryDate: data.workPermitExpiryDate,
                                firstName: data.FirstName,
                                lastName: data.LastName,
                                dob: data.Dob,
                                sex: data.Sex,
                            }}

                            validationSchema={Yup.object({
                                firstName: Yup.string()
                                    .min(2, "Too Short!")
                                    .max(50, "Too Long!")
                                    .required("Required"),
                                lastName: Yup.string()
                                    .min(2, "Too Short!")
                                    .max(50, "Too Long!")
                                    .required("Required"),
                                dob: Yup.date().nullable()
                                    .max(new Date(), "Date of birth must be a past date")
                                    .required("Date of Birth is required"),
                                sex: Yup.string()
                                    .nullable()
                                    .required("Select Gender")
                                    .min(2, "Select Gender"),
                            })}
                            onSubmit={async (values, { setSubmitting }) => {
                                await dispatch(updatePersonalDetails(data.id, values))
                                    .then(() => {
                                        history.push(
                                            `/manage-worker/` + data.id + `/details`
                                        );
                                        setSubmitting(false);
                                        reloadPage();
                                        closePopup();
                                    })
                                    .catch((e) => {
                                        setSubmitting(false);
                                        console.log("an error for occured ");
                                        console.log(e);
                                    })
                            }}
                        >
                            {({
                                isSubmitting,
                                isValid,
                            }) => (
                                <Form autoComplete="off" className={classes.tForm}>
                                    <Field
                                        fullWidth
                                        size="small"
                                        name="firstName"
                                        component={TextField}
                                        label="First Name"
                                        variant="outlined"
                                    />
                                    <Field
                                        fullWidth
                                        size="small"
                                        name="lastName"
                                        component={TextField}
                                        label="Last Name"
                                        variant="outlined"
                                    />
                                    <Field
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        component={KeyboardDatePicker}
                                        label="Date of birth"
                                        name="dob"
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        InputAdornmentProps={{ position: "start" }}
                                    />
                                    <Field fullWidth name="sex">
                                        {({
                                            meta,
                                            form: { setFieldValue, values }
                                        }) => (
                                            <Controls.FormikSelect
                                                name="sex"
                                                value={values.sex}
                                                options={["Male", "Female"]}
                                                label="Gender"
                                                placeholder="Gender"
                                                setFieldValue={setFieldValue}
                                                meta={meta}
                                            />
                                        )}
                                    </Field>

                                    <Controls.SubmitButton
                                        isSubmitting={isSubmitting}
                                        isValid={isValid}
                                    />
                                </Form>
                            )}
                        </Formik>
                    </>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    )
}

export default PersonalDetailsUpdateForm
