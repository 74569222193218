import { IconButton, Typography } from '@material-ui/core';
import { ArrowBackTwoTone } from '@material-ui/icons';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { organizationFetchDetailsById } from '../actions/user';
import OrganizationProfile from '../components/organizations/OrganizationProfile';
import PageHeader from '../components/PageHeader';

function OrganizationDetails(props) {

    const uuid = props.match.params.id;
    const dispatch = useDispatch();
    const history = props.history;

    useEffect(() => {
        reloadPage();

    }, []);

    const org = useSelector((state) => state.organization.organization);
    const isFetching = useSelector((state) => state.organization.isFetching);

    const reloadPage = () => {
        dispatch(organizationFetchDetailsById(uuid))
            .then(() => { })
            .catch(() => {
                console.log("AN ERROR OCCURED");
            });
    };

    return (
        <>
            {isFetching ? (
                <Typography variant="subtitle1">Fetching....</Typography>
            ) : null}

            <PageHeader
                title="Organization"
                subtitle="Manage details"
                icon={
                    <IconButton>
                        <a href="\manage-organizations">
                            <ArrowBackTwoTone />
                        </a>
                    </IconButton>
                }
            />

            <OrganizationProfile
                profile={org}
                history={history}
                reloadPage={reloadPage}
            />
        </>
    )
}

export default OrganizationDetails
