import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Search } from "@material-ui/icons";
import UserService from "../services/user.service";
import { BrowserRouter as Router } from "react-router-dom";

const useClasses = makeStyles((theme) => ({
  image: {
    "& img": {
      display: "block",
    },
    "& .MuiTypography-caption": {
      textDecoration: "italic",
      color: theme.palette.primary.main
    },
  },
}));

const Home = () => {
  const classes = useClasses();

  const [, setWorkers] = useState([]);

  useEffect(() => {
    UserService.getPublicWorkersContent().then(
      (response) => {
        setWorkers(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        console.log(_content);
        // setWorkers(_content);
      }
    );
  }, []);

  return (
    <Router>
      <Grid container>
        <Grid md={12} xs={12} item>
          <>
            {/* Top content */}
            <Grid
              container
              style={{
                margin: "80px 30px",
              }}
            >
              <Grid xs={4} item style={{ margin: "10px 10px" }}>
                <Typography variant="h2" align="right">
                  AEAS
                </Typography>
                <Typography variant="caption" style={{ float: "right" }}>
                  Our online database portal
                </Typography>
              </Grid>
              <Grid
                xs={6}
                item
                style={{
                  margin: "10px 10px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="body1"
                  align="left"
                  style={{ padding: "5px 10px" }}
                >
                  National Agricultural Extension and Advisory Services
                </Typography>
                <Typography
                  variant="h5"
                  align="left"
                  style={{ padding: "5px 10px" }}
                >
                  e-Registration Portal
                </Typography>

                <Grid
                  container
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "40px",
                  }}
                >
                  <Typography variant="caption" color="primary">
                    Search for registered members
                  </Typography>
                </Grid>

                <Grid
                  container
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {/* <Link to={""}> */}
                  <Button variant="outlined" color="primary" size="medium">
                    <a
                      href="/workers"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Extension workers
                    </a>
                    <Divider
                      orientation="vertical"
                      style={{ margin: "0px 5px", height: 28 }}
                    />
                    <Search color="primary" />
                  </Button>
                  {/* </Link> */}
                  &nbsp;
                  <Button variant="outlined" color="primary" size="medium">
                    <a
                      href="/organizations"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Organizations
                    </a>
                    <Divider
                      orientation="vertical"
                      style={{ margin: "0px 5px", height: 28 }}
                    />
                    <Search color="primary" />
                  </Button>
                </Grid>
              </Grid>
              <Grid xs={2} item></Grid>
            </Grid>

            {/* Mission content */}
            <Grid container style={{ margin: "30px 0px" }}>
              <Grid item xs={1}></Grid>
              <Grid item xs={8}>
                <Typography variant="h6">Our Mission</Typography>
                <Typography variant="body1">
                  To promote professionalism, accountability and ethical conduct
                  among the AEAS actors for increased efficiency and
                  effectiveness of the agricultural extension system.
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>

              <Grid item xs={12} style={{ margin: "10px 30px" }}>
                <Divider />
              </Grid>
            </Grid>

            {/* Staff members */}
            <Grid container>
              <Grid item xs={1}></Grid>
              <Grid item xs={6}>
                <div className={classes.image}>
                  <img src={`./members.jpg`} alt="Members" />
                  <Typography variant="caption">Image: AEAS Members</Typography>
                </div>
              </Grid>
            </Grid>

            {/* Footer */}
            <Grid container style={{ marginTop: "80px" }}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography variant="caption" align="center">
                  Uganda National AEAS e-Registration | Developed by Dostrim
                  limited
                </Typography>
              </Grid>
            </Grid>
          </>
        </Grid>
      </Grid>

      <>
        {/* <Switch>
          <Route exact path={["/", "/home"]} component={Home} />
          <Route exact path="/workers" component={Login} />
          <Route exact path="/organizations" component={Register} />
        </Switch> */}
      </>
    </Router>
  );
};

export default Home;
