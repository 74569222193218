import React, { useEffect, useState } from "react";
import PageHeader from "../components/PageHeader";
import { PeopleAltOutlined } from "@material-ui/icons";
import { useTable } from "../components/useTable";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { workersFetchAll } from "../actions/user";
import Controls from "../components/controls/Controls";

const headCells = [
  { id: "workerID", label: "Worker ID" },
  { id: "fname", label: "First name" },
  { id: "lname", label: "Last name" },
  { id: "email", label: "Email" },
  { id: "areas", label: "Areas of operation" },
  { id: "keybenefs", label: "Key beneficiaries" },
  { id: "actions", label: "Action" },
];
function GuestWorkers() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(workersFetchAll())
      .then(() => {})
      .catch(() => {
        console.log("AN ERROR OCCURED");
      });
  }, []);

  const workers = useSelector((state) => state.worker.workers);
  const isFetching = useSelector((state) => state.worker.isFetching);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingaAndSorting,
  } = useTable(workers.data, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter(
            (x) =>
              x.firstName.toLowerCase().includes(target.value.toLowerCase()) ||
              x.lastName.toLowerCase().includes(target.value.toLowerCase()) ||
              x.email.toLowerCase().includes(target.value.toLowerCase())
          );
      },
    });
  };

  return (
    <>
      <PageHeader
        icon={<PeopleAltOutlined />}
        title="Extension workers"
        subtitle="List of registered workers"
      />

      <Controls.GuestToolbar
        searchLabel="Search worker"
        handleSearch={handleSearch}
      />
      <TblPagination />
      <TblContainer>
        <TblHead />
        <TableBody>
          {recordsAfterPagingaAndSorting().map((worker) => (
            <TableRow key={worker.Id}>
              <TableCell>{worker.PUID}</TableCell>
              <TableCell>{worker.FirstName}</TableCell>
              <TableCell>{worker.LastName}</TableCell>
              <TableCell>{worker.Email}</TableCell>
              <TableCell>{worker.AreaOfOpsTargetDistricts}</TableCell>
              <TableCell>{worker.KeyBenef}</TableCell>
              <TableCell>
                <a href={`/worker/` + worker.Id + `/details`}>All Details</a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TblContainer>
      <TblPagination />
      {isFetching ? "Loading..." : null}
    </>
  );
}

export default GuestWorkers;
