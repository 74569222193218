import {
    START_USERS_FETCH,
    FETCH_ALL_USERS_SUCCESS,
    FETCH_ALL_USERS_FAIL,
    FINISH_USERS_FETCH,
    REGISTER_WORKER_SUCCESS,
    REGISTER_WORKER_FAIL,
    REGISTER_ORGANIZATION_SUCCESS,
    REGISTER_ORGANIZATION_FAIL,
    SET_MESSAGE,
    FETCH_ALL_WORKERS_SUCCESS,
    FETCH_ALL_WORKERS_FAIL,
    FETCH_ALL_ORGANIZATIONS_SUCCESS,
    FETCH_ALL_ORGANIZATIONS_FAIL,
    LOGOUT,
    FETCH_ROLES_SUCCESS,
    FETCH_ROLES_FAIL,
    FETCH_ALL_WORKERS,
    FETCH_ALL_ORGANIZATIONS,
    FETCH_A_WORKER_BY_ID,
    FETCH_A_WORKER_BY_ID_SUCCESS,
    FETCH_A_WORKER_BY_ID_FAIL,
    FETCH_AN_ORGANIZATION_BY_ID,
    FETCH_AN_ORGANIZATION_BY_ID_SUCCESS,
    FETCH_AN_ORGANIZATION_BY_ID_FAIL,
    FETCH_A_USER_BY_EMAIL,
    FETCH_A_USER_BY_EMAIL_SUCCESS,
    FETCH_A_USER_BY_EMAIL_FAIL,

    FETCH_WORKER_PROFILE_FOR_GUEST_BY_ID,
    FETCH_WORKER_PROFILE_FOR_GUEST_BY_ID_SUCCESS,
    FETCH_WORKER_PROFILE_FOR_GUEST_BY_ID_FAIL,

    FETCH_ORGANIZAION_PROFILE_FOR_GUEST_BY_ID,
    FETCH_ORGANIZAION_PROFILE_FOR_GUEST_BY_ID_SUCCESS,
    FETCH_ORGANIZAION_PROFILE_FOR_GUEST_BY_ID_FAIL,

    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,

} from "./types";

import UserService from "../services/user.service";
import AuthService from "../services/auth.service"

export const startUsersFetch = () => ({
    type: START_USERS_FETCH,
});


export const fetchAllRoles = () => (dispatch) => {
    return UserService.getAllRoles().then(
        (data) => {
            dispatch({
                type: FETCH_ROLES_SUCCESS,
                payload: {
                    data
                }
            })
            return Promise.resolve();
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
            dispatch({
                type: FETCH_ROLES_FAIL
            })
            dispatch({
                type: SET_MESSAGE,
                payload: message
            })

            return Promise.reject()
        }
    )
}

export const fetchAllUsers = () => (dispatch) => {

    dispatch({
        type: START_USERS_FETCH
    })

    return UserService.getUsersList().then(
        (data) => {
            dispatch({
                type: FETCH_ALL_USERS_SUCCESS,
                payload: {
                    data
                }
            })

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: FETCH_ALL_USERS_FAIL,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        })
}

export const finishUsersFetch = () => ({
    type: FINISH_USERS_FETCH,
});


export const workerRegisteration = (formData) => (dispatch) => {
    if (!AuthService.tokenIsValid()) {
        console.log("USER LOGGED OUT FOR EXPIRED TOKEN")
        dispatch({
            type: LOGOUT
        })
        return Promise.resolve();
    }
    console.log("USER TOKEN IS VALID")
    return UserService.registerWorker(formData).then(
        (data) => {
            dispatch({
                type: REGISTER_WORKER_SUCCESS,
                payload: {
                    workerId: data
                },
            })

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            console.log(error)
            console.log(message)
            dispatch({
                type: REGISTER_WORKER_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};


export const organizationRegistration = (formData) => (dispatch) => {
    if (!AuthService.tokenIsValid()) {
        dispatch({
            type: LOGOUT
        })
        return Promise.resolve();
    }
    return UserService.registerOrganization(formData).then(
        (data) => {
            dispatch({
                type: REGISTER_ORGANIZATION_SUCCESS,
                payload: {
                    organizationID: data
                },
            })

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: REGISTER_ORGANIZATION_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
}

export const workersFetchAll = () => (dispatch) => {
    dispatch({
        type: FETCH_ALL_WORKERS
    })

    return UserService.getPublicWorkersContent().then(
        (data) => {
            dispatch({
                type: FETCH_ALL_WORKERS_SUCCESS,
                payload: {
                    data
                },
            })

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: FETCH_ALL_WORKERS_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        })
}

export const workersFetchDetailsById = (id) => (dispatch) => {
    dispatch({
        type: FETCH_A_WORKER_BY_ID
    })

    return UserService.getAWorkerByIdAdminContent(id).then(
        (data) => {
            dispatch({
                type: FETCH_A_WORKER_BY_ID_SUCCESS,
                payload: {
                    data: data.data
                },
            })

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: FETCH_A_WORKER_BY_ID_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        })
}

export const organizationFetchAll = () => (dispatch) => {
    dispatch({
        type: FETCH_ALL_ORGANIZATIONS
    })

    return UserService.getPublicOrganizationContent().then(
        (data) => {

            dispatch({
                type: FETCH_ALL_ORGANIZATIONS_SUCCESS,
                payload: {
                    data
                }
            })

            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: FETCH_ALL_ORGANIZATIONS_FAIL
            })
            return Promise.reject();
        }
    )
}

export const organizationFetchDetailsById = (id) => (dispatch) => {
    dispatch({
        type: FETCH_AN_ORGANIZATION_BY_ID
    })

    return UserService.getAnOrganizationByIdAdminContent(id).then(
        (data) => {
            dispatch({
                type: FETCH_AN_ORGANIZATION_BY_ID_SUCCESS,
                payload: {
                    data: data.data
                },
            })

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: FETCH_AN_ORGANIZATION_BY_ID_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        })
}

export const userFetchDetailsByEmail = (email) => (dispatch) => {
    dispatch({
        type: FETCH_A_USER_BY_EMAIL
    })

    return UserService.getAUserByEmailAdminContent(email).then(
        (data) => {
            dispatch({
                type: FETCH_A_USER_BY_EMAIL_SUCCESS,
                payload: {
                    data: data.data
                },
            })

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: FETCH_A_USER_BY_EMAIL_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        })
}

export const workersFetchDetailsForGuestById = (id) =>  (dispatch) => {
    dispatch({
        type: FETCH_WORKER_PROFILE_FOR_GUEST_BY_ID
    })

    return UserService.getAWorkerByIdGuestContent(id).then(
        (data) => {
            dispatch({
                type: FETCH_WORKER_PROFILE_FOR_GUEST_BY_ID_SUCCESS,
                payload: {
                    data: data.data
                },
            })

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: FETCH_WORKER_PROFILE_FOR_GUEST_BY_ID_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        })
}

export const organizationsFetchDetailsForGuestById = (id) =>  (dispatch) => {
    dispatch({
        type: FETCH_ORGANIZAION_PROFILE_FOR_GUEST_BY_ID
    })

    return UserService.getAnOrganizationByIdGuestContent(id).then(
        (data) => {
            dispatch({
                type: FETCH_ORGANIZAION_PROFILE_FOR_GUEST_BY_ID_SUCCESS,
                payload: {
                    data: data.data
                },
            })

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: FETCH_ORGANIZAION_PROFILE_FOR_GUEST_BY_ID_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        })
}

//Update SECTION
export const updateUser = (id, formData) => (dispatch) => {
    if (!AuthService.tokenIsValid()) {
        console.log("USER LOGGED OUT FOR EXPIRED TOKEN")
        dispatch({
            type: LOGOUT
        })
        return Promise.resolve();
    }

    return AuthService.updateUser(id, formData).then(
        (data) => {
            dispatch({
                type: UPDATE_USER_SUCCESS,
                payload: {
                    userEmail: data
                },
            })

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            console.log(error)
            console.log(message)
            dispatch({
                type: UPDATE_USER_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};

// DELETE
export const actionDeleteUser = (id) => (dispatch) => {
    if (!AuthService.tokenIsValid()) {
        console.log("USER LOGGED OUT FOR EXPIRED TOKEN")
        dispatch({
            type: LOGOUT
        })
        return Promise.resolve();
    }

    return AuthService.deleteUser(id).then(
        (data) => {
            dispatch({
                type: DELETE_USER_SUCCESS,
                payload: {},
            })

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            console.log(error)
            console.log(message)
            dispatch({
                type: DELETE_USER_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
}