import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import "./App.css";

import Login from "./pages/Login";
import RegisterUser from "./pages/RegisterUser";
import Home from "./pages/Home";
import Profile from "./components/MyProfile";
import RegisterWorker from "./pages/RegisterWorker.tsx";
import RegisterOrganization from "./pages/RegisterOrganization.tsx";

import { logout } from "./actions/auth";
import AuthService from "./services/auth.service";

import { clearMessage } from "./actions/message";
// import logo from "../public/logo192.png";
import { history } from "./helpers/history";
import { CssBaseline, Grid, Paper } from "@material-ui/core";
import Header from "./components/Header";
import NavMenu from "./components/NavMenu";
import Workers from "./pages/Workers";
import Organizations from "./pages/Organizations";
import WorkerDetails from "./pages/WorkerDetails";
import OrganizationDetails from "./pages/OrganizationDetails";
import Users from "./pages/Users";
import UserDetails from "./pages/UserDetails";
import GuestOrganizations from "./pages/GuestRegisteredOrganizations";
import GuestWorkers from "./pages/GuestRegisteredWorkers";
import GuestDetailsForWorker from "./pages/GuestDetailsForWorker";
import GuestDetailsForOrganization from "./pages/GuestDetailsForOrganization";
import MyProfile from "./components/MyProfile";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paperRoot: {
    padding: "10px 5px",
  },
  menuLink: {
    color: "#FFFFFF",
    textDecoration: "None",
    textDecorationLine: "None",
    padding: 0,
    margin: 0,
  },
  menuLinkAlt: {
    color: "#111111",
    textDecoration: "None",
    textDecorationLine: "None",
  },
  menuLinkLogin: {
    color: "#FFFFFF",
    textDecoration: "None",
    textDecorationLine: "None",
    float: "right",
    paddingRight: 10,
  },
}));

const App = () => {
  const classes = useStyles();

  const [isAdmin, setIsAdmin] = useState(false);
  const [isSysAdmin, setIsSysAdmin] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setIsAdmin(currentUser.roles.includes("Admin"));
      setIsSysAdmin(currentUser.roles.includes("System Admin"));
    } else {
      setIsAdmin(false);
      setIsSysAdmin(false);
    }
  }, [currentUser]);

  const logOut = () => {
    dispatch(logout());
  };

  return (
    <Router history={history}>
      <div>
        <Header
          handleLogout={logOut}
          isLoggedIn={AuthService.getAuth()}
          user={currentUser}
        />
        <NavMenu
          isLoggedIn={AuthService.getAuth()}
          isAdmin={isAdmin}
          isSysAdmin={isSysAdmin}
        />
        <>
          <Grid container>
            <Grid md={12} xs={12} item>
              <Paper variant="outlined" className={classes.paperRoot}>
                <Switch>
                  <Route exact path={["/", "/home"]} component={Home} />
                  <Route exact path="/login" component={Login} />
                  {/* <PrivateRoute exact path="/register" component={Register} /> */}
                  <PrivateRoute exact path="/profile" component={Profile} />

                  <PrivateRoute path="/manage-users" component={Users} />
                  <PrivateRoute
                    exact
                    path="/register-user"
                    component={RegisterUser}
                  />
                  <PrivateRoute
                    path="/manage-user/:email/details"
                    component={UserDetails}
                  />



                  <PrivateRoute
                    path="/register-worker"
                    component={RegisterWorker}
                  />
                  <PrivateRoute path="/manage-workers" component={Workers} />
                  <PrivateRoute
                    path="/manage-worker/:id/details"
                    component={WorkerDetails}
                  />
                  <PrivateRoute
                    path="/manage-organizations"
                    component={Organizations}
                  />
                  <PrivateRoute
                    path="/manage-organization/:id/details"
                    component={OrganizationDetails}
                  />
                  <PrivateRoute
                    path="/register-organization"
                    component={RegisterOrganization}
                  />
                  <PrivateRoute
                    path="/my-profile"
                    component={MyProfile}
                  />


                  <Route
                    path="/workers"
                    component={GuestWorkers}
                  />
                  <Route
                    path="/worker/:id/details"
                    component={GuestDetailsForWorker}
                  />
                  <Route
                    path="/organizations"
                    component={GuestOrganizations}
                  />
                  <Route
                    path="/organization/:id/details"
                    component={GuestDetailsForOrganization}
                  />
                </Switch>
              </Paper>
            </Grid>
          </Grid>
        </>
        <CssBaseline />
      </div>
    </Router>
  );
};

export default App;

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      AuthService.getAuth() ? (
        <Component {...props} />
      ) : (
        <>
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        </>
      )
    }
  />
);
