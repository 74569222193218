import { Grid, makeStyles } from '@material-ui/core'
import { AccountBox } from '@material-ui/icons';
import React, { useState } from 'react'
import AboutOrganizationFormUpdate from './AboutOrganizationFormUpdate';
import CapacityNeedsUpdateForm from './CapacityNeedsUpdateForm';
import SectorInformationUpdateForm from './SectorInformationUpdateForm';
import StaffUpdateForm from './StaffUpdateForm';
import SectionHeader from '../SectionHeader';
import { useTable } from '../useTable';
import HeadOfficeUpdateForm from './HeadOfficeUpdateForm';
import ContactPersonUpdateForm from './ContactPersonUpdateForm';
import CollapsableSection from "../CollapsableSection";

const useStyles = makeStyles((theme) => ({
    section: {
        margin: theme.spacing(2),
    },
}));

function OrganizationProfile(props) {

    const { profile, history, reloadPage } = props
    const { TblContainer, SectionLabelValueMultiRow } = useTable();
    const classes = useStyles();
    const [openAboutOrgPopup, setOpenAboutOrgPopup] = useState(false);
    const [openHeadOfficeLocPopup, setOpenHeadOfficeLocPopup] = useState(false);
    const [openContactPopup, setOpenContactPopup] = useState(false);
    const [openStaffPopup, setOpenStaffPopup] = useState(false);
    const [openSectorPopup, setOpenSectorPopup] = useState(false);
    const [openCapacityPopup, setOpenCapacityPopup] = useState(false);


    const AboutOrg = [
        { label: "AEAS ID", value: profile.OUID },
        { label: "Name of organization", value: profile.Name },
        { label: "Type of registration", value: profile.TypeOfReg },
        { label: "Date of registration", value: profile.DateOfIncorp },
        { label: "Registration No. of entity", value: profile.RegNoEntity },
        { label: "Email address", value: profile.Email },
        { label: "Country", value: profile.Country },
        { label: "Telephone", value: profile.Telephone },
        { label: "Website", value: profile.Website },
        { label: "Postal address", value: profile.PostalAddress },
    ]

    const HeadOfficeLoc = [
        { label: "District", value: profile.HeadOfficeDistrict },
        { label: "Sub-county/Division", value: profile.HeadOfficeDiv },
        { label: "Village", value: profile.HeadOfficeVillage },
        { label: "Street", value: profile.HeadOfficeStreet },
    ]

    const ContactPerson = [
        { label: "Contact name", value: profile.ContName },
        { label: "Position", value: profile.ContPosition },
        { label: "Contact telephone", value: profile.ContTelephone },
        { label: "Email", value: profile.ContEmail },
    ]

    const Staff = [
        { label: "Males", value: profile.NumStaffMale },
        { label: "Females", value: profile.NumStaffFemale },
        { label: "Males at PhD level", value: profile.PhdMales },
        { label: "Females at PhD level", value: profile.PhdFemales },
        { label: "Males at Masters level", value: profile.MastrsMales },
        { label: "Females at Masters level", value: profile.MastrsFemales },
        { label: "Males at Bsc level", value: profile.BscMale },
        { label: "Females at Bsc level", value: profile.BscFemale },
        { label: "Males at Diploma level", value: profile.DipMale },
        { label: "Females at Diploma level", value: profile.DipFemale },
        { label: "Males at Certificate level", value: profile.CertMale },
        { label: "Females at Certificate level", value: profile.CertFemale },
    ]

    const SectorInfo = [
        { label: "Sector", value: profile.Sector },
        { label: "Agricultural sector focus", value: profile.AgricSectorFocus },
        // optional
        profile.AgricFocusValChainFocLev !== "" && { label: "Value chain focus level", value: profile.AgricFocusValChainFocLev },
        profile.AgricFocusEnterp !== "" && { label: "Focus enterprises", value: profile.AgricFocusEnterp },
        // end optional
        { label: "Mission", value: profile.Mission },
        { label: "Area of operation", value: profile.AreaOpnTrgtDistricts },
        // optional
        profile.CentralDistricts !== "" && { label: "Central districts", value: profile.CentralDistricts },
        profile.EastDistricts !== "" && { label: "Eastern districts", value: profile.EastDistricts },
        profile.WestDistricts !== "" && { label: "Western districts", value: profile.WestDistricts },
        profile.NorthDistricts !== "" && { label: "Northern districts", value: profile.NorthDistricts },
        // end optional
        { label: "Key beneficiaries", value: profile.KeyBenefs },
        // optional
        profile.KeyBeneFrmrsCateg !== "" && { label: "If farmers, which category", value: profile.KeyBeneFrmrsCateg },
        profile.KeyBeneFrmrsGender !== "" && { label: "If farmers, which gender", value: profile.KeyBeneFrmrsGender },
        // end optional
    ]

    const CapacityNeeds = [
        { label: "Capacity needs 1st choice", value: profile.CapacityNeedFirstChoice },
        { label: "Capacity needs 2nd choice", value: profile.CapacityNeedSecondChoice },
        { label: "Capacity needs 3rd choice", value: profile.CapacityNeedThirdChoice },
    ]



    return (
        <>
            <Grid container>
                <Grid item sm={12} md={12} className={classes.section}>
                    {/* About organization */}
                    <h1>About organization</h1>
                    <SectionHeader
                        onClick={() => {
                            setOpenAboutOrgPopup(true);
                        }}
                        title=""
                        openPopup={openAboutOrgPopup}
                        setOpenPopup={setOpenAboutOrgPopup}
                        popupTitle="Update about organization"
                    >
                        <AboutOrganizationFormUpdate
                            data={profile}
                            closePopup={() => { setOpenAboutOrgPopup(false) }}
                            reloadPage={reloadPage}
                            history={history}
                        />
                    </SectionHeader>
                    <Grid container>
                        <SectionLabelValueMultiRow data={AboutOrg} />
                    </Grid>
                </Grid>
                <Grid item sm={12} md={12} className={classes.section}>
                    {/* Head office location */}
                    <CollapsableSection title="Head office location" defaultExpanded="true" icon="fa-solid fa-location-pin">
                        <SectionHeader
                            onClick={() => {
                                setOpenHeadOfficeLocPopup(true);
                            }}
                            title=""
                            openPopup={openHeadOfficeLocPopup}
                            setOpenPopup={setOpenHeadOfficeLocPopup}
                            popupTitle="Update head office location"
                        >
                            <HeadOfficeUpdateForm
                                data={profile}
                                closePopup={() => { setOpenHeadOfficeLocPopup(false) }}
                                reloadPage={reloadPage}
                                history={history}
                            />
                        </SectionHeader>
                    <br/>
                    <SectionLabelValueMultiRow data={HeadOfficeLoc} />
                        <br/><br/>
                    </CollapsableSection>
                </Grid>
                <Grid item xs={12} md={12} className={classes.section}>
                    {/* Contact person */}
                    <CollapsableSection title="Contact person" defaultExpanded="true" icon="fa-solid fa-message">
                        <SectionHeader
                            onClick={() => {
                                setOpenContactPopup(true);
                            }}
                            title=""
                            openPopup={openContactPopup}
                            setOpenPopup={setOpenContactPopup}
                            popupTitle="Update contact person"
                        >
                            <ContactPersonUpdateForm
                                data={profile}
                                closePopup={() => { setOpenContactPopup(false) }}
                                reloadPage={reloadPage}
                                history={history}
                            />
                        </SectionHeader>
                        <br/>
                        <SectionLabelValueMultiRow data={ContactPerson} />
                        <br/><br/>
                    </CollapsableSection>
                </Grid>
                <Grid item xs={12} md={12} className={classes.section}>
                    {/* AEAS staff */}
                    <CollapsableSection title="Staff" defaultExpanded="true" icon="fa-solid fa-users">
                        <SectionHeader
                            onClick={() => {
                                setOpenStaffPopup(true);
                            }}
                            title=""
                            openPopup={openStaffPopup}
                            setOpenPopup={setOpenStaffPopup}
                            popupTitle="Update staff"
                        >
                            <StaffUpdateForm
                                data={profile}
                                closePopup={() => { setOpenStaffPopup(false) }}
                                reloadPage={reloadPage}
                                history={history}
                            />
                        </SectionHeader>
                        <br/>
                        <SectionLabelValueMultiRow data={Staff} />
                        <br/><br/>
                    </CollapsableSection>
                </Grid>
                <Grid item xs={12} md={12} className={classes.section}>
                    {/* Sector info */}
                    <CollapsableSection title="Sector information" defaultExpanded="true" icon="fa-solid fa-file-lines">
                        <SectionHeader
                            onClick={() => {
                                setOpenSectorPopup(true);
                            }}
                            title=""
                            openPopup={openSectorPopup}
                            setOpenPopup={setOpenSectorPopup}
                            popupTitle="Update sector information"
                        >
                            <SectorInformationUpdateForm
                                data={profile}
                                closePopup={() => { setOpenSectorPopup(false) }}
                                reloadPage={reloadPage}
                                history={history}
                            />
                        </SectionHeader>
                        <br/>
                        <SectionLabelValueMultiRow data={SectorInfo} />
                        <br/><br/>
                    </CollapsableSection>
                </Grid>
                <Grid item xs={12} md={12} className={classes.section}>
                    {/* Capacity needs */}
                    <CollapsableSection title="Capacity needs" defaultExpanded="true" icon="fa-solid fa-file-lines">
                        <SectionHeader
                            onClick={() => {
                                setOpenCapacityPopup(true);
                            }}
                            title=""
                            openPopup={openCapacityPopup}
                            setOpenPopup={setOpenCapacityPopup}
                            popupTitle="Update capacity needs"
                        >
                            <CapacityNeedsUpdateForm
                                data={profile}
                                closePopup={() => { setOpenCapacityPopup(false) }}
                                reloadPage={reloadPage}
                                history={history}
                            />
                        </SectionHeader>
                        <br/>
                        <SectionLabelValueMultiRow data={CapacityNeeds} />
                        <br/><br/>
                    </CollapsableSection>
                </Grid>
            </Grid>
        </>
    )
}

export default OrganizationProfile
