import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";

const MyProfile = () => {
  const { user: currentUser } = useSelector((state) => state.auth);

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            My user profile
          </Typography>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          {/* Profile content */}

          <TableContainer style={{ marginBottom: "100px" }}>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Email
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {currentUser.userName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Roles/Permissions:
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {currentUser.roles &&
                      currentUser.roles.map((role, index) => (
                        <div key={index}>
                          <Typography variant="caption">{role}</Typography>
                        </div>
                      ))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default MyProfile;
