import { FormControlLabel, Radio } from '@material-ui/core';
import { Field } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import React from 'react'

function FormikRadioGroup(props) {
    const { name, label, radios, variant, ...other } = props;
    return (
        <Field
            {...other}
            component={RadioGroup}
            name={name}
            variant={variant || "inline"}
        >
            <div>{label}</div>
            {
                radios.map((item, index) =>
                    <FormControlLabel
                        key={index}
                        value={item.value}
                        control={<Radio value={item.value} />}
                        label={item.value}
                    />)
            }

        </Field>
    )
}

export default FormikRadioGroup
