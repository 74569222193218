import { Grid, makeStyles } from '@material-ui/core'
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react'
import * as Yup from "yup";
import Controls from '../controls/Controls';
import { useDispatch } from 'react-redux';
import { actionUpdateOrgContactPerson } from '../../actions/organization';

const useStyles = makeStyles((theme) => ({
    tForm: {
        '& .MuiFormControl-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        }
    }
}))
function ContactPersonUpdateForm(props) {
    const classes = useStyles()
    const { data, reloadPage, closePopup, history } = props;
    const dispatch = useDispatch();

    return (
        <>
            <Grid container>
                <Grid md={2} xs={12} item />
                <Grid md={8} xs={12} item>
                    <Formik
                        initialValues={{
                            contName: data.ContName,
                            contPosition: data.ContPosition,
                            contTelephone: data.ContTelephone,
                            contEmail: data.ContEmail,
                        }}

                        validationSchema={Yup.object({
                            contName: Yup.string()
                                .min(2, "Too Short!")
                                .required("Required"),
                            contPosition: Yup.string()
                                .min(2, "Too Short!")
                                .required("Required"),
                            contTelephone: Yup.string().required("Required"),
                            contEmail: Yup.string().email()
                        })}
                        onSubmit={async (values, { setSubmitting }) => {
                            await dispatch(actionUpdateOrgContactPerson(data.Id, values))
                                .then(() => {
                                    setSubmitting(false)
                                    history.push(
                                        `/manage-organization/` + data.Id + `/details`
                                    );
                                    reloadPage()
                                    closePopup()
                                })
                                .catch((e) => {
                                    setSubmitting(false)
                                })
                        }}
                    >

                        {({
                            isSubmitting,
                            isValid,
                        }) => (
                            <Form autoComplete="off" className={classes.tForm}>
                                <Field
                                    fullWidth
                                    size="small"
                                    name="contName"
                                    component={TextField}
                                    variant="outlined"
                                    label="Contact Name"
                                />

                                <Field
                                    fullWidth
                                    size="small"
                                    name="contPosition"
                                    component={TextField}
                                    variant="outlined"
                                    label="Position"
                                />
                                <Field
                                    fullWidth
                                    size="small"
                                    name="contTelephone"
                                    component={TextField}
                                    variant="outlined"
                                    label="Contact Telephone"
                                />
                                <Field
                                    fullWidth
                                    size="small"
                                    name="contEmail"
                                    component={TextField}
                                    variant="outlined"
                                    label="Email"
                                />

                                <Controls.SubmitButton
                                    isSubmitting={isSubmitting}
                                    isValid={isValid}
                                />
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>

        </>
    )
}

export default ContactPersonUpdateForm
