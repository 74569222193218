import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react'
import * as Yup from "yup";
import { agricRelQualOptions, qualificationOptions, skillsDurationOptions, supportSkillOptions } from '../../utils/options';
import Controls from '../controls/Controls';
import { useDispatch } from 'react-redux'
import { actionUpdateWorkerQualifications } from '../../actions/worker';

const useStyles = makeStyles((theme) => ({
    tForm: {
        '& .MuiFormControl-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        }
    },
}))

function QualificationsUpdateForm(props) {
    const { data, closePopup, reloadPage, history } = props;
    const classes = useStyles()
    const dispatch = useDispatch()

    return (
        <>
            <Grid container>
                <Grid item md={2} sm={12} />
                <Grid item md={8} sm={12}>
                    <Formik
                        initialValues={{
                            agricRelQual: data.AgricRelQual,
                            highestQual: data.HighestQual,
                            otherHighestQual: data.OtherHighestQual,
                            yearOfQual: data.YearOfQual,
                            otherMainSuppSkillsTrain: (data.SupportSkillA || data.SupportSkillB || data.SupportSkillC) ? "Yes" : "No",
                            supportSkillA: data.SupportSkillA,
                            qualSupportSkillA: data.QualSupportSkillA,
                            durationSkillA: data.DurationSkillA,
                            supportSkillB: data.SupportSkillB,
                            qualSupportSkillB: data.QualSupportSkillB,
                            durationSkillB: data.DurationSkillB,
                            supportSkillC: data.SupportSkillC,
                            qualSupportSkillC: data.QualSupportSkillC,
                            durationSkillC: data.DurationSkillC,
                        }}

                        validationSchema={Yup.object({
                            agricRelQual: Yup.string().nullable().required("Required"),
                            highestQual: Yup.string().nullable().required("Required"),
                            otherHighestQual: Yup.string(),
                            yearOfQual: Yup.number("Invalid year").nullable().required("Required"),
                            otherMainSuppSkillsTrain: Yup.string().nullable().required(
                                "Required"
                            ),
                            supportSkillA: Yup.string().nullable(),
                            qualSupportSkillA: Yup.string().nullable(),
                            durationSkillA: Yup.string().nullable(),
                            supportSkillB: Yup.string().nullable(),
                            qualSupportSkillB: Yup.string().nullable(),
                            durationSkillB: Yup.string().nullable(),
                            supportSkillC: Yup.string().nullable(),
                            qualSupportSkillC: Yup.string().nullable(),
                            durationSkillC: Yup.string().nullable()
                        })}
                        onSubmit={async (values, { setSubmitting }) => {
                            if (values.otherMainSuppSkillsTrain === "No") {
                                values = {
                                    ...values, supportSkillA: null,
                                    qualSupportSkillA: null,
                                    durationSkillA: null,
                                    supportSkillB: null,
                                    qualSupportSkillB: null,
                                    durationSkillB: null,
                                    supportSkillC: null,
                                    qualSupportSkillC: null,
                                    durationSkillC: null
                                };
                            }
                            await dispatch(actionUpdateWorkerQualifications(data.Id, values))
                                .then(() => {
                                    setSubmitting(false)
                                    history.push(
                                        `/manage-worker/` + data.Id + `/details`
                                    );
                                    reloadPage()
                                    closePopup()
                                })
                                .catch((e) => {
                                    setSubmitting(false)
                                })
                        }}

                    >
                        {({ isSubmitting, isValid }) => (
                            <Form autoComplete="off" className={classes.tForm} >
                                {/* <Field
                                    fullWidth
                                    size="small"
                                    name="telephone1"
                                    component={TextField}
                                    label="Telephone 1"
                                    variant="outlined"
                                /> */}
                                <Field fullWidth name="agricRelQual">
                                    {({
                                        meta,
                                        form: { setFieldValue, values }
                                    }) => (
                                        <Controls.FormikSelect
                                            name="agricRelQual"
                                            value={values.agricRelQual}
                                            options={agricRelQualOptions}
                                            label="Field of agricultural-related qualification"
                                            placeholder="Field of agricultural-related qualification"
                                            setFieldValue={setFieldValue}
                                            meta={meta}
                                            freeSolo={true}
                                        />
                                    )}
                                </Field>

                                <Field fullWidth name="highestQual">
                                    {({
                                        meta,
                                        form: { setFieldValue, values }
                                    }) => (
                                        <Controls.FormikSelect
                                            name="highestQual"
                                            value={values.highestQual}
                                            options={qualificationOptions}
                                            label="Field of agricultural-related qualification"
                                            placeholder="Field of agricultural-related qualification"
                                            setFieldValue={setFieldValue}
                                            meta={meta}
                                            freeSolo={false}
                                        />
                                    )}
                                </Field>

                                <Field>
                                    {({ form: { values } }) => (
                                        <>
                                            {values.highestQual === "Other(specify)" ?
                                                <Field
                                                    fullWidth
                                                    size="small"
                                                    variant="outlined"
                                                    name="otherHighestQual"
                                                    component={TextField}
                                                    label="Other highest qualification"
                                                />
                                                : null
                                            }
                                        </>
                                    )}
                                </Field>

                                <Field
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    name="yearOfQual"
                                    component={TextField}
                                    label="Year of Qualification"
                                />
                                <Controls.FormikRadioGroup
                                    name="otherMainSuppSkillsTrain"
                                    label="Other main support skills Training?"
                                    radios={[{ label: "No", value: "No" }, { label: "Yes", value: "Yes" },]}
                                />
                                <Field>
                                    {({ form: { values } }) => (
                                        <>
                                            {values.otherMainSuppSkillsTrain === "Yes"
                                                ?
                                                <>
                                                    <Field fullWidth name="supportSkillA">
                                                        {({
                                                            meta,
                                                            form: { setFieldValue, values }
                                                        }) => (
                                                            <Controls.FormikSelect
                                                                name="supportSkillA"
                                                                value={values.supportSkillA}
                                                                options={supportSkillOptions}
                                                                label="Support Skill A"
                                                                placeholder="Support Skill A"
                                                                setFieldValue={setFieldValue}
                                                                meta={meta}
                                                                freeSolo={true}
                                                            />
                                                        )}
                                                    </Field>
                                                    <Field fullWidth name="qualSupportSkillA">
                                                        {({
                                                            meta,
                                                            form: { setFieldValue, values }
                                                        }) => (
                                                            <Controls.FormikSelect
                                                                name="qualSupportSkillA"
                                                                value={values.qualSupportSkillA}
                                                                options={qualificationOptions}
                                                                label="Qualification support skill A"
                                                                placeholder="Qualification support skill A"
                                                                setFieldValue={setFieldValue}
                                                                meta={meta}
                                                                freeSolo={true}
                                                            />
                                                        )}
                                                    </Field>
                                                    <Controls.FormikRadioGroup
                                                        name="durationSkillA"
                                                        label="Duration skill A"
                                                        radios={skillsDurationOptions}
                                                    />


                                                    <Field fullWidth name="supportSkillB">
                                                        {({
                                                            meta,
                                                            form: { setFieldValue, values }
                                                        }) => (
                                                            <Controls.FormikSelect
                                                                name="supportSkillB"
                                                                value={values.supportSkillB}
                                                                options={supportSkillOptions}
                                                                label="Support skill B"
                                                                placeholder="Support skill B"
                                                                setFieldValue={setFieldValue}
                                                                meta={meta}
                                                                freeSolo={true}
                                                            />
                                                        )}
                                                    </Field>
                                                    <Field fullWidth name="qualSupportSkillB">
                                                        {({
                                                            meta,
                                                            form: { setFieldValue, values }
                                                        }) => (
                                                            <Controls.FormikSelect
                                                                name="qualSupportSkillB"
                                                                value={values.qualSupportSkillB}
                                                                options={qualificationOptions}
                                                                label="Qualification support skill B"
                                                                placeholder="Qualification support skill B"
                                                                setFieldValue={setFieldValue}
                                                                meta={meta}
                                                                freeSolo={true}
                                                            />
                                                        )}
                                                    </Field>
                                                    <Controls.FormikRadioGroup
                                                        name="durationSkillB"
                                                        label="Duration skill B"
                                                        radios={skillsDurationOptions}
                                                    />



                                                    <Field fullWidth name="supportSkillC">
                                                        {({
                                                            meta,
                                                            form: { setFieldValue, values }
                                                        }) => (
                                                            <Controls.FormikSelect
                                                                name="supportSkillC"
                                                                value={values.supportSkillC}
                                                                options={supportSkillOptions}
                                                                label="Support skill C"
                                                                placeholder="Support skill C"
                                                                setFieldValue={setFieldValue}
                                                                meta={meta}
                                                                freeSolo={true}
                                                            />
                                                        )}
                                                    </Field>
                                                    <Field fullWidth name="qualSupportSkillC">
                                                        {({
                                                            meta,
                                                            form: { setFieldValue, values }
                                                        }) => (
                                                            <Controls.FormikSelect
                                                                name="qualSupportSkillC"
                                                                value={values.qualSupportSkillC}
                                                                options={qualificationOptions}
                                                                label="Qualification support skill C"
                                                                placeholder="Qualification support skill C"
                                                                setFieldValue={setFieldValue}
                                                                meta={meta}
                                                                freeSolo={true}
                                                            />
                                                        )}
                                                    </Field>
                                                    <Controls.FormikRadioGroup
                                                        name="durationSkillC"
                                                        label="Duration skill C"
                                                        radios={skillsDurationOptions}
                                                    />
                                                </> : null}
                                        </>
                                    )}
                                </Field>
                                <Controls.Button
                                    startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                                    disabled={!isValid || isSubmitting}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    {isSubmitting
                                        ? "Submitting..."
                                        : "Submit"
                                    }
                                </Controls.Button>
                            </Form>
                        )}

                    </Formik>
                </Grid>
                <Grid item md={2} sm={12} />
            </Grid>
        </>
    )
}

export default QualificationsUpdateForm
