import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React from 'react'
import * as Yup from "yup";
import { capacityNeedsOptions } from '../../utils/options';
import Controls from '../controls/Controls';
import { useDispatch } from 'react-redux'
import { actionUpdateWorkerCapacityNeeds } from '../../actions/worker';


const useStyles = makeStyles((theme) => ({
    tForm: {
        '& .MuiFormControl-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        }
    },
}))

function CapacityNeedsUpdateForm(props) {

    const { data, closePopup, reloadPage, history  } = props;
    const classes = useStyles()
    const dispatch = useDispatch()


    return (
        <>
            <Grid container>
                <Grid item md={2} sm={12} />
                <Grid item md={8} sm={12}>
                    <Formik
                        initialValues={{
                            capacityNeedFirstChoice: data.CapacityNeedFirstChoice,
                            capacityNeedSecondChoice: data.CapacityNeedSecondChoice,
                            capacityNeedThirdChoice: data.CapacityNeedThirdChoice
                        }}

                        validationSchema={Yup.object({
                            capacityNeedFirstChoice: Yup.string().nullable(),
                            capacityNeedSecondChoice: Yup.string().nullable(),
                            capacityNeedThirdChoice: Yup.string().nullable()
                        })}
                        onSubmit={async (values, { setSubmitting }) => {
                            await dispatch(actionUpdateWorkerCapacityNeeds(data.Id, values))
                                .then(() => {
                                    setSubmitting(false)
                                    history.push(
                                        `/manage-worker/` + data.Id + `/details`
                                    );
                                    reloadPage()
                                    closePopup()
                                })
                                .catch((e) => {
                                    setSubmitting(false)
                                })
                        }}
                    >

                        {({ isSubmitting, isValid }) => (
                            <Form autoComplete="off" className={classes.tForm} >
                                <Field fullWidth name="capacityNeedFirstChoice">
                                    {({
                                        meta,
                                        form: { setFieldValue, values }
                                    }) => (
                                        <Controls.FormikSelect
                                            name="capacityNeedFirstChoice"
                                            value={values.capacityNeedFirstChoice}
                                            options={capacityNeedsOptions}
                                            label="1st Choice"
                                            placeholder="Choice"
                                            setFieldValue={setFieldValue}
                                            meta={meta}
                                            freeSolo={true}
                                        />
                                    )}
                                </Field>

                                <Field fullWidth name="capacityNeedSecondChoice">
                                    {({
                                        meta,
                                        form: { setFieldValue, values }
                                    }) => (
                                        <Controls.FormikSelect
                                            name="capacityNeedSecondChoice"
                                            value={values.capacityNeedSecondChoice}
                                            options={capacityNeedsOptions}
                                            label="2nd Choice"
                                            placeholder="Choice"
                                            setFieldValue={setFieldValue}
                                            meta={meta}
                                            freeSolo={true}
                                        />
                                    )}
                                </Field>

                                <Field fullWidth name="capacityNeedThirdChoice">
                                    {({
                                        meta,
                                        form: { setFieldValue, values }
                                    }) => (
                                        <Controls.FormikSelect
                                            name="capacityNeedThirdChoice"
                                            value={values.capacityNeedThirdChoice}
                                            options={capacityNeedsOptions}
                                            label="3rd Choice"
                                            placeholder="Choice"
                                            setFieldValue={setFieldValue}
                                            meta={meta}
                                            freeSolo={true}
                                        />
                                    )}
                                </Field>

                                <Controls.Button
                                    startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                                    disabled={!isValid || isSubmitting}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    {isSubmitting
                                        ? "Submitting..."
                                        : "Submit"
                                    }
                                </Controls.Button>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </>
    )
}

export default CapacityNeedsUpdateForm
