import { Grid, makeStyles } from '@material-ui/core'
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react'
import * as Yup from "yup";
import { districts } from '../../data/districts';
import Controls from '../controls/Controls';
import { useDispatch } from 'react-redux'
import { actionUpdateOrgHeadOffice } from '../../actions/organization';


const useStyles = makeStyles((theme) => ({
    tForm: {
        '& .MuiFormControl-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        }
    }
}))

function HeadOfficeUpdateForm(props) {
    const classes = useStyles()
    const { data, history, reloadPage, closePopup } = props;
    const dispatch = useDispatch();

    return (
        <>
            <Grid container>
                <Grid md={2} xs={12} item />
                <Grid md={8} xs={12} item>
                    <Formik
                        initialValues={{
                            headOfficeDistrict: data.HeadOfficeDistrict,
                            headOfficeDiv: data.HeadOfficeDiv,
                            headOfficeVillage: data.HeadOfficeVillage,
                            headOfficeStreet: data.HeadOfficeStreet,
                        }}

                        validationSchema={Yup.object({
                            headOfficeDistrict: Yup.string()
                                .min(2, "Too Short!")
                                .required("Required"),
                            headOfficeDiv: Yup.string()
                                .min(2, "Too Short!")
                                .required("Required"),
                            headOfficeVillage: Yup.string(),
                            headOfficeStreet: Yup.string()
                        })}
                        onSubmit={async (values, { setSubmitting }) => {
                            await dispatch(actionUpdateOrgHeadOffice(data.Id, values))
                                    .then(() => {
                                        setSubmitting(false)
                                        history.push(
                                            `/manage-organization/` + data.Id + `/details`
                                        );
                                        reloadPage()
                                        closePopup()
                                    })
                                    .catch((e) => {
                                        setSubmitting(false)
                                    })
                        }}
                    >

                        {({
                            isSubmitting,
                            isValid,
                        }) => (
                            <Form autoComplete="off" className={classes.tForm}>
                                <Field fullWidth name="headOfficeDistrict">
                                    {({
                                        meta,
                                        form: { setFieldValue, values }
                                    }) => (
                                        <Controls.FormikSelect
                                            name="headOfficeDistrict"
                                            value={values.headOfficeDistrict}
                                            options={districts}
                                            label="District"
                                            placeholder="District"
                                            setFieldValue={setFieldValue}
                                            meta={meta}
                                        />
                                    )}
                                </Field>
                                <Field
                                    fullWidth
                                    size="small"
                                    name="headOfficeDiv"
                                    component={TextField}
                                    variant="outlined"
                                    label="Sub county/Division"
                                />
                                <Field
                                    fullWidth
                                    size="small"
                                    name="headOfficeVillage"
                                    component={TextField}
                                    variant="outlined"
                                    label="Village"
                                />
                                <Field
                                    fullWidth
                                    size="small"
                                    name="headOfficeStreet"
                                    component={TextField}
                                    variant="outlined"
                                    label="Street"
                                />
                                <Controls.SubmitButton
                                    isSubmitting={isSubmitting}
                                    isValid={isValid}
                                />
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </>
    )
}

export default HeadOfficeUpdateForm
