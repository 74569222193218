import React, { useEffect, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { login } from "../actions/auth";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RemoveRedEye } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import { TextField as FTField } from "formik-material-ui";

import * as Yup from "yup";

import PropTypes from "prop-types";

const styles = () => ({
  // eye: {
  //   cursor: "pointer"
  // }
});

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  error: {
    color: "red"
  }
});

class PasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordIsMasked: true
    };
  }

  togglePasswordMask = () => {
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked
    }));
  };

  render() {
    const { passwordIsMasked } = this.state;

    return (
      <TextField
        variant="outlined"
        size="small"
        type={passwordIsMasked ? "password" : "text"}
        {...this.props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <RemoveRedEye
                style={{ cursor: "pointer" }}
                onClick={this.togglePasswordMask}
              />
            </InputAdornment>
          )
        }}
      />
    );
  }
}

PasswordInput.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

PasswordInput = withStyles(styles)(PasswordInput);

const Login = props => {
  const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("Got to the login page");
  }, []);

  const classes = useStyles();

  if (isLoggedIn) {
    return <Redirect to="/home" />;
  }

  return (
    <div style={{ height: "400px" }}>
      <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={6} style={{ marginTop: "80px" }}>
          <Box paddingBottom={4}>
            <Typography variant="h5" align="center">
              Login
            </Typography>
          </Box>

          <Formik
            initialValues={{ username: "", password: "" }}
            onSubmit={async values => {
              await dispatch(login(values.username, values.password))
                .then(() => {
                  props.history.push("/home");
                  // window.location.reload();
                })
                .catch(() => {});
            }}
            validationSchema={Yup.object({
              username: Yup.string()
                .email("Invalid email")
                .required("Required"),
              password: Yup.string()
                .min(2, "Too Short!")
                .required("Required")
            })}
          >
            {({ isSubmitting }) => (
              <Form autoComplete="off">
                <Box paddingBottom={2}>
                  <Field
                    fullWidth
                    size="small"
                    name="username"
                    component={FTField}
                    variant="outlined"
                    label="Email"
                  />
                </Box>
                <Box paddingBottom={2}>
                  <Field fullWidth name="password">
                    {({ meta, form: { setFieldValue, values } }) => (
                      <FormControl variant="outlined" fullWidth>
                        <PasswordInput
                          label="Password"
                          name="password"
                          value={values.password}
                          onChange={e =>
                            setFieldValue("password", e.target.value)
                          }
                        />
                        {meta.touched && meta.error && (
                          <span className="error">{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box
                  paddingBottom={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "left",
                      flexDirection: "column"
                    }}
                  >
                    <Button
                      startIcon={
                        isSubmitting ? <CircularProgress size="1rem" /> : null
                      }
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      {isSubmitting ? "Logging in..." : "Login"}{" "}
                    </Button>
                    {message && (
                      <Typography variant="caption" className={classes.error}>
                        {message.includes("400")
                          ? "Invalid credentials"
                          : message}
                      </Typography>
                    )}
                  </Box>

                  <Box
                    style={{
                      paddingLeft: "25px",
                      display: "flex",
                      alignItems: "left",
                      flexDirection: "column"
                    }}
                  >
                    <Typography variant="caption">
                      Don't have an account?
                    </Typography>
                    <Typography variant="caption">
                      Contact <a href="/home"> AEAS administration</a>
                    </Typography>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </div>
  );
};

export default Login;
