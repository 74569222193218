import {
  AppBar,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { PowerSettingsNew } from "@material-ui/icons";

import React from "react";

const useClasses = makeStyles((theme) => ({
  siteLogo: {
    display: "flex",
    verticalAlign: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  loginButton: {
    fontSize: "12px",
    color: "#FFFFFF",
    "& a": {
      textDecoration: "none",
      color: "#FFFFFF",
    },
  },
  link: {
    textDecoration: "none",
    color: "#FFFFFF",
  },
}));

function Header(props) {
  const { isLoggedIn, user, handleLogout } = props;
  const classes = useClasses();

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Grid container>
            <Grid item className={classes.siteLogo}>
              <img
                src={`/ugflag.jpg`}
                alt="Logo"
                height="20"
                width="30"
                style={{ marginTop: "5px" }}
              />
              &nbsp;
              <Typography variant="h6">
                Uganda National AEAS e-Registration
              </Typography>
            </Grid>
            <Grid item sm></Grid>
            <Grid item>
              {isLoggedIn ? (
                <>
                  <a href="/my-profile" className={classes.link} title="Profile">{user.userName}</a>
                  &nbsp;
                  <IconButton
                    onClick={handleLogout}
                    classes={{ root: classes.loginButton }}
                  >
                    Logout
                    <PowerSettingsNew fontSize="inherit" />
                  </IconButton>
                </>
              ) : (
                <IconButton
                  // onClick={handleLogin}
                  classes={{ root: classes.loginButton }}
                >
                  <a href="/login">Login</a>
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;
