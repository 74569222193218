import { Chip, FormControl, makeStyles, TextField as TField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react'

const useStyles = makeStyles(theme => ({
    error: {
        color: "red"
    },
    container: {
        clear: 'both'
    }
}))

function FormikSelect(props) {
    const classes = useStyles();
    const { name, value, options, label, placeholder, setFieldValue, meta, freeSolo, multiple, disabled } = props;
    return (
        <FormControl variant="outlined" fullWidth className={classes.container}>
            <Autocomplete
            disabled={disabled || false}
                freeSolo={freeSolo || false}
                multiple={multiple || false}
                size="small"
                options={options}
                getOptionLabel={option => option}
                value={value}
                filterSelectedOptions
                onChange={(e: object, val: any) => {
                    setFieldValue(name, val);
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                        />
                    ))
                }
                renderInput={params => (
                    <TField
                        {...params}
                        style={{ minWidth: '300px' }}
                        variant="outlined"
                        label={freeSolo ? label + "(Type and press enter for any other options)" : label}
                        placeholder={placeholder}
                    />
                )}
            />
            {meta.touched && meta.error && (
                <span>
                    <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                </span>
            )}
        </FormControl>
    )
}

export default FormikSelect
