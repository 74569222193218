import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(3),
    "& thead th": {
      fontWeight: "600",
      color: theme.palette.primary.main,
      backgroundColor: "#e0e0e0",
    },
    "& tbody td": {
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
  },
}));
export function useTable(records, headCells, filterFn) {
  const classes = useStyles();

  const pages = [30, 50, 100];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);

  const TblContainer = (props) => (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        {props.children}
      </Table>
    </TableContainer>
  );
  const TblHead = () => (
    <TableHead>
      <TableRow>
        {headCells.map((item) => (
          <TableCell key={item.id}>{item.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const SectionLabelValueMultiRow = (props) => {

    let data = props.data.filter((x)=>{
      if(x !== undefined && x.value === "")
        x.value = "Not available";
      return x;
    }
    );

    return data.length > 6 ? (
      <>
        <Grid container>
          <Grid item xs={6} sm={6}>
            {data.slice(0, data.length / 2).map((item, index) => (
              <Grid container key={index} spacing={2}>
                <Grid item xs={2} sm={2}>{item.label}</Grid>
                <Grid item xs={8} sm={8}>{item.value}</Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={6} sm={6}>
            {data.slice(data.length / 2, data.length).map((item, index) => (
              <Grid container key={index} spacing={2}>
                <Grid item xs={2} sm={2}>{item.label}</Grid>
                <Grid item xs={8} sm={8}>{item.value}</Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </>
    ) : (
      <>
        {data.map((item, index) => (
          <Grid container key={index} spacing={2}>
            <Grid item xs={2} sm={2}>{item.label}</Grid>
            <Grid item xs={8} sm={8}>{item.value}</Grid>
          </Grid>
        ))}
      </>
    );
  };


  const SectionLongLabelValueRow = (props) => {
    // let data = props.data.filter((x)=> x);

    let data = props.data.filter((x)=>{
      if(x !== undefined && x.value === "")
        x.value = "Not available";
      return x;
    });

    return data.length > 6 ? (
        <>
          <Grid container>
            <Grid item xs={6} sm={6}>
              {data.slice(0, data.length / 2).map((item, index) => (
                  <Grid container key={index} spacing={2}>
                    <Grid item xs={4} sm={4}>{item.label}</Grid>
                    <Grid item xs={8} sm={8}>{item.value}</Grid>
                  </Grid>
              ))}
            </Grid>
            <Grid item xs={6} sm={6}>
              {data.slice(data.length / 2, data.length).map((item, index) => (
                  <Grid container key={index} spacing={2}>
                    <Grid item xs={4} sm={4}>{item.label}</Grid>
                    <Grid item xs={8} sm={8}>{item.value}</Grid>
                  </Grid>
              ))}
            </Grid>
          </Grid>
        </>
    ) : (
        <>
          {data.map((item, index) => (
              <Grid container key={index} spacing={2}>
                <Grid item xs={2} sm={2}>{item.label}</Grid>
                <Grid item xs={8} sm={8}>{item.value}</Grid>
              </Grid>
          ))}
        </>
    );
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const TblPagination = () => (
    <TablePagination
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={records ? records.length : 0}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );

  const recordsAfterPagingaAndSorting = () => {
    return records
      ? filterFn.fn(records).slice(page * rowsPerPage, (page + 1) * rowsPerPage)
      : [];
  };

  return {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingaAndSorting,
    SectionLabelValueMultiRow: SectionLabelValueMultiRow,
    SectionLongLabelValueRow: SectionLongLabelValueRow,
  };
}
