import { Toolbar, makeStyles, InputAdornment } from '@material-ui/core'
import { AddCircleOutlineOutlined, SearchOutlined } from '@material-ui/icons';
import React from 'react'
import Button from './Button'
import Input from './Input'


const useStyles = makeStyles((theme) => ({
    searchInput: {
      width: "65%",
      '& .MuiOutlinedInput-input':{
        padding: '8px',
      },
    },
    addButton: {
      position: "absolute",
      right: "10px",
      "& a": {
        textDecoration: "none",
        color: theme.palette.primary.main,
      },
    },
  }));

  
function ManageToolbar(props) {
    const {searchLabel, handleSearch, registerUrl, registerButtonLabel } = props;
    const classes = useStyles();


    return (
        <Toolbar>
        <Input
          className={classes.searchInput}
          label={searchLabel}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        <Button
          className={classes.addButton}
          startIcon={<AddCircleOutlineOutlined />}
        >
          <a href={registerUrl}>{registerButtonLabel}</a>
        </Button>
        
      </Toolbar>
    )
}

export default ManageToolbar
