import React, { Component } from "react";
import PropTypes from "prop-types";
import { InputAdornment, TextField, withStyles } from "@material-ui/core";
import { RemoveRedEye } from "@material-ui/icons";

const styles = () => ({
  // eye: {
  //   cursor: "pointer"
  // }
});

class PasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordIsMasked: true
    };
  }

  togglePasswordMask = () => {
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked
    }));
  };

  render() {
    const { passwordIsMasked } = this.state;

    return (
      <TextField
        variant="outlined"
        size="small"
        type={passwordIsMasked ? "password" : "text"}
        {...this.props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <RemoveRedEye
                style={{
                  cursor: "pointer"
                }}
                onClick={this.togglePasswordMask}
              />{" "}
            </InputAdornment>
          )
        }}
      />
    );
  }
}

PasswordInput.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default withStyles(styles)(PasswordInput);
