import { Grid } from "@material-ui/core";
import React from "react";


function UpdateFormHOC(props){

  return (
    <>
      <Grid container>
        <Grid md={2} xs={12} item />
        <Grid md={8} xs={12} item>
          {props.children}
        </Grid>
      </Grid>
    </>
  );
}

export default UpdateFormHOC;
