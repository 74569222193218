import { CircularProgress, Grid } from '@material-ui/core'
import { Field, Form, Formik } from 'formik';
import React from 'react'
import * as Yup from "yup";
import { makeStyles } from '@material-ui/core';
import Controls from '../controls/Controls';
import { agricFocusEnterpOptions, agricFocusValChainFocLevOptions, areaOpnTrgtDistrictsOptions, keyBenefFarmersCatOptions, keyBenefFarmersGenderOptions, keyBenefOptions, levOfOpnInPubSecOptions, sectorOptions, workStatusOptions } from '../../utils/options';
import { TextField } from 'formik-material-ui';
import { centralDistrictsOptions, easternDistrictsOptions, northernDistrictsOptions, westernDistrictsOptions } from '../../data/districts';
import { useDispatch } from 'react-redux'
import { actionUpdateWorkerWorkInfo } from '../../actions/worker';

const useStyles = makeStyles((theme) => ({
    tForm: {
        '& .MuiFormControl-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        }
    },
}))

function WorkInformationUpdateForm(prop) {
    const { data, history, reloadPage, closePopup } = prop;
    const classes = useStyles()
    const dispatch = useDispatch();

    return (
        <>
            <Grid container>
                <Grid item md={2} sm={12} />
                <Grid item md={8} sm={12}>
                    <Formik
                        initialValues={{
                            workStatus: data.WorkStatus,
                            wrkplaceOrg: data.WrkplaceOrg,
                            position: data.Position,
                            sectorOfOrganization: data.SectorOfOrganization,
                            levOfOpnInPubSec: data.LevOfOpnInPubSec,
                            agricFocus: data.AgricFocus,
                            agricFocusValChainFocLev: data.AgricFocusValChainFocLev ? data.AgricFocusValChainFocLev.split(",") : [],
                            agricFocusEnterp: data.AgricFocusEnterp ? data.AgricFocusEnterp.split(",") : [],
                            areaOfOpsTargetDistricts: data.AreaOfOpsTargetDistricts ? data.AreaOfOpsTargetDistricts.split(",") : [],
                            centralDistricts: data.CentralDistricts ? data.CentralDistricts.split(",") : [],
                            eastDistricts: data.EastDistricts ? data.EastDistricts.split(",") : [],
                            westDistricts: data.WestDistricts ? data.WestDistricts.split(",") : [],
                            northDistricts: data.NorthDistricts ? data.NorthDistricts.split(",") : [],
                            keyBenef: data.KeyBenef ? data.KeyBenef.split(",") : [],
                            keyBenefFarmersCat: data.KeyBenefFarmersCat ? data.KeyBenefFarmersCat.split(",") : [],
                            keyBenefFarmersGender: data.KeyBenefFarmersGender ? data.KeyBenefFarmersGender.split(",") : []
                        }}

                        validationSchema={Yup.object({
                            workStatus: Yup.string().nullable().required("Required"),
                            wrkplaceOrg: Yup.string().nullable(),
                            position: Yup.string().nullable(),
                            sectorOfOrganization: Yup.string().nullable(),
                            levOfOpnInPubSec: Yup.string().nullable(),
                            agricFocus: Yup.string().nullable(),
                            agricFocusValChainFocLev: Yup.string().nullable(),
                            agricFocusEnterp: Yup.string().nullable(),
                            areaOfOpsTargetDistricts: Yup.string().nullable(),
                            centralDistricts: Yup.string().nullable(),
                            eastDistricts: Yup.string().nullable(),
                            westDistricts: Yup.string().nullable(),
                            northDistricts: Yup.string().nullable(),
                            keyBenef: Yup.string().nullable(),
                            keyBenefFarmersCat: Yup.string().nullable(),
                            keyBenefFarmersGender: Yup.string().nullable()
                        })}
                        onSubmit={async (values, { setSubmitting }) => {

                            var hasCentral = values.areaOfOpsTargetDistricts.includes("Central") || values.areaOfOpsTargetDistricts.includes("All");
                            var hasEast = values.areaOfOpsTargetDistricts.includes("East") || values.areaOfOpsTargetDistricts.includes("All");
                            var hasWest = values.areaOfOpsTargetDistricts.includes("West") || values.areaOfOpsTargetDistricts.includes("All");
                            var hasNorth = values.areaOfOpsTargetDistricts.includes("North") || values.areaOfOpsTargetDistricts.includes("All");

                            //sanitizing conditions
                            if(values.areaOfOpsTargetDistricts.length === 0){
                                values = {
                                        ...values, 
                                        centralDistricts: [],
                                        eastDistricts: [],
                                        westDistricts: [],
                                        northDistricts: [],
                                         }
                            }else{
                                if(!hasCentral ){
                                    //centralDistricts = null
                                    values = { ...values, centralDistricts: [] }
                                }
                                if(!hasEast){
                                    //eastDistricts = null
                                    values = { ...values, eastDistricts: [] }
                                }
                                if(!hasWest){
                                    //westDistricts = null
                                    values = { ...values, westDistricts: [] }
                                }
                                if(!hasNorth){
                                    //northDistricts = null
                                    values = { ...values, northDistricts: [] }
                                }
                            }                        
                        
                            if(values.sectorOfOrganization !== "Public" ){
                                // levOfOpnInPubSec = null
                                values = { ...values, levOfOpnInPubSec: null }
                            } 
                            if(values.agricFocus !== "Specific" ){
                                // agricFocusValChainFocLev = null
                                // agricFocusEnterp = null
                                values = { ...values, agricFocusValChainFocLev: [], agricFocusEnterp: [] }
                            } 
                            
                            if(!values.keyBenef.includes("Farmers")){
                                //keyBenefFarmersCat = null
                                //keyBenefFarmersGender = null
                                values = { ...values, keyBenefFarmersCat: [], keyBenefFarmersGender: [] }
                            }
                            
                            await dispatch(actionUpdateWorkerWorkInfo(data.Id, values))
                                .then(() => {
                                    console.log(values)
                                    history.push(
                                        `/manage-worker/` + data.Id + `/details`
                                    );
                                    setSubmitting(false);
                                    reloadPage();
                                    closePopup();
                                })
                                .catch((e) => {
                                    setSubmitting(false);
                                    console.log("an error for occured ");
                                    console.log(e);
                                })
                        }}
                    >
                        {({ isSubmitting, isValid }) => (
                            <Form autoComplete="off" className={classes.tForm} >
                                <Field fullWidth name="workStatus">
                                    {({
                                        meta,
                                        form: { setFieldValue, values }
                                    }) => (
                                        <Controls.FormikSelect
                                            name="workStatus"
                                            value={values.workStatus}
                                            options={workStatusOptions}
                                            label="Work status"
                                            placeholder="Work status"
                                            setFieldValue={setFieldValue}
                                            meta={meta}
                                            freeSolo={true}
                                        />
                                    )}
                                </Field>
                                <Field
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    name="wrkplaceOrg"
                                    component={TextField}
                                    label="Workplace Organisation / Firm"
                                />
                                <Field
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    name="position"
                                    component={TextField}
                                    label="Position"
                                />
                                <Field fullWidth name="sectorOfOrganization">
                                    {({
                                        meta,
                                        form: { setFieldValue, values }
                                    }) => (
                                        <Controls.FormikSelect
                                            name="sectorOfOrganization"
                                            value={values.sectorOfOrganization}
                                            options={sectorOptions}
                                            label="Sector of the Organisation/firm"
                                            placeholder="Sector of the Organisation/firm"
                                            setFieldValue={setFieldValue}
                                            meta={meta}
                                            freeSolo={true}
                                        />
                                    )}
                                </Field>
                                <Field>
                                    {({ form: { values } }) => (
                                        <>
                                            {values.sectorOfOrganization === "Public" ? (

                                                <Field fullWidth name="levOfOpnInPubSec">
                                                    {({
                                                        meta,
                                                        form: { setFieldValue, values }
                                                    }) => (
                                                        <Controls.FormikSelect
                                                            name="levOfOpnInPubSec"
                                                            value={values.sectorOfOrganization}
                                                            options={levOfOpnInPubSecOptions}
                                                            label="Level of Operation in Public Sector"
                                                            placeholder="Level of Operation in Public Sector"
                                                            setFieldValue={setFieldValue}
                                                            meta={meta}
                                                            freeSolo={true}
                                                        />
                                                    )}
                                                </Field>


                                            ) : null}
                                        </>
                                    )}
                                </Field>

                                <Field fullWidth name="agricFocus">
                                    {({
                                        meta,
                                        form: { setFieldValue, values }
                                    }) => (
                                        <Controls.FormikSelect
                                            name="agricFocus"
                                            value={values.agricFocus}
                                            options={["General", "Specific"]}
                                            label="Your Agricultural Focus"
                                            placeholder="Your Agricultural Focus"
                                            setFieldValue={setFieldValue}
                                            meta={meta}
                                            freeSolo={true}
                                        />
                                    )}
                                </Field>

                                <Field>
                                    {({ form: { values } }) => (
                                        <>
                                            {values.agricFocus && values.agricFocus === "Specific" ? (
                                                <>
                                                    <Field fullWidth name="agricFocusValChainFocLev">
                                                        {({
                                                            meta,
                                                            form: { setFieldValue, values }
                                                        }) => (
                                                            <Controls.FormikSelect
                                                                multiple={true}
                                                                name="agricFocusValChainFocLev"
                                                                value={values.agricFocusValChainFocLev}
                                                                options={agricFocusValChainFocLevOptions}
                                                                label="Value Chain Focus Level"
                                                                placeholder="Value Chain Focus Level"
                                                                setFieldValue={setFieldValue}
                                                                meta={meta}
                                                                freeSolo={true}
                                                            />
                                                        )}
                                                    </Field>

                                                    <Field fullWidth name="agricFocusEnterp">
                                                        {({
                                                            meta,
                                                            form: { setFieldValue, values }
                                                        }) => (
                                                            <Controls.FormikSelect
                                                                multiple={true}
                                                                name="agricFocusEnterp"
                                                                value={values.agricFocusEnterp}
                                                                options={agricFocusEnterpOptions}
                                                                label="Focus Enterprises"
                                                                placeholder="Focus Enterprises"
                                                                setFieldValue={setFieldValue}
                                                                meta={meta}
                                                                freeSolo={true}
                                                            />
                                                        )}
                                                    </Field>
                                                </>
                                            ) : null}
                                        </>
                                    )}
                                </Field>

                                <Field fullWidth name="areaOfOpsTargetDistricts">
                                    {({
                                        meta,
                                        form: { setFieldValue, values }
                                    }) => (
                                        <Controls.FormikSelect
                                            multiple={true}
                                            name="areaOfOpsTargetDistricts"
                                            value={values.areaOfOpsTargetDistricts}
                                            options={areaOpnTrgtDistrictsOptions}
                                            label="Area of Operation: Target Districts"
                                            placeholder="Region"
                                            setFieldValue={setFieldValue}
                                            meta={meta}
                                            freeSolo={true}
                                        />
                                    )}
                                </Field>
                                <Field>
                                    {({ form: { values } }) => (
                                        <>
                                            {/* if Central  */}
                                            {(values.areaOfOpsTargetDistricts &&
                                                values.areaOfOpsTargetDistricts.includes("Central")) ||
                                                (values.areaOfOpsTargetDistricts &&
                                                    values.areaOfOpsTargetDistricts.includes("All")) ? (
                                                <>
                                                    <Field fullWidth name="centralDistricts">
                                                        {({
                                                            meta,
                                                            form: { setFieldValue, values }
                                                        }) => (
                                                            <Controls.FormikSelect
                                                                multiple={true}
                                                                name="centralDistricts"
                                                                value={values.centralDistricts}
                                                                options={centralDistrictsOptions}
                                                                label="Central Districts"
                                                                placeholder="Central Districts"
                                                                setFieldValue={setFieldValue}
                                                                meta={meta}
                                                                freeSolo={true}
                                                            />
                                                        )}
                                                    </Field>
                                                </>
                                            ) : null}

                                            {/* If East */}
                                            {(values.areaOfOpsTargetDistricts &&
                                                values.areaOfOpsTargetDistricts.includes("East")) ||
                                                (values.areaOfOpsTargetDistricts &&
                                                    values.areaOfOpsTargetDistricts.includes("All")) ? (
                                                <>
                                                    <Field fullWidth name="eastDistricts">
                                                        {({
                                                            meta,
                                                            form: { setFieldValue, values }
                                                        }) => (
                                                            <Controls.FormikSelect
                                                                multiple={true}
                                                                name="eastDistricts"
                                                                value={values.eastDistricts}
                                                                options={easternDistrictsOptions}
                                                                label="East Districts"
                                                                placeholder="Districts"
                                                                setFieldValue={setFieldValue}
                                                                meta={meta}
                                                                freeSolo={true}
                                                            />
                                                        )}
                                                    </Field>
                                                </>
                                            ) : null}

                                            {/* if West */}
                                            {(values.areaOfOpsTargetDistricts &&
                                                values.areaOfOpsTargetDistricts.includes("West")) ||
                                                (values.areaOfOpsTargetDistricts &&
                                                    values.areaOfOpsTargetDistricts.includes("All")) ? (
                                                <>
                                                    <Field fullWidth name="westDistricts">
                                                        {({
                                                            meta,
                                                            form: { setFieldValue, values }
                                                        }) => (
                                                            <Controls.FormikSelect
                                                                multiple={true}
                                                                name="westDistricts"
                                                                value={values.westDistricts}
                                                                options={westernDistrictsOptions}
                                                                label="West Districts"
                                                                placeholder="Districts"
                                                                setFieldValue={setFieldValue}
                                                                meta={meta}
                                                                freeSolo={true}
                                                            />
                                                        )}
                                                    </Field>
                                                </>
                                            ) : null}
                                            {/* if North */}
                                            {(values.areaOfOpsTargetDistricts &&
                                                values.areaOfOpsTargetDistricts.includes("North")) ||
                                                (values.areaOfOpsTargetDistricts &&
                                                    values.areaOfOpsTargetDistricts.includes("All")) ? (
                                                <>
                                                    <Field fullWidth name="northDistricts">
                                                        {({
                                                            meta,
                                                            form: { setFieldValue, values }
                                                        }) => (
                                                            <Controls.FormikSelect
                                                                multiple={true}
                                                                name="northDistricts"
                                                                value={values.northDistricts}
                                                                options={northernDistrictsOptions}
                                                                label="North Districts"
                                                                placeholder="Districts"
                                                                setFieldValue={setFieldValue}
                                                                meta={meta}
                                                                freeSolo={true}
                                                            />
                                                        )}
                                                    </Field>
                                                </>
                                            ) : null}
                                        </>
                                    )}
                                </Field>
                                {/* Key beneficiaries */}
                                <Field fullWidth name="keyBenef">
                                    {({
                                        meta,
                                        form: { setFieldValue, values }
                                    }) => (
                                        <Controls.FormikSelect
                                            multiple={true}
                                            name="keyBenef"
                                            value={values.keyBenef}
                                            options={keyBenefOptions}
                                            label="Key Beneficiaries"
                                            placeholder="Beneficiary"
                                            setFieldValue={setFieldValue}
                                            meta={meta}
                                            freeSolo={true}
                                        />
                                    )}
                                </Field>
                                {/* If key beneficiaries are farmers */}
                                <Field>
                                    {({ form: { values } }) => (
                                        <>
                                            {values.keyBenef &&
                                                values.keyBenef.includes("Farmers") ? (
                                                <>
                                                    {/* Category of farmers */}
                                                    <Field fullWidth name="keyBenefFarmersCat">
                                                        {({
                                                            meta,
                                                            form: { setFieldValue, values }
                                                        }) => (
                                                            <Controls.FormikSelect
                                                                multiple={true}
                                                                name="keyBenefFarmersCat"
                                                                value={values.keyBenefFarmersCat}
                                                                options={keyBenefFarmersCatOptions}
                                                                label="If farmers, which category"
                                                                placeholder="Category"
                                                                setFieldValue={setFieldValue}
                                                                meta={meta}
                                                                freeSolo={true}
                                                            />
                                                        )}
                                                    </Field>
                                                    {/* Gender of farmers */}
                                                    <Field fullWidth name="keyBenefFarmersGender">
                                                        {({
                                                            meta,
                                                            form: { setFieldValue, values }
                                                        }) => (
                                                            <Controls.FormikSelect
                                                                multiple={true}
                                                                name="keyBenefFarmersGender"
                                                                value={values.keyBenefFarmersGender}
                                                                options={keyBenefFarmersGenderOptions}
                                                                label="If farmers, which gender?"
                                                                placeholder="Gender"
                                                                setFieldValue={setFieldValue}
                                                                meta={meta}
                                                                freeSolo={true}
                                                            />
                                                        )}
                                                    </Field>
                                                </>
                                            ) : null}
                                        </>
                                    )}
                                </Field>
                                <Controls.Button
                                    startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                                    disabled={!isValid || isSubmitting}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    {isSubmitting
                                        ? "Submitting..."
                                        : "Submit"
                                    }
                                </Controls.Button>

                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </>
    )
}

export default WorkInformationUpdateForm
