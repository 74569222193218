import axios from "axios";
import authHeader from "./auth-header";
import moment from "moment"
import { API_URL } from "../utils/urls";


// const API_URL = "https://api.aeas-uganda.org/api/";
// const API_URL = "https://localhost:5001/api/";

const getAllRoles = () => {
    return axios.get(API_URL + "User/Admin/GetAllRoles", {
        headers: authHeader()
    });
}
const getPublicWorkersContent = () => {
    return axios.get(API_URL + "Individual/GetAll");
};

const getPublicOrganizationContent = () => {
    return axios.get(API_URL + "Organization/GetAllOrganizations");
};

const getAWorkerByIdAdminContent = (id) => {
    return axios.get(API_URL + "Individual/GetIndividual/"+id+"/Admin", {
        headers: authHeader()
    });
}

const getAnOrganizationByIdAdminContent = (id) => {
    return axios.get(API_URL + "Organization/GetOrganization/"+id+"/Admin", {
        headers: authHeader()
    });
}

const getAUserByEmailAdminContent = (email) => {
    return axios.get(API_URL + "User/Admin/GetEmailUser/"+email, {
        headers: authHeader()
    });
}

const getAWorkerByIdGuestContent = (id) => {
    return axios.get(API_URL + "Individual/GetIndividual/"+id);
}

const getAnOrganizationByIdGuestContent = (id) => {
    return axios.get(API_URL + "Organization/GetOrganization/"+id);
}

const getUsersList = () => {
    return axios.get(API_URL + "User/Admin/GetAllUsers", {
        headers: authHeader()
    });
}

const getUserBoard = () => {
    return axios.get(API_URL + "user", {
        headers: authHeader()
    });
};

const getModeratorBoard = () => {
    return axios.get(API_URL + "mod", {
        headers: authHeader()
    });
};

const getAdminBoard = () => {
    return axios.get(API_URL + "admin", {
        headers: authHeader()
    });
};

const registerWorker = (values) => {

    var data = {
        'Nin': values.nin,
        'FirstName': values.firstName,
        'LastName': values.lastName,
        'Dob': moment(values.dob).format("YYYY-MM-DD"),
        'Sex': values.sex,
        'Category': values.category,
        'Telephone1': values.telephone1,
        'Telephone2': values.telephone2,
        'Email': values.email,
        'DistrictOfRegistration': values.districtOfRegistration,
        'AgricRelQual': values.agricRelQual,
        'HighestQual': values.highestQual,
        'OtherHighestQual': values.otherHighestQual,
        'YearOfQual': values.yearOfQual,
        'otherMainSuppSkillsTrain': values.otherMainSuppSkillsTrain,
        'SupportSkillA': values.supportSkillA,
        'QualSupportSkillA': values.qualSupportSkillA,
        'DurationSkillA': values.durationSkillA,
        'SupportSkillB': values.supportSkillB,
        'QualSupportSkillB': values.qualSupportSkillB,
        'DurationSkillB': values.durationSkillB,
        'SupportSkillC': values.supportSkillC,
        'QualSupportSkillC': values.qualSupportSkillC,
        'DurationSkillC': values.durationSkillC,
        'WorkStatus': values.workStatus,
        'WrkplaceOrg': values.wrkplaceOrg,
        'Position': values.position,
        'SectorOfOrganization': values.sectorOfOrganization,
        'LevOfOpnInPubSec': values.levOfOpnInPubSec,
        'AgricFocus': values.agricFocus,
        'AgricFocusValChainFocLev': values.agricFocusValChainFocLev.join(),
        'AgricFocusEnterp': values.agricFocusEnterp.join(),
        'AreaOfOpsTargetDistricts': values.areaOfOpsTargetDistricts.join(),
        'CentralDistricts': values.centralDistricts.join(),
        'EastDistricts': values.eastDistricts.join(),
        'WestDistricts': values.westDistricts.join(),
        'NorthDistricts': values.northDistricts.join(),
        'KeyBenef': values.keyBenef.join(),
        'KeyBenefFarmersCat': values.keyBenefFarmersCat.join(),
        'KeyBenefFarmersGender': values.keyBenefFarmersGender.join(),
        'CapacityNeedFirstChoice': values.capacityNeedFirstChoice,
        'CapacityNeedSecondChoice': values.capacityNeedSecondChoice,
        'CapacityNeedThirdChoice': values.capacityNeedThirdChoice,

        'Country': values.country,
        'PassportNo': values.passportNo,
        'WorkPermitNo': values.workPermitNo,
        'ExpDateOfWorkPermit': values.workPermitExpiryDate ? moment(values.workPermitExpiryDate).format("YYYY-MM-DD") : null,
    }

    return axios.post(API_URL + "Individual/AddIndividual", data, {
        headers: authHeader()
    }).then((response) => {
        return response.data;
    });
};

const registerOrganization = (values) => {

    var data = {
        'Name': values.name,
        'TypeOfReg': values.typeOfReg,
        'DateOfIncorp': moment(values.dateOfIncorp).format("YYYY-MM-DD"),
        'RegNoEntity': values.regNoEntity,
        'Email': values.email,
        'Country': values.country,
        'Telephone': values.telephone,
        'Website': values.website,
        'PostalAddress': values.postalAddress,
        'HeadOfficeDistrict': values.headOfficeDistrict,
        'HeadOfficeDiv': values.headOfficeDiv,
        'HeadOfficeVillage': values.headOfficeVillage,
        'HeadOfficeStreet': values.headOfficeStreet,
        'ContName': values.contName,
        'ContPosition': values.contPosition,
        'ContTelephone': values.contTelephone,
        'ContEmail': values.contEmail,
        'NumStaffMale': values.numStaffMale,
        'NumStaffFemale': values.numStaffFemale,
        'PhdMales': values.phdMales,
        'PhdFemales': values.phdFemales,
        'MastrsMales': values.mastrsMales,
        'MastrsFemales': values.mastrsFemales,
        'BscMale': values.bscMale,
        'BscFemale': values.bscFemale,
        'DipMale': values.dipMale,
        'DipFemale': values.dipFemale,
        'CertMale': values.certMale,
        'CertFemale': values.certFemale,
        'Sector': values.sector,
        'AgricSectorFocus': values.agricSectorFocus,
        'AgricFocusValChainFocLev': values.focusValueChain.join(),
        'AgricFocusEnterp': values.focusEnterprises.join(),
        'Mission': values.mission,
        'AreaOpnTrgtDistricts': values.areaOpnTrgtDistricts.join(),
        'CentralDistricts': values.centralDistricts.join(),
        'EastDistricts': values.eastDistricts.join(),
        'WestDistricts': values.westDistricts.join(),
        'NorthDistricts': values.northDistricts.join(),
        'KeyBenefs': values.keyBenef.join(),
        'KeyBeneFrmrsCateg': values.keyBeneFrmrsCateg.join(),
        'KeyBeneFrmrsGender': values.keyBenefrmrsGender.join(),
        'CapacityNeedFirstChoice': values.capacityNeedFirstChoice,
        'CapacityNeedSecondChoice': values.capacityNeedSecondChoice,
        'CapacityNeedThirdChoice': values.capacityNeedThirdChoice,
    }

    return axios.post(API_URL + "Organization/AddNew", data, {
        headers: authHeader()
    }).then((response) => {
        return response.data
    })
}

const UpdateIndividualCapacityNeeds = (id, update) => {

    var data = {
        Id: id,
        CapacityNeedFirstChoice: update.capacityNeedFirstChoice,
        CapacityNeedSecondChoice: update.capacityNeedSecondChoice,
        CapacityNeedThirdChoice: update.capacityNeedThirdChoice,
    };

    return axios.put(API_URL + `Individual/update/${id}/CapacityNeeds`, data, {
        headers: authHeader()
    }).then((response) => {
        return response.data
    })
}


const UpdateIndividualContactInfo = (id, update) => {

    var data = {
        Id: id,
        Telephone1: update.telephone1,
        Telephone2: update.telephone2,
        Email: update.email
    };

    return axios.put(API_URL + `Individual/update/${id}/ContactInfo`, data, {
        headers: authHeader()
    }).then((response) => {
        return response.data
    });
}


const UpdateIndividualPersonalDetails = (id, update) => {

    var data = {
        Id : id,
        Nin: update.Nin,
        FirstName: update.firstName,
        LastName: update.lastName,
        Dob: moment(update.dob).format("YYYY-MM-DD"),
        Sex: update.sex
    };

    return axios.put(API_URL + `Individual/update/${id}/PersonalDetails`,data, {
        headers: authHeader()
    }).then((response)=>{
        return response.data
    });
}

const UpdateIndividualQualifications = (id, update) => {
    var data = {
        Id: id ,
        AgricRelQual: update.agricRelQual ,
        HighestQual: update.highestQual ,
        OtherHighestQual: update.otherHighestQual ,
        YearOfQual: update.yearOfQual ,
        SupportSkillA: update.supportSkillA ,
        QualSupportSkillA: update.qualSupportSkillA ,
        DurationSkillA: update.durationSkillA ,
        SupportSkillB: update.supportSkillB ,
        QualSupportSkillB: update.qualSupportSkillB ,
        DurationSkillB: update.durationSkillB,
        SupportSkillC: update.supportSkillC ,
        QualSupportSkillC: update.qualSupportSkillC ,
        DurationSkillC: update.durationSkillC ,
    };

    return axios.put(API_URL + `Individual/update/${id}/Qualifications`, data, {
        headers: authHeader()
    }).then((resp) => { return resp.data });
}

const UpdateIndividualWorkInfo = (id, update) => {
    var data ={
        Id: id,
        WorkStatus: update.workStatus,
        WrkplaceOrg: update.wrkplaceOrg,
        Position: update.position,
        SectorOfOrganization: update.sectorOfOrganization,
        LevOfOpnInPubSec: update.levOfOpnInPubSec,
        AgricFocus: update.agricFocus,
        AgricFocusValChainFocLev: update.agricFocusValChainFocLev.join(),
        AgricFocusEnterp: update.agricFocusEnterp.join(),
        AreaOfOpsTargetDistricts: update.areaOfOpsTargetDistricts.join(),
        CentralDistricts: update.centralDistricts.join(),
        EastDistricts: update.eastDistricts.join(),
        WestDistricts: update.westDistricts.join(),
        NorthDistricts: update.northDistricts.join(),
        KeyBenef: update.keyBenef.join(),
        KeyBenefFarmersCat: update.keyBenefFarmersCat.join(),
        KeyBenefFarmersGender: update.keyBenefFarmersGender.join(),
    };

    return axios.put(API_URL + `Individual/update/${id}/WorkInfo`, data, {
        headers: authHeader()
    }).then((resp)=>{ return resp.data });
}

const UpdateOrgAbout = (id, update) => {
    var data = {
        Id: id,
        Email: update.email,
        Telephone: update.telephone,
        Website: update.website,
        PostalAddress: update.postalAddress
    };

    return axios.put(API_URL + `Organization/update/${id}/About`, data, {
        headers: authHeader()
    }).then((resp) => { return resp.data });
}


const UpdateOrgHeadOffice = (id, update) => {
    var data = {
        Id: id,
        HeadOfficeDistrict: update.headOfficeDistrict,
        HeadOfficeDiv: update.headOfficeDiv,
        HeadOfficeStreet: update.headOfficeStreet,
        HeadOfficeVillage: update.headOfficeVillage
    };

    return axios.put(API_URL + `Organization/update/${id}/HeadOffice`, data, {
        headers: authHeader()
    }).then((resp)=> { return resp.data });
}

const UpdateOrgContactPerson = (id, update) => {
    var data = {
        Id: id,
        ContName: update.contName,
        ContPosition: update.contPosition,
        ContTelephone: update.contTelephone,
        ContEmail: update.contEmail
    };

    return axios.put(API_URL + `Organization/update/${id}/ContactPerson`, data, {
        headers: authHeader()
    }).then((resp) => { return resp.data });
}

const UpdateOrgStaff = (id, update) => {
    var data = {
        Id: id,
        NumStaffMale: update.numStaffMale,
        NumStaffFemale: update.numStaffFemale,
        PhdMales: update.phdMales ,
        PhdFemales: update.phdFemales,
        MastrsMales: update.mastrsMales,
        MastrsFemales: update.mastrsFemales,
        BscMale: update.bscMale,
        BscFemale: update.bscFemale,
        DipMale: update.dipMale,
        DipFemale: update.dipFemale,
        CertMale: update.certMale,
        CertFemale: update.certFemale,
    };

    return axios.put(API_URL + `Organization/update/${id}/Staff`, data, {
        headers: authHeader()
    }).then((resp) => { return resp.data });
}

const UpdateOrgSectorInfo = (id, update) => {

    var data = {
        Id: id,
        Sector: update.sector,
        AgricSectorFocus: update.agricSectorFocus,
        AgricFocusValChainFocLev: update.focusValueChain.join(),
        AgricFocusEnterp: update.focusEnterprises.join(),
        Mission: update.mission,
        AreaOpnTrgtDistricts: update.areaOpnTrgtDistricts.join(),
        CentralDistricts: update.centralDistricts.join(),
        EastDistricts: update.eastDistricts.join(),
        WestDistricts: update.westDistricts.join(),
        NorthDistricts: update.northDistricts.join(),
        KeyBenefs: update.keyBenefs.join(),
        KeyBeneFrmrsCateg: update.keyBeneFrmrsCateg.join(),
        KeyBeneFrmrsGender: update.keyBenefFarmersGender.join(),
    };

    return axios.put(API_URL + `Organization/update/${id}/SectorInfo`, data, {
        headers: authHeader()
    }).then((resp)=> {return resp.data});
}

const UpdateOrgCapacityNeeds = (id, update) => {
    var data = {
        Id: id, 
        CapacityNeedFirstChoice: update.capacityNeedFirstChoice,
        CapacityNeedSecondChoice: update.capacityNeedSecondChoice,
        CapacityNeedThirdChoice: update.capacityNeedThirdChoice,
    };

    return axios.put(API_URL + `Organization/update/${id}/CapacityNeeds`, data, {
        headers: authHeader()
    }).then((resp) => resp.data);
}

const removeUserRole = (update) => {
    var data = { 'UserId': update.userid, 'RoleName': update.role, }; 

    return axios.post(API_URL + `User/Admin/RemoveARole`, data, {
        headers: authHeader()
    }).then((resp) => resp.data);
}

const addUserRole = (update) => {
    var data = { 'UserId': update.userid, 'RoleName': update.role, }; 

    return axios.post(API_URL + `User/Admin/AddARole`, data, {
        headers: authHeader()
    }).then((resp) => resp.data);
}

export default {
    getPublicWorkersContent,
    getPublicOrganizationContent,
    getUsersList,
    getUserBoard,
    getModeratorBoard,
    getAdminBoard,
    registerWorker,
    registerOrganization,
    getAllRoles,
    UpdateIndividualCapacityNeeds,
    UpdateIndividualContactInfo,
    UpdateIndividualPersonalDetails,
    UpdateIndividualQualifications,
    UpdateIndividualWorkInfo,
    UpdateOrgAbout,
    UpdateOrgHeadOffice,
    UpdateOrgContactPerson,
    UpdateOrgStaff,
    UpdateOrgSectorInfo,
    UpdateOrgCapacityNeeds,
    removeUserRole,
    addUserRole,
    getAWorkerByIdAdminContent,
    getAnOrganizationByIdAdminContent,
    getAUserByEmailAdminContent,
    getAWorkerByIdGuestContent,
    getAnOrganizationByIdGuestContent,
};