import { makeStyles, Toolbar } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    // display: "flex",
    marginBottom: "-20px",
    color: theme.palette.primary.main,
    "& .MuiIconButton-root": {
      position: "absolute",
      right: "0px",
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      fontSize: "large",
      marginLeft: "0px",
    },
  },
}));

function GuestSectionHeader(props) {
  const classes = useStyles();
  const { title } = props;
  return (
    <>
      <Toolbar className={classes.title}>
        {title}
      </Toolbar>
     
    </>
  );
}

export default GuestSectionHeader;
