export const supportSkillOptions = [
    "Management", "ICT", "Research Methods", "Project/Programme Planning",
    "Orgnizational Development", "Public Administration", "Human Resource",
    "Monitoring and Evaluation", "Other(specify)"
];
export const qualificationOptions = [
    "PhD", "Masters", "Bsc", "Diploma", "Certificate", "Other(specify)"
];

export const skillsDurationOptions = [{
        value: "1 - 3 months"
    },
    {
        value: "4 - 11 months"
    },
    {
        value: "in 1 year"
    },
    {
        value: "2 years"
    },
    {
        value: "3 years"
    },
    {
        value: "more than 3 years"
    }
];
export const workStatusOptions = [
    "Employed", "Self-Employed", "Other"
];

export const sectorOptions = [
    "Public",
    "Private",
    "Academia",
    "Professional Body",
    "NGO",
    "Farmers Organization",
    "Media",
    "Other"
];

export const agricSectorFocusOptions = [
    "General",
    "Specific"
];

export const levOfOpnInPubSecOptions = [
    "National Level", "Local Gov't(District)", "Local Gov't(Sub-county)"
];

export const agricFocusValChainFocLevOptions = [
    "All",
    "Inputs",
    "Production",
    "Post-Harverst",
    "Agro-processing",
    "Marketing",
    "Consultancy",
    "Other"
];

export const agricFocusEnterpOptions = [
    "All",
    "Large livestock(cattle)",
    "Small livestock(sheep, goat)",
    "Micro livestock(rabbits)",
    "Birds(chicken, turkey, ducks )",
    "Bees",
    "Fisheries",
    "Traditional cash crops(tea, cocoa, coffee, sugarcanes)",
    "Cereals and legumes(maize, rice, sorghum, beans, soya, cowpeas)",
    "Fruits and vegetables",
    "Commercial trees",
    "Other(specify)"
];

export const areaOpnTrgtDistrictsOptions = [
    "All",
    "Central",
    "East",
    "West",
    "North"
]

export const keyBenefOptions = [
    "Farmers",
    "Students",
    "Field Extension Workers",
    "Agro-processors",
    "Traders",
    "Input dealers",
    "Researchers",
    "Others"
]

export const keyBenefFarmersCatOptions = [
    "All Farmers",
    "Rural",
    "Peri-Urban",
    "Others"
];

export const keyBenefFarmersGenderOptions = [
    "All Genders",
    "Men",
    "Women",
    "Youths",
    "Others"
];

export const capacityNeedsOptions = [
    "Extension approaches and methods",
    "ICT for agriculture",
    "Professional ethics, guidelines and standards",
    "Knowledge management for Extension",
    "Facilitation for development",
    "Farmer organisational development",
    "Value chain development",
    "Agricultural Entrepreneurship",
    "Gender in extension and advisory services",
    "Climate change and risk management",
    "Others"
];

export const agricRelQualOptions = [
    "Crop",
    "Animal science",
    "Fisheries",
    "Forestry",
    "Veterinary",
    "Extension",
    "Agric. Engineering",
    "Soil science",
    "Agribusiness",
    "Entomology",
    "Environment",
    "Food science/nutrition",
    "Agricultural Economics",
    "Other(specify)"
];