import { CircularProgress } from "@material-ui/core";
import React from "react";
import Button from "./Button";

function SubmitButton(props) {
  const { isSubmitting, isValid } = props;
  return (
    <Button
      startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
      disabled={!isValid || isSubmitting}
      variant="contained"
      color="primary"
      type="submit"
    >
      {/* {JSON.stringify(errors)} */}
      {isSubmitting ? "Submitting..." : "Submit"}
    </Button>
  );
}

export default SubmitButton;
