import {
  REGISTER_WORKER,
  REGISTER_WORKER_SUCCESS,
  REGISTER_WORKER_FAIL,
  FETCH_ALL_WORKERS,
  FETCH_ALL_WORKERS_SUCCESS,
  FETCH_ALL_WORKERS_FAIL,
  FETCH_A_WORKER_BY_ID,
  FETCH_A_WORKER_BY_ID_SUCCESS,
  FETCH_A_WORKER_BY_ID_FAIL,
  FETCH_WORKER_PROFILE_FOR_GUEST_BY_ID,
  FETCH_WORKER_PROFILE_FOR_GUEST_BY_ID_SUCCESS,
  FETCH_WORKER_PROFILE_FOR_GUEST_BY_ID_FAIL,
  UPDATE_INDIVIDUAL_DETAILS,
  UPDATE_INDIVIDUAL_DETAILS_SUCCESS,
  UPDATE_INDIVIDUAL_DETAILS_FAIL,
  UPDATE_INDIVIDUAL_CONTACT,
  UPDATE_INDIVIDUAL_CONTACT_SUCCESS,
  UPDATE_INDIVIDUAL_CONTACT_FAIL,
  UPDATE_INDIVIDUAL_QUALIFICATIONS,
  UPDATE_INDIVIDUAL_QUALIFICATIONS_SUCCESS,
  UPDATE_INDIVIDUAL_QUALIFICATIONS_FAIL,
} from "../actions/types";

const initState = {
  isSaving: false,
  workerID: "",
  saved: false,
  isFetching: false,
  fetched: false,
  workers: [],
  worker: {},
};

const workerReducer = (state = initState, action) => {
  switch (action.type) {
    case REGISTER_WORKER:
      return {
        ...state,
        isSaving: true,
      };
    case REGISTER_WORKER_SUCCESS:
      return {
        ...state,
        isSaving: false,
        workerID: action.payload.workerId,
        saved: true,
      };
    case REGISTER_WORKER_FAIL:
      return {
        ...state,
        isSaving: false,
        workerID: "Something went wrong.Try again",
        saved: false,
      };

    case FETCH_ALL_WORKERS:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_ALL_WORKERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        workers: action.payload.data,
      };

    case FETCH_ALL_WORKERS_FAIL:
      return {
        ...state,
        isFetching: false,
        fetched: false,
        workers: [],
      };

    case FETCH_A_WORKER_BY_ID:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_A_WORKER_BY_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        worker: action.payload.data,
      };

    case FETCH_A_WORKER_BY_ID_FAIL:
      return {
        ...state,
        isFetching: false,
        fetched: false,
        worker: {},
      };

    case FETCH_WORKER_PROFILE_FOR_GUEST_BY_ID:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_WORKER_PROFILE_FOR_GUEST_BY_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        worker: action.payload.data,
      };

    case FETCH_WORKER_PROFILE_FOR_GUEST_BY_ID_FAIL:
      return {
        ...state,
        isFetching: false,
        fetched: false,
        worker: {},
      };

    case UPDATE_INDIVIDUAL_DETAILS:
      return {
        ...state,
        isSaving: true,
      };

    case UPDATE_INDIVIDUAL_DETAILS_SUCCESS:
      return {
        ...state,
        isSaving: false,
        saved: true,
      };

    case UPDATE_INDIVIDUAL_DETAILS_FAIL:
      return {
        ...state,
        isSaving: false,
        saved: false,
      };

    case UPDATE_INDIVIDUAL_CONTACT:
      return {
        ...state,
        isSaving: true,
      };

    case UPDATE_INDIVIDUAL_CONTACT_SUCCESS:
      return {
        ...state,
        isSaving: false,
        saved: true,
      };

    case UPDATE_INDIVIDUAL_CONTACT_FAIL:
      return {
        ...state,
        isSaving: false,
        saved: false,
      };
      case UPDATE_INDIVIDUAL_QUALIFICATIONS:
        return {
          ...state,
          isSaving: true,
        };
  
      case UPDATE_INDIVIDUAL_QUALIFICATIONS_SUCCESS:
        return {
          ...state,
          isSaving: false,
          saved: true,
        };
  
      case UPDATE_INDIVIDUAL_QUALIFICATIONS_FAIL:
        return {
          ...state,
          isSaving: false,
          saved: false,
        };



    default:
      return state;
  }
};

export default workerReducer;
