import { Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import SectionHeader from "./SectionHeader";
import { useTable } from "./useTable";
import UpdateUserForm from "./users/UpdateUserForm";

const useStyles = makeStyles((theme) => ({
    section: {
        margin: theme.spacing(2),
    },
}));

function UserProfile(props) {
    const { profile, refresh, routeHistory } = props;
    const { TblContainer, SectionLabelValueMultiRow } = useTable();
    const classes = useStyles();
    const [openPopup, setOpenPopup] = useState(false);

    const userinfo = [
        { label: "First name", value: profile.FirstName },
        { label: "Second name", value: profile.LastName },
        { label: "Contact", value: profile.Contact },
        { label: "Email", value: profile.Email },
        {
            label: "Roles", value: profile.Roles && typeof profile.Roles === "object" ? (
                Object.entries(profile.Roles).map(([key, val]) => (val)).toString()
            ) : ""
        },
    ];
    
    return (
        <>
            <Grid container>
                <Grid item xs={12} md={12} className={classes.section}>
                    <h1>User Details</h1>
                    <SectionHeader
                        onClick={() => {
                            setOpenPopup(true);
                        }}
                        title=""
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                        popupTitle="Update user details"
                    >
                        <UpdateUserForm
                        refreshPage={refresh}
                        closePopup={()=>{setOpenPopup(false)}  }
                        routeHistory = {routeHistory}
                        data={{
                            ...profile,
                            roles: profile.Roles && typeof profile.Roles === "object" ? (
                                Object.entries(profile.Roles).map(([key, val]) => (val)).toString()
                            ) : ""
                        }} />
                    </SectionHeader>
                    <br />
                    <SectionLabelValueMultiRow data={userinfo} />
                </Grid>
            </Grid>
        </>
    )
}

export default UserProfile
