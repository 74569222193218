import { IconButton, Button, makeStyles, Toolbar, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React from "react";
import Popup from "./Popup";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    // marginBottom: "-20px",
    // color: theme.palette.primary.main,
    "& .MuiIconButton-root": {
      position: "absolute",
      right: "20px",
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      fontSize: "large",
      marginLeft: "5px",
    },
  },
}));

function SectionHeader(props) {
  const classes = useStyles();
  const { title, onClick, openPopup, setOpenPopup, popupTitle } = props;
  return (
    <>
      <div className={classes.title}>
        <h1>{title}</h1>
        <Button variant="contained" size="medium" color="primary" onClick={onClick}>
          <Typography variant="caption"><i className="fa-solid fa-gear"></i> &nbsp;Update</Typography>
        </Button>
      </div>
      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title={popupTitle}
      >
        {props.children}
      </Popup>
    </>
  );
}

export default SectionHeader;
