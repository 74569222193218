import { VerifiedUser } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import PageHeader from "../components/PageHeader";
import Controls from "../components/controls/Controls";
import { useDispatch, useSelector } from "react-redux";
import { organizationFetchAll } from "../actions/user";
import { useTable } from "../components/useTable";
import { TableBody, TableCell, TableRow } from "@material-ui/core";


const headCells = [
    { id: "orgID", label: "ORG. ID" },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "headOfficeDistrict", label: "Head office district" },
    { id: "mission", label: "Mission" },
    { id: "keybenefs", label: "Key beneficiaries" },
    { id: "actions", label: "Action" },
  ];


function GuestOrganizations() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(organizationFetchAll())
      .then(() => {})
      .catch(() => {
        console.log("AN ERROR OCCURED");
      });
  }, []);

  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const orgs = useSelector((state) => state.organization.organizations);
  const isFetching = useSelector((state) => state.organization.isFetching);

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingaAndSorting,
  } = useTable(orgs.data, headCells, filterFn);


  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter(
            (x) =>
              x.name.toLowerCase().includes(target.value.toLowerCase()) ||
              x.id.toLowerCase().includes(target.value.toLowerCase()) ||
              x.email.toLowerCase().includes(target.value.toLowerCase())
          );
      },
    });
  };

  return (
    <>
      <PageHeader
        icon={<VerifiedUser />}
        title="Organizations"
        subtitle="List of registered organizations"
      />
      <Controls.GuestToolbar
        searchLabel="Search organization"
        handleSearch={handleSearch}
      />
      <TblPagination />
      <TblContainer>
        <TblHead />
        <TableBody>
          {recordsAfterPagingaAndSorting().map((org) => (
            <TableRow key={org.Id}>
              <TableCell>{org.OUID}</TableCell>
              <TableCell>{org.Name}</TableCell>
              <TableCell>{org.Email}</TableCell>
              <TableCell>{org.HeadOfficeDistrict}</TableCell>
              <TableCell>{org.Mission}</TableCell>
              <TableCell>{org.KeyBenefs}</TableCell>
              <TableCell>
                <a href={`/organization/` + org.Id + `/details`}>
                  All Details
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TblContainer>
      <TblPagination />
      {isFetching ? "Loading..." : null}
    </>
  );
}

export default GuestOrganizations;
