import axios from "axios";
import authHeader from "./auth-header";
import jwt_decode from "jwt-decode";
import { API_URL, BASE_URL } from "../utils/urls";

// const BASE_URL = "https://api.aeas-uganda.org/";
// const API_URL = "https://api.aeas-uganda.org/api/";

// const BASE_URL = "https://localhost:5001/";
// const API_URL = "https://localhost:5001/api/";


const register = (firstName,
    secondName,
    contact,
    roles,
    email,
    password,
    confirmPassword) => {

    return axios.post(API_URL + "User/Admin/AddNewUser", {
        FirstName: firstName,
        SecondName: secondName,
        Contact: contact,
        Roles: roles,
        Email: email,
        Password: password,
        ConfirmPassword: confirmPassword
    }, {
        headers: {
            ...authHeader(),
            Accept: "application/json",
        }
    });
};

const updateUser = (id, values) => {
    var data = {
        'Id' : values.id,
        'FirstName': values.firstName,
        'LastName': values.secondName,
        'Contact': values.contact,
        'Roles': values.userRoles,
    };

    console.log(data);

    return axios.put(API_URL + "User/update/"+id+"/Details", data, {
        headers: {
            ...authHeader(),
            Accept: "application/json",
        }
    });
};

const deleteUser = (id) => {

    return axios.delete(API_URL + "User/delete/user/"+id, {
        headers: {
            ...authHeader(),
            Accept: "application/json",
        }
    });
};

const login = (username, password) => {

    var data = { "username" : username, "password": password };

    return axios
        .post(BASE_URL + "api/Login/login", data, {
            headers: {
                authorization: 'No Auth'
            }
        })
        .then((response) => {
            if (response.data.token) {
                console.log(response.data);
                localStorage.setItem("user", JSON.stringify({...response.data, access_Token: response.data.token}));
            }

            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
};

const tokenIsValid = () => {
    const token = JSON.parse(localStorage.getItem("user")) && JSON.parse(localStorage.getItem("user"))["access_Token"];
    if (token) {
        if (jwt_decode(token).exp < Date.now() / 5000) {
            localStorage.clear();
            // console.log("toekn expired")
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
};


const getAuth = () => {
    const token = JSON.parse(localStorage.getItem("user")) && JSON.parse(localStorage.getItem("user"))["access_Token"];
    if (token) {
        if (jwt_decode(token).exp < Date.now() / 1000) {
            localStorage.clear();
            // console.log("toekn expired")
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
};
export default {
    register,
    updateUser,
    login,
    logout,
    tokenIsValid,
    getAuth,
    deleteUser,
};