import authService from "../services/auth.service";
import userService from "../services/user.service";
import {
  LOGOUT,
  SET_MESSAGE,
  UPDATE_ORGANIZATION_ABOUT_FAIL,
  UPDATE_ORGANIZATION_ABOUT_SUCCESS,
  UPDATE_ORGANIZATION_CAPACITY_NEEDS_FAIL,
  UPDATE_ORGANIZATION_CAPACITY_NEEDS_SUCCESS,
  UPDATE_ORGANIZATION_CONTACT_PERSON_FAIL,
  UPDATE_ORGANIZATION_CONTACT_PERSON_SUCCESS,
  UPDATE_ORGANIZATION_HEAD_OFFICE_FAIL,
  UPDATE_ORGANIZATION_HEAD_OFFICE_SUCCESS,
  UPDATE_ORGANIZATION_SECTOR_INFO_FAIL,
  UPDATE_ORGANIZATION_SECTOR_INFO_SUCCESS,
  UPDATE_ORGANIZATION_STAFF_FAIL,
  UPDATE_ORGANIZATION_STAFF_SUCCESS,
} from "./types";

export const actionUpdateOrgAbout = (id, update) => (dispatch) => {
  if (!authService.tokenIsValid()) {
    console.log("USER LOGGED OUT FOR EXPIRED TOKEN");
    dispatch({
      type: LOGOUT,
    });
    return Promise.resolve();
  }

  return userService.UpdateOrgAbout(id, update).then(
    (data) => {
      dispatch({
        type: UPDATE_ORGANIZATION_ABOUT_SUCCESS,
        payload: {},
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(error);
      console.log(message);
      dispatch({
        type: UPDATE_ORGANIZATION_ABOUT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const actionUpdateOrgHeadOffice = (id, update) => (dispatch) => {
  if (!authService.tokenIsValid()) {
    console.log("USER LOGGED OUT FOR EXPIRED TOKEN");
    dispatch({
      type: LOGOUT,
    });
    return Promise.resolve();
  }

  return userService.UpdateOrgHeadOffice(id, update).then(
    (data) => {
      dispatch({
        type: UPDATE_ORGANIZATION_HEAD_OFFICE_SUCCESS,
        payload: {},
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(error);
      console.log(message);
      dispatch({
        type: UPDATE_ORGANIZATION_HEAD_OFFICE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const actionUpdateOrgContactPerson = (id, update) => (dispatch) => {
  if (!authService.tokenIsValid()) {
    console.log("USER LOGGED OUT FOR EXPIRED TOKEN");
    dispatch({
      type: LOGOUT,
    });
    return Promise.resolve();
  }

  return userService.UpdateOrgContactPerson(id, update).then(
    (data) => {
      dispatch({
        type: UPDATE_ORGANIZATION_CONTACT_PERSON_SUCCESS,
        payload: {},
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(error);
      console.log(message);
      dispatch({
        type: UPDATE_ORGANIZATION_CONTACT_PERSON_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const actionUpdateOrgStaff = (id, update) => (dispatch) => {
  if (!authService.tokenIsValid()) {
    console.log("USER LOGGED OUT FOR EXPIRED TOKEN");
    dispatch({
      type: LOGOUT,
    });
    return Promise.resolve();
  }

  return userService.UpdateOrgStaff(id, update).then(
    (data) => {
      dispatch({
        type: UPDATE_ORGANIZATION_STAFF_SUCCESS,
        payload: {},
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(error);
      console.log(message);
      dispatch({
        type: UPDATE_ORGANIZATION_STAFF_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const actionUpdateOrgSectorInfo = (id, update) => (dispatch) => {
  if (!authService.tokenIsValid()) {
    console.log("USER LOGGED OUT FOR EXPIRED TOKEN");
    dispatch({
      type: LOGOUT,
    });
    return Promise.resolve();
  }

  return userService.UpdateOrgSectorInfo(id, update).then(
    (data) => {
      dispatch({
        type: UPDATE_ORGANIZATION_SECTOR_INFO_SUCCESS,
        payload: {},
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(error);
      console.log(message);
      dispatch({
        type: UPDATE_ORGANIZATION_SECTOR_INFO_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const actionUpdateOrgCapacityNeeds = (id, update) => (dispatch) => {
  if (!authService.tokenIsValid()) {
    console.log("USER LOGGED OUT FOR EXPIRED TOKEN");
    dispatch({
      type: LOGOUT,
    });
    return Promise.resolve();
  }

  return userService.UpdateOrgCapacityNeeds(id, update).then(
    (data) => {
      dispatch({
        type: UPDATE_ORGANIZATION_CAPACITY_NEEDS_SUCCESS,
        payload: {},
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(error);
      console.log(message);
      dispatch({
        type: UPDATE_ORGANIZATION_CAPACITY_NEEDS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
