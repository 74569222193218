import { IconButton, Typography } from "@material-ui/core";
import { ArrowBackTwoTone } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { workersFetchDetailsById } from "../actions/user";
import PageHeader from "../components/PageHeader";
import WorkerProfile from "../components/workers/WorkerProfile";

function WorkerDetails(props) {
  const uuid = props.match.params.id;
  const dispatch = useDispatch();
  const history = props.history;

  useEffect(() => {
    reloadPage();
  }, []);
  const worker = useSelector((state) => state.worker.worker);
  const isFetching = useSelector((state) => state.worker.isFetching);

  const reloadPage = () => {
    dispatch(workersFetchDetailsById(uuid))
      .then(() => {})
      .catch(() => {
        console.log("AN ERROR OCCURED");
      });
  };

  return (
    <>
      {isFetching ? (
        <Typography variant="subtitle1">Fetching....</Typography>
      ) : null}
      <PageHeader
        title="Extension worker"
        subtitle="Manage details"
        icon={
          <IconButton>
            <a href="\manage-workers">
              <ArrowBackTwoTone />
            </a>
          </IconButton>
        }
      />
      <WorkerProfile
        profile={worker}
        history={history}
        reloadPage={reloadPage}
      />
    </>
  );
}

export default WorkerDetails;
