import {
  REGISTER_ORGANIZATION,
  REGISTER_ORGANIZATION_SUCCESS,
  REGISTER_ORGANIZATION_FAIL,
  FETCH_ALL_ORGANIZATIONS,
  FETCH_ALL_ORGANIZATIONS_SUCCESS,
  FETCH_ALL_ORGANIZATIONS_FAIL,
  FETCH_AN_ORGANIZATION_BY_ID,
  FETCH_AN_ORGANIZATION_BY_ID_SUCCESS,
  FETCH_AN_ORGANIZATION_BY_ID_FAIL,
  FETCH_ORGANIZAION_PROFILE_FOR_GUEST_BY_ID,
  FETCH_ORGANIZAION_PROFILE_FOR_GUEST_BY_ID_SUCCESS,
  FETCH_ORGANIZAION_PROFILE_FOR_GUEST_BY_ID_FAIL,
  UPDATE_ORGANIZATION_ABOUT,
  UPDATE_ORGANIZATION_ABOUT_SUCCESS,
  UPDATE_ORGANIZATION_ABOUT_FAIL,
  UPDATE_ORGANIZATION_HEAD_OFFICE,
  UPDATE_ORGANIZATION_HEAD_OFFICE_SUCCESS,
  UPDATE_ORGANIZATION_HEAD_OFFICE_FAIL,
  UPDATE_ORGANIZATION_CONTACT_PERSON,
  UPDATE_ORGANIZATION_CONTACT_PERSON_SUCCESS,
  UPDATE_ORGANIZATION_CONTACT_PERSON_FAIL,
  UPDATE_ORGANIZATION_STAFF,
  UPDATE_ORGANIZATION_STAFF_SUCCESS,
  UPDATE_ORGANIZATION_STAFF_FAIL,
  UPDATE_ORGANIZATION_SECTOR_INFO,
  UPDATE_ORGANIZATION_SECTOR_INFO_SUCCESS,
  UPDATE_ORGANIZATION_SECTOR_INFO_FAIL,
  UPDATE_ORGANIZATION_CAPACITY_NEEDS,
  UPDATE_ORGANIZATION_CAPACITY_NEEDS_SUCCESS,
  UPDATE_ORGANIZATION_CAPACITY_NEEDS_FAIL,
} from "../actions/types";

const initState = {
  isSaving: false,
  organizationID: "",
  saved: false,
  isFetching: false,
  fetched: false,
  organizations: [],
  organization: {},
  error: {},
};

const organizationReducer = (state = initState, action) => {
  switch (action.type) {
    case REGISTER_ORGANIZATION:
      return {
        ...state,
        isSaving: true,
      };
    case REGISTER_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isSaving: false,
        organizationID: action.payload.organizationID,
        saved: true,
      };
    case REGISTER_ORGANIZATION_FAIL:
      return {
        ...state,
        isSaving: false,
        organizationID: "Something went wrong.Try again",
        saved: false,
      };
    case FETCH_ALL_ORGANIZATIONS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_ALL_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        organizations: action.payload.data,
      };

    case FETCH_ALL_ORGANIZATIONS_FAIL:
      return {
        ...state,
        isFetching: false,
        fetched: false,
        organizations: [],
      };

    case FETCH_AN_ORGANIZATION_BY_ID:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_AN_ORGANIZATION_BY_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        organization: action.payload.data,
      };

    case FETCH_AN_ORGANIZATION_BY_ID_FAIL:
      return {
        ...state,
        isFetching: false,
        fetched: false,
        organization: {},
        error: action.payload,
      };

    case FETCH_ORGANIZAION_PROFILE_FOR_GUEST_BY_ID:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_ORGANIZAION_PROFILE_FOR_GUEST_BY_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        organization: action.payload.data,
      };

    case FETCH_ORGANIZAION_PROFILE_FOR_GUEST_BY_ID_FAIL:
      return {
        ...state,
        isFetching: false,
        fetched: false,
        organization: {},
        error: action.payload,
      };

    case UPDATE_ORGANIZATION_ABOUT:
      return {
        ...state,
        isSaving: true,
      };
    case UPDATE_ORGANIZATION_ABOUT_SUCCESS:
      return {
        ...state,
        isSaving: false,
        saved: true,
      };
    case UPDATE_ORGANIZATION_ABOUT_FAIL:
      return {
        ...state,
        isSaving: false,
        saved: false,
      };

    case UPDATE_ORGANIZATION_HEAD_OFFICE:
      return {
        ...state,
        isSaving: true,
      };
    case UPDATE_ORGANIZATION_HEAD_OFFICE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        saved: true,
      };
    case UPDATE_ORGANIZATION_HEAD_OFFICE_FAIL:
      return {
        ...state,
        isSaving: false,
        saved: false,
      };
    case UPDATE_ORGANIZATION_CONTACT_PERSON:
      return {
        ...state,
        isSaving: true,
      };
    case UPDATE_ORGANIZATION_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
        isSaving: false,
        saved: true,
      };
    case UPDATE_ORGANIZATION_CONTACT_PERSON_FAIL:
      return {
        ...state,
        isSaving: false,
        saved: false,
      };

    case UPDATE_ORGANIZATION_STAFF:
      return {
        ...state,
        isSaving: true,
      };
    case UPDATE_ORGANIZATION_STAFF_SUCCESS:
      return {
        ...state,
        isSaving: false,
        saved: true,
      };
    case UPDATE_ORGANIZATION_STAFF_FAIL:
      return {
        ...state,
        isSaving: false,
        saved: false,
      };

    case UPDATE_ORGANIZATION_SECTOR_INFO:
      return {
        ...state,
        isSaving: true,
      };
    case UPDATE_ORGANIZATION_SECTOR_INFO_SUCCESS:
      return {
        ...state,
        isSaving: false,
        saved: true,
      };
    case UPDATE_ORGANIZATION_SECTOR_INFO_FAIL:
      return {
        ...state,
        isSaving: false,
        saved: false,
      };

    case UPDATE_ORGANIZATION_CAPACITY_NEEDS:
      return {
        ...state,
        isSaving: true,
      };
    case UPDATE_ORGANIZATION_CAPACITY_NEEDS_SUCCESS:
      return {
        ...state,
        isSaving: false,
        saved: true,
      };
    case UPDATE_ORGANIZATION_CAPACITY_NEEDS_FAIL:
      return {
        ...state,
        isSaving: false,
        saved: false,
      };

    default:
      return state;
  }
};
export default organizationReducer;
