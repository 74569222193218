import { IconButton, Typography } from '@material-ui/core';
import { ArrowBackTwoTone } from '@material-ui/icons';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { organizationsFetchDetailsForGuestById } from '../actions/user';
import OrganizationProfileForGuest from '../components/organizations/OrganizationProfileForGuest';
import PageHeader from '../components/PageHeader';

function GuestDetailsForOrganization(props) {

    const uuid = props.match.params.id;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(organizationsFetchDetailsForGuestById(uuid))
            .then(() => { })
            .catch(() => {
                console.log("AN ERROR OCCURED");
            });
    }, []);

    const org = useSelector((state) => state.organization.organization);
    const isFetching = useSelector((state) => state.organization.isFetching);

    return (
        <>
            {isFetching ? (
                <Typography variant="subtitle1">Fetching....</Typography>
            ) : null}

            <PageHeader
                title="Organization"
                subtitle="Details"
                icon={
                    <IconButton>
                        <a href="\organizations">
                            <ArrowBackTwoTone />
                        </a>
                    </IconButton>
                }
            />

            <OrganizationProfileForGuest profile={org}  />
        </>
    )
}

export default GuestDetailsForOrganization
