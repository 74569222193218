import { AccountCircleOutlined, DeleteForever } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import PageHeader from "../components/PageHeader";
import Controls from "../components/controls/Controls";
import { useDispatch, useSelector } from "react-redux";
import { actionDeleteUser, fetchAllUsers } from "../actions/user";
import { useTable } from "../components/useTable";
import { TableBody, TableCell, TableRow, makeStyles } from "@material-ui/core";
import Notification from "../components/controls/Notification";
import ConfirmDialog from "../components/controls/ConfirmDialog";

const useStyles = makeStyles((theme) => ({
  deleteIconContainer: {
    marginLeft: theme.spacing(4),
  },
  deleteIcon: {
    fontSize: "12px",
  },
}));

const headCells = [
  { id: "firstName", label: "First name" },
  { id: "secondName", label: "Second name" },
  { id: "email", label: "Email" },
  { id: "contact", label: "Contact" },
  { id: "roles", label: "Roles" },
  { id: "actions", label: "Actions" },
];

function Users(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  useEffect(() => {
    fetchUsers()
  }, []);

  const fetchUsers = async () => {
    await dispatch(fetchAllUsers())
    .then(() => {})
    .catch(() => {
      console.log("AN ERROR OCCURED");
    });
  }

  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const users = useSelector((state) => state.user.users);
  const isFetching = useSelector((state) => state.user.isFetching);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const onDelete = async (id) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });

    await dispatch(actionDeleteUser(id))
      .then(() => {
        setNotify({ isOpen: true, message: "Deleted successfully", type: "error" });
        fetchUsers()
      })
      .catch(() => {
        setNotify({ isOpen: true, message: "An error occured while deleting. Try again.", type: "error" });
        console.log("AN ERROR OCCURED");
      });
  };

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingaAndSorting,
  } = useTable(users.data, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter(
            (x) =>
              x.firstName.toLowerCase().includes(target.value.toLowerCase()) ||
              x.secondName.toLowerCase().includes(target.value.toLowerCase()) ||
              x.email.toLowerCase().includes(target.value.toLowerCase())
          );
      },
    });
  };

  return (
    <>
      <PageHeader
        icon={<AccountCircleOutlined />}
        title="Users registered"
        subtitle="Add, Edit, Remove a user"
      />
      <Controls.ManageToolbar
        searchLabel="Search user"
        handleSearch={handleSearch}
        registerUrl="\register-user"
        registerButtonLabel="New user"
      />
      <TblPagination />
      <TblContainer>
        <TblHead />
        <TableBody>
          {recordsAfterPagingaAndSorting().map((usr) => (
            <TableRow key={usr.Id}>
              <TableCell>{usr.FirstName}</TableCell>
              <TableCell>{usr.LastName}</TableCell>
              <TableCell>{usr.Email}</TableCell>
              <TableCell>{usr.Contact}</TableCell>
              <TableCell>
                {usr.Roles && typeof usr.Roles === "object" ? (
                  <>
                    {Object.entries(usr.Roles).map(([key, val]) => (
                      <li key={key}>{val}</li>
                    ))}
                  </>
                ) : null}
              </TableCell>
              <TableCell>
                <a href={`/manage-user/` + usr.Email + `/details`}>
                  All Details
                </a>
                &nbsp;
                <span className={classes.deleteIconContainer}>
                  <Controls.ActionButton
                    variant="text"
                    color="secondary"
                    onClick={() => {
                      // onDelete()
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure you want to delete this user?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDelete(usr.Id);
                        },
                      });
                    }}
                  >
                    <DeleteForever
                      fontSize="inherit"
                      className={classes.deleteIcon}
                    />
                  </Controls.ActionButton>
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TblContainer>
      <TblPagination />
      {isFetching ? "Loading..." : null}
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export default Users;
