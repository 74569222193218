import { IconButton, Typography } from "@material-ui/core";
import { ArrowBackTwoTone } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userFetchDetailsByEmail } from "../actions/user";
import PageHeader from "../components/PageHeader";
import UserProfile from "../components/UserProfile";


function UserDetails(props) {
    const uuid = props.match.params.email;
    const dispatch = useDispatch();
    const history = props.history;

    useEffect(() => {
        dispatch(userFetchDetailsByEmail(uuid))
            .then(() => { })
            .catch(() => {
                console.log("AN ERROR OCCURED");
            });
    }, []);

    
    const reloadPage = () => {
        dispatch(userFetchDetailsByEmail(uuid))
            .then(() => { })
            .catch(() => {
                console.log("AN ERROR OCCURED");
            });
    }

    const user = useSelector((state) => state.user.user);
    const isFetching = useSelector((state) => state.user.isFetching);

    return (
        <>
            {isFetching ? (
                <Typography variant="subtitle1">Fetching....</Typography>
            ) : null}
            <PageHeader
                title="Authenticated user"
                subtitle="Manage user details"
                icon={
                    <IconButton>
                        <a href="\manage-users">
                            <ArrowBackTwoTone />
                        </a>
                    </IconButton>
                }
            />
            <UserProfile profile={user} routeHistory = {history} refresh={ ()=>{reloadPage() }} />
        </>
    )
}

export default UserDetails
