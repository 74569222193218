import { Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import UpdateFormHOC from "../HOC/UpdateFormHOC";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { fetchAllRoles, updateUser } from "../../actions/user";
import { TextField } from "formik-material-ui";
import Controls from "../controls/Controls";

const useStyles = makeStyles((theme) => ({
  tForm: {
    "& .MuiFormControl-root": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  error: {},
}));

function UpdateUserForm(props) {
  const { data, closePopup, refreshPage } = props;
  const classes = useStyles();

  const roles = useSelector((state) => state.user.roles);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllRoles())
      .then(() => {})
      .catch(() => {
        console.log("Error occured");
      });
  });

  return (
    <UpdateFormHOC>
      <Formik
        initialValues={{
          id: data.Id,
          firstName: data.FirstName,
          secondName: data.LastName,
          contact: data.Contact,
          email: data.Email,
          userRoles: data.roles ? data.roles.split(",") : [],
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().required("Required"),
          secondName: Yup.string().required("Required"),
          contact: Yup.string().required("Required"),
          email: Yup.string().email("Invalid email").required("Required"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          await dispatch(updateUser(data.Id, values))
            .then(() => {
              props.routeHistory.push(
                `/manage-user/` + data.Email + `/details`
              );
              setSubmitting(false);
              refreshPage();
              closePopup();
              // window.location.reload();
            })
            .catch((e) => {
              setSubmitting(false);
              console.log("an error for occured ");
              console.log(e);
            });
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form autoComplete="off" className={classes.tForm}>
            <Field
              fullWidth
              size="small"
              name="firstName"
              component={TextField}
              variant="outlined"
              label="FirstName"
            />
            <Field
              fullWidth
              size="small"
              name="secondName"
              component={TextField}
              variant="outlined"
              label="Second Name"
            />
            <Field
              fullWidth
              size="small"
              name="contact"
              component={TextField}
              variant="outlined"
              label="Contact"
            />
            <Field
              fullWidth
              size="small"
              name="email"
              component={TextField}
              variant="outlined"
              label="Email"
              disabled={true}
            />

            <Field fullWidth name="roles">
              {({ meta, form: { setFieldValue, values } }) => (
                <FormControl variant="outlined" fullWidth>
                  <Typography variant="body2">Roles</Typography>
                  {roles &&
                    roles.data &&
                    Object.entries(roles.data).map(([key, val]) => (
                      <React.Fragment key={key}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.userRoles.includes(val["Name"])}
                              color="primary"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                              onChange={() => {
                                const checked = values.userRoles.includes(val["Name"]);

                                checked === false
                                  ? setFieldValue("userRoles", [
                                      ...values.userRoles,
                                      val["Name"],
                                    ])
                                  : setFieldValue(
                                      "userRoles",
                                      values.userRoles.filter(
                                        (sc) => sc !== val["Name"]
                                      )
                                    );
                              }}
                            />
                          }
                          label={val["Name"]}
                          labelPlacement="end"
                        />
                      </React.Fragment>
                    ))}

                  {meta.touched && meta.error && (
                    <span className={classes.error}>{meta.error}</span>
                  )}
                </FormControl>
              )}
            </Field>

            <Controls.SubmitButton
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
          </Form>
        )}
      </Formik>
    </UpdateFormHOC>
  );
}

export default UpdateUserForm;
