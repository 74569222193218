import {
  START_USERS_FETCH,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_FAIL,
  FINISH_USERS_FETCH,
  FETCH_ROLES,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAIL,
  FETCH_A_USER_BY_EMAIL,
  FETCH_A_USER_BY_EMAIL_SUCCESS,
  FETCH_A_USER_BY_EMAIL_FAIL,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
} from "../actions/types";

const initState = {
  isSaving: false,
  saved: false,
  isFetching: false,
  fetched: false,
  users: [],
  roles: {},
  user: {},
  userEmail: "",
  isDeleting: false,
  isDeleted: false,
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case START_USERS_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.data,
        isFetching: false,
      };
    case FINISH_USERS_FETCH:
      return {
        ...state,
        isFetching: false,
      };
    case FETCH_ALL_USERS_FAIL:
      return {
        ...state,
        isFetching: false,
        users: [],
      };
    case FETCH_ROLES:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        roles: action.payload.data,
      };
    case FETCH_ROLES_FAIL:
      return {
        ...state,
        isFetching: false,
        roles: {},
      };

    case FETCH_A_USER_BY_EMAIL:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_A_USER_BY_EMAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload.data,
      };
    case FETCH_A_USER_BY_EMAIL_FAIL:
      return {
        ...state,
        isFetching: false,
        user: {},
      };

    case UPDATE_USER:
      return {
        ...state,
        isSaving: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isSaving: false,
        userEmail: action.payload.userEmail,
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        isSaving: false,
        userEmail: "",
      };

    case DELETE_USER:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        isDeleted: true,
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
      };

    default:
      return state;
  }
};

export default userReducer;
