import { Grid, makeStyles } from '@material-ui/core'
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react'
import * as Yup from "yup";
import Controls from '../controls/Controls';
import UpdateFormHOC from '../HOC/UpdateFormHOC';
import { useDispatch } from 'react-redux'
import { actionUpdateOrgStaff } from '../../actions/organization';

const useStyles = makeStyles((theme) => ({
    tForm: {
        "& .MuiFormControl-root": {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
}));


function StaffUpdateForm(props) {
    const { data, history, reloadPage, closePopup } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <UpdateFormHOC>
            <Formik
                initialValues={{
                    numStaffMale: data.NumStaffMale,
                    numStaffFemale: data.NumStaffFemale,
                    phdMales: data.PhdMales,
                    phdFemales: data.PhdFemales,
                    mastrsMales: data.MastrsMales,
                    mastrsFemales: data.MastrsFemales,
                    bscMale: data.BscMale,
                    bscFemale: data.BscFemale,
                    dipMale: data.DipMale,
                    dipFemale: data.DipFemale,
                    certMale: data.CertMale,
                    certFemale: data.CertFemale,
                }}

                validationSchema={Yup.object({
                    numStaffMale: Yup.number()
                        .required("Required")
                        .min(0, "Number should be greater or equal to zero"),
                    numStaffFemale: Yup.number()
                        .required("Required")
                        .min(0, "Number should be greater or equal to zero"),
                    phdMales: Yup.number()
                        .required("Required")
                        .min(0, "Number should be greater or equal to zero")
                        .test({
                            exclusive: false,
                            message: "Total Male staff exceeded",
                            test: function (value) {
                                let phd = value;
                                let mstr = this.parent.mastrsMales;
                                let bsc = this.parent.bscMale;
                                let dip = this.parent.dipMale;
                                let cert = this.parent.certMale;
                                phd = isNaN(phd) ? 0 : parseInt(phd);
                                mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                                bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                                dip = isNaN(dip) ? 0 : parseInt(dip);
                                cert = isNaN(cert) ? 0 : parseInt(cert);
                                if (
                                    phd + mstr + bsc + dip + cert <=
                                    parseInt(this.parent.numStaffMale)
                                )
                                    return true;
                                else return false;
                            }
                        }),
                    phdFemales: Yup.number()
                        .required("Required")
                        .min(0, "Number should be greater or equal to zero")
                        .test({
                            exclusive: false,
                            message: "Total Female staff exceeded",
                            test: function (value) {
                                let phd = value;
                                let mstr = this.parent.mastrsFemales;
                                let bsc = this.parent.bscFemale;
                                let dip = this.parent.dipFemale;
                                let cert = this.parent.certFemale;
                                phd = isNaN(phd) ? 0 : parseInt(phd);
                                mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                                bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                                dip = isNaN(dip) ? 0 : parseInt(dip);
                                cert = isNaN(cert) ? 0 : parseInt(cert);
                                if (
                                    phd + mstr + bsc + dip + cert <=
                                    parseInt(this.parent.numStaffFemale)
                                )
                                    return true;
                                else return false;
                            }
                        }),
                    mastrsMales: Yup.number()
                        .required("Required")
                        .min(0, "Number should be greater or equal to zero")
                        .test({
                            exclusive: false,
                            message: "Total Male staff exceeded",
                            test: function (value) {
                                let phd = this.parent.phdMales;
                                let mstr = value;
                                let bsc = this.parent.bscMale;
                                let dip = this.parent.dipMale;
                                let cert = this.parent.certMale;
                                phd = isNaN(phd) ? 0 : parseInt(phd);
                                mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                                bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                                dip = isNaN(dip) ? 0 : parseInt(dip);
                                cert = isNaN(cert) ? 0 : parseInt(cert);
                                if (
                                    phd + mstr + bsc + dip + cert <=
                                    parseInt(this.parent.numStaffMale)
                                )
                                    return true;
                                else return false;
                            }
                        }),
                    mastrsFemales: Yup.number()
                        .required("Required")
                        .min(0, "Number should be greater or equal to zero")
                        .test({
                            exclusive: false,
                            message: "Total Female staff exceeded",
                            test: function (value) {
                                let phd = this.parent.phdFemales;
                                let mstr = value;
                                let bsc = this.parent.bscFemale;
                                let dip = this.parent.dipFemale;
                                let cert = this.parent.certFemale;
                                phd = isNaN(phd) ? 0 : parseInt(phd);
                                mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                                bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                                dip = isNaN(dip) ? 0 : parseInt(dip);
                                cert = isNaN(cert) ? 0 : parseInt(cert);
                                if (
                                    phd + mstr + bsc + dip + cert <=
                                    parseInt(this.parent.numStaffFemale)
                                )
                                    return true;
                                else return false;
                            }
                        }),
                    bscMale: Yup.number()
                        .required("Required")
                        .min(0, "Number should be greater or equal to zero")
                        .test({
                            exclusive: false,
                            message: "Total Male staff exceeded",
                            test: function (value) {
                                let phd = this.parent.phdMales;
                                let mstr = this.parent.mastrsMales;
                                let bsc = value;
                                let dip = this.parent.dipMale;
                                let cert = this.parent.certMale;
                                phd = isNaN(phd) ? 0 : parseInt(phd);
                                mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                                bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                                dip = isNaN(dip) ? 0 : parseInt(dip);
                                cert = isNaN(cert) ? 0 : parseInt(cert);
                                if (
                                    phd + mstr + bsc + dip + cert <=
                                    parseInt(this.parent.numStaffMale)
                                )
                                    return true;
                                else return false;
                            }
                        }),
                    bscFemale: Yup.number()
                        .required("Required")
                        .min(0, "Number should be greater or equal to zero")
                        .test({
                            exclusive: false,
                            message: "Total Female staff exceeded",
                            test: function (value) {
                                let phd = this.parent.phdFemales;
                                let mstr = this.parent.mastrsFemales;
                                let bsc = value;
                                let dip = this.parent.dipFemale;
                                let cert = this.parent.certFemale;
                                phd = isNaN(phd) ? 0 : parseInt(phd);
                                mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                                bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                                dip = isNaN(dip) ? 0 : parseInt(dip);
                                cert = isNaN(cert) ? 0 : parseInt(cert);
                                if (
                                    phd + mstr + bsc + dip + cert <=
                                    parseInt(this.parent.numStaffFemale)
                                )
                                    return true;
                                else return false;
                            }
                        }),
                    dipMale: Yup.number()
                        .required("Required")
                        .min(0, "Number should be greater or equal to zero")
                        .test({
                            exclusive: false,
                            message: "Total Male staff exceeded",
                            test: function (value) {
                                let phd = this.parent.phdMales;
                                let mstr = this.parent.mastrsMales;
                                let bsc = this.parent.bscMale;
                                let dip = value;
                                let cert = this.parent.certMale;
                                phd = isNaN(phd) ? 0 : parseInt(phd);
                                mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                                bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                                dip = isNaN(dip) ? 0 : parseInt(dip);
                                cert = isNaN(cert) ? 0 : parseInt(cert);
                                if (
                                    phd + mstr + bsc + dip + cert <=
                                    parseInt(this.parent.numStaffMale)
                                )
                                    return true;
                                else return false;
                            }
                        }),
                    dipFemale: Yup.number()
                        .required("Required")
                        .min(0, "Number should be greater or equal to zero")
                        .test({
                            exclusive: false,
                            message: "Total Female staff exceeded",
                            test: function (value) {
                                let phd = this.parent.phdFemales;
                                let mstr = this.parent.mastrsFemales;
                                let bsc = this.parent.bscFemale;
                                let dip = value;
                                let cert = this.parent.certFemale;
                                phd = isNaN(phd) ? 0 : parseInt(phd);
                                mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                                bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                                dip = isNaN(dip) ? 0 : parseInt(dip);
                                cert = isNaN(cert) ? 0 : parseInt(cert);
                                if (
                                    phd + mstr + bsc + dip + cert <=
                                    parseInt(this.parent.numStaffFemale)
                                )
                                    return true;
                                else return false;
                            }
                        }),
                    certMale: Yup.number()
                        .required("Required")
                        .min(0, "Number should be greater or equal to zero")
                        .test({
                            exclusive: false,
                            message: "Total Male staff exceeded",
                            test: function (value) {
                                let phd = this.parent.phdMales;
                                let mstr = this.parent.mastrsMales;
                                let bsc = this.parent.bscMale;
                                let dip = this.parent.dipMale;
                                let cert = value;
                                phd = isNaN(phd) ? 0 : parseInt(phd);
                                mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                                bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                                dip = isNaN(dip) ? 0 : parseInt(dip);
                                cert = isNaN(cert) ? 0 : parseInt(cert);
                                if (
                                    phd + mstr + bsc + dip + cert <=
                                    parseInt(this.parent.numStaffMale)
                                )
                                    return true;
                                else return false;
                            }
                        }),
                    certFemale: Yup.number()
                        .required("Required")
                        .min(0, "Number should be greater or equal to zero")
                        .test({
                            exclusive: false,
                            message: "Total Female staff exceeded",
                            test: function (value) {
                                let phd = this.parent.phdFemales;
                                let mstr = this.parent.mastrsFemales;
                                let bsc = this.parent.bscFemale;
                                let dip = this.parent.dipFemale;
                                let cert = value;
                                phd = isNaN(phd) ? 0 : parseInt(phd);
                                mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                                bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                                dip = isNaN(dip) ? 0 : parseInt(dip);
                                cert = isNaN(cert) ? 0 : parseInt(cert);
                                if (
                                    phd + mstr + bsc + dip + cert <=
                                    parseInt(this.parent.numStaffFemale)
                                )
                                    return true;
                                else return false;
                            }
                        })
                })}
                onSubmit={async (values, { setSubmitting }) => {

                    await dispatch(actionUpdateOrgStaff(data.Id, values))
                        .then(() => {
                            setSubmitting(false)
                            history.push(
                                `/manage-organization/` + data.Id + `/details`
                            );
                            reloadPage()
                            closePopup()
                        })
                        .catch(() => {
                            setSubmitting(false)
                        })
                }}
            >

                {({
                    isSubmitting,
                    isValid,
                }) => (
                    <Form autoComplete="off" className={classes.tForm}>
                        <Field
                            fullWidth
                            size="small"
                            name="numStaffMale"
                            type="number"
                            component={TextField}
                            label="Male"
                            variant="outlined"
                        />
                        <Field
                            fullWidth
                            size="small"
                            name="numStaffFemale"
                            type="number"
                            component={TextField}
                            label="Female"
                            variant="outlined"
                        />
                        <Grid container>
                            <Grid item xs={6}>
                                <Field
                                    fullWidth
                                    size="small"
                                    name="phdMales"
                                    type="number"
                                    component={TextField}
                                    label="Males at PhD level"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    fullWidth
                                    size="small"
                                    name="phdFemales"
                                    type="number"
                                    component={TextField}
                                    label="Females at PhD level"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={6}>
                                <Field
                                    fullWidth
                                    size="small"
                                    name="mastrsMales"
                                    type="number"
                                    component={TextField}
                                    label="Males at Masters level"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    fullWidth
                                    size="small"
                                    name="mastrsFemales"
                                    type="number"
                                    component={TextField}
                                    label="Females at Masters level"
                                    variant="outlined"
                                />

                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={6}>
                                <Field
                                    fullWidth
                                    size="small"
                                    name="bscMale"
                                    type="number"
                                    component={TextField}
                                    label="Males at Bsc level"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    fullWidth
                                    size="small"
                                    name="bscFemale"
                                    type="number"
                                    component={TextField}
                                    label="Females at Bsc level"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={6}>
                                <Field
                                    fullWidth
                                    name="dipMale"
                                    type="number"
                                    component={TextField}
                                    label="Males at Diploma level"
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    fullWidth
                                    name="dipFemale"
                                    type="number"
                                    component={TextField}
                                    label="Females at Diploma level"
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={6}>
                                <Field
                                    fullWidth
                                    name="certMale"
                                    type="number"
                                    component={TextField}
                                    label="Males at Certificate level"
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    fullWidth
                                    name="certFemale"
                                    type="number"
                                    component={TextField}
                                    label="Females at Certificate level"
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                        </Grid>

                        <Controls.SubmitButton
                            isSubmitting={isSubmitting}
                            isValid={isValid}
                        />
                    </Form>
                )}
            </Formik>
        </UpdateFormHOC>
    )
}

export default StaffUpdateForm;
