import { TextField } from '@material-ui/core'
import React from 'react'

function Input(props) {

    const  { name, label,value, error=null, onChange, ...other } = props;

    return (
        <TextField
            variant="outlined"
            value={value}
            name={name}
            label={label}
            onChange={onChange}
            {...other}
            {...(error && {error:true, helperText:error})}

        >
            
        </TextField>
    )
}

export default Input
