import DateFnsUtils from '@date-io/date-fns';
import { FormControl, Grid, makeStyles, TextField as TField, } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import React from 'react'
import * as Yup from "yup";
import { countries } from '../../data/countries';
import Controls from '../controls/Controls';
import { useDispatch } from 'react-redux'
import { actionUpdateOrgAbout } from '../../actions/organization';
import Autocomplete from '@material-ui/lab/Autocomplete';
const useStyles = makeStyles((theme) => ({
    tForm: {
        '& .MuiFormControl-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        }
    },
    error: {
        color: 'red'
    }
}))

function AboutOrganizationFormUpdate(props) {
    const { data, history, reloadPage, closePopup } = props;
    const classes = useStyles()
    const dispatch = useDispatch()

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
                <Grid md={2} xs={12} item />
                <Grid md={8} xs={12} item>
                    <>
                        <Formik
                            initialValues={{
                                name: data.Name,
                                typeOfReg: data.TypeOfReg,
                                dateOfIncorp: data.DateOfIncorp,
                                regNoEntity: data.RegNoEntity,
                                email: data.Email,
                                country: data.Country,
                                telephone: data.Telephone,
                                website: data.Website,
                                postalAddress: data.PostalAddress,
                            }}

                            validationSchema={Yup.object({
                                name: Yup.string()
                                    .min(2, "Too Short!")
                                    .required("Required"),
                                typeOfReg: Yup.string()
                                    .nullable()
                                    .min(2, "Too Short!")
                                    .required("Required"),
                                dateOfIncorp: Yup.date()
                                    .max(
                                        new Date(),
                                        "Date of incorporation must be a past date"
                                    )
                                    .required("Required"),
                                regNoEntity: Yup.string()
                                    .required("Required")
                                    .min(2, "Too short!"),
                                telephone: Yup.string().required("Required"),
                                email: Yup.string()
                                    .email("Invalid email")
                                    .required("Required"),
                                website: Yup.string(),
                                postalAddress: Yup.string(),
                                country: Yup.string().required("Required")
                            })}
                            onSubmit={async (values, { setSubmitting }) => {
                                await dispatch(actionUpdateOrgAbout(data.Id, values))
                                    .then(() => {
                                        setSubmitting(false)
                                        history.push(
                                            `/manage-organization/` + data.Id + `/details`
                                        );
                                        reloadPage()
                                        closePopup()
                                    })
                                    .catch((e) => {
                                        setSubmitting(false)
                                    })
                            }}
                        >
                            {({
                                isSubmitting,
                                isValid,
                            }) => (
                                <Form autoComplete="off" autoCapitalize="true" className={classes.tForm}>
                                    <Field
                                        fullWidth
                                        size="small"
                                        name="name"
                                        component={TextField}
                                        label="Name of organization"
                                        variant="outlined"
                                        disabled={true}
                                    />
                                    <Field fullWidth name="typeOfReg">
                                        {({
                                            meta,
                                            form: { setFieldValue, values }
                                        }) => (
                                            <Controls.FormikSelect
                                                disabled={true}
                                                name="typeOfReg"
                                                value={values.typeOfReg}
                                                options={["Company", "NGO", "CBO"]}
                                                label="Type of Registration"
                                                placeholder="Type of Registration"
                                                setFieldValue={setFieldValue}
                                                meta={meta}
                                            />
                                        )}
                                    </Field>

                                    <Field
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        component={KeyboardDatePicker}
                                        label="Date of incorporation/registration"
                                        name="dateOfIncorp"
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        InputAdornmentProps={{ position: "start" }}
                                        disabled={true}
                                    />
                                    <Field
                                        fullWidth
                                        size="small"
                                        name="regNoEntity"
                                        component={TextField}
                                        variant="outlined"
                                        label="Registration No. of Entity"
                                        disabled={true}
                                    />
                                    <Field
                                        fullWidth
                                        size="small"
                                        name="email"
                                        component={TextField}
                                        variant="outlined"
                                        label="E-mail Address"
                                        required={true}
                                    />

                                    <Field fullWidth name="country">
                                        {({
                                            meta,
                                            form: { setFieldValue, values }
                                        }) => (
                                            <FormControl variant="outlined" fullWidth>
                                                <Autocomplete
                                                    disabled={true}
                                                    size="small"
                                                    options={countries.map(option => option.name)}
                                                    getOptionLabel={option => option}
                                                    value={values.country}
                                                    filterSelectedOptions
                                                    onChange={(e: object, val: any) => {
                                                        setFieldValue("country", val);
                                                    }}
                                                    renderInput={params => (
                                                        <TField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Country"
                                                            placeholder="Country"
                                                        />
                                                    )}
                                                />
                                                {meta.error && (
                                                    <span className={classes.error}>{meta.error}</span>
                                                )}
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field
                                        fullWidth
                                        size="small"
                                        name="telephone"
                                        component={TextField}
                                        variant="outlined"
                                        label="Telephone"
                                        required={true}
                                    />
                                    <Field
                                        fullWidth
                                        size="small"
                                        name="website"
                                        component={TextField}
                                        variant="outlined"
                                        label="Website (if any)"
                                    />
                                    <Field
                                        fullWidth
                                        size="small"
                                        name="postalAddress"
                                        component={TextField}
                                        variant="outlined"
                                        label="Postal Address"
                                    />
                                    <Controls.SubmitButton
                                        isSubmitting={isSubmitting}
                                        isValid={isValid}
                                    />
                                </Form>
                            )}
                        </Formik>
                    </>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    )
}

export default AboutOrganizationFormUpdate
