import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui';
import React from 'react'
import * as Yup from "yup";
import Controls from '../controls/Controls';
import { useDispatch } from 'react-redux'
import { actionUpdateWorkerContactInfo } from '../../actions/worker';

const useStyles = makeStyles((theme) => ({
    tForm: {
        '& .MuiFormControl-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        }
    }
}))
function ContactInformationUpdateForm(props) {

    const { data, history, reloadPage, closePopup } = props;
    const classes = useStyles()
    const dispatch = useDispatch()

    return (
        <>
            <Grid container>
                <Grid item md={2} sm={12} />
                <Grid item md={8} sm={12}>
                    <Formik
                        initialValues={{
                            telephone1: data.Telephone1,
                            telephone2: data.Telephone2,
                            email: data.Email,
                            districtOfRegistration: data.DistrictOfRegistration,
                        }}

                        validationSchema={Yup.object({
                            telephone1: Yup.mixed().required("Required"),
                            telephone2: Yup.mixed(),
                            email: Yup.string()
                                .email("Invalid email")
                                .required("Required"),
                            districtOfRegistration: Yup.string().required("Required")
                        })}
                        onSubmit={async (values, { setSubmitting }) => {
                            await dispatch(actionUpdateWorkerContactInfo(data.Id, values))
                                    .then(() => {
                                        history.push(
                                            `/manage-worker/` + data.Id + `/details`
                                        );
                                        setSubmitting(false);
                                        reloadPage();
                                        closePopup();
                                    })
                                    .catch((e)=>{
                                        setSubmitting(false);
                                        console.log("an error for occured ");
                                        console.log(e);
                                    })
                        }}

                    >
                        {({ isSubmitting, isValid }) =>
                        (
                            <Form autoComplete="off" className={classes.tForm}>
                                <Field
                                    fullWidth
                                    size="small"
                                    name="telephone1"
                                    component={TextField}
                                    label="Telephone 1"
                                    variant="outlined"
                                />
                                <Field
                                    fullWidth
                                    size="small"
                                    name="telephone2"
                                    component={TextField}
                                    label="Telephone 2"
                                    variant="outlined"
                                />
                                <Field
                                    fullWidth
                                    size="small"
                                    name="email"
                                    component={TextField}
                                    label="Email"
                                    variant="outlined"
                                />
                                <Field
                                    fullWidth
                                    size="small"
                                    name="districtOfRegistration"
                                    component={TextField}
                                    label="District of registration"
                                    variant="outlined"
                                    disabled={true}
                                />
                                <Controls.Button
                                    startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                                    disabled={!isValid || isSubmitting}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    {isSubmitting
                                        ? "Submitting..."
                                        : "Submit"
                                    }
                                </Controls.Button>
                            </Form>
                        )}

                    </Formik>

                </Grid>
            </Grid>
        </>
    )
}

export default ContactInformationUpdateForm