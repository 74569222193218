import { Card, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useClasses = makeStyles((theme) => ({
  header: {
    display: "flex",
    textAlign: "left",
    margin: theme.spacing(2)
  },
  pageIcon:{
      display: 'inline-block',
      padding: theme.spacing(2)
  },
  pageSubtitle:{
      opacity: 0.6
  }
}));

function PageHeader(props) {
  const { title, subtitle, icon } = props;
  const classes = useClasses();

  return (
    <>
      <Grid container>
        <Grid item sm={12} className={classes.header}>
          <Card className={classes.pageIcon} elevation={0}>{icon}</Card>
          <div>
            <Typography variant="h5" component="div">
              {title}
            </Typography>
            <Typography variant="subtitle1" component="div" className={classes.pageSubtitle}>
              {subtitle}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default PageHeader;
