import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  supportSkillOptions,
  qualificationOptions,
  skillsDurationOptions,
  workStatusOptions,
  sectorOptions,
  levOfOpnInPubSecOptions,
  agricFocusValChainFocLevOptions,
  agricFocusEnterpOptions,
  keyBenefFarmersCatOptions,
  keyBenefFarmersGenderOptions,
  capacityNeedsOptions,
  agricRelQualOptions,
  areaOpnTrgtDistrictsOptions,
  keyBenefOptions
} from "../utils/options";
import { centralDistrictsOptions, districts, easternDistrictsOptions, northernDistrictsOptions, westernDistrictsOptions } from "../data/districts"
import moment from "moment";
import { workerRegisteration } from "../actions/user";
import {
  Button,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField as TField,
  Chip,
  IconButton
} from "@material-ui/core";
import { Field, Form, Formik, FormikConfig, FormikValues } from "formik";
import { TextField, RadioGroup } from "formik-material-ui";
import { KeyboardDatePicker } from "formik-material-ui-pickers";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import * as Yup from "yup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { countries } from "../data/countries";
import PageHeader from "../components/PageHeader";
import { ArrowBackTwoTone } from "@material-ui/icons";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  error: {
    color: "red",
    marginLeft: "14px",
    marginRight: "14px"
  }
});

export default function RegisterWorker() {
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => { }, []);

  return (
    <>
      <PageHeader
        icon={<IconButton> <a href="\manage-workers"> <ArrowBackTwoTone /> </a> </IconButton>}
        title="Register extension worker"
        subtitle="Add a new worker"
      />

    <Grid container>
      {/* <Grid item md={1}></Grid> */}
      <Grid md={12} xs={12} item>
        <>
          <div>
            {/* <Grid md={12} item>
              <Typography variant="h5" align="center">
                Register extension worker
              </Typography>
            </Grid> */}
            <Grid container>
              <Grid md={2} xs={12} item></Grid>
              <Grid md={8} xs={12} item>
                <FormikStepper
                  initialValues={{
                    category: "",
                    nin: "",
                    passportNo: "",
                    workPermitNo: "",
                    workPermitExpiryDate: null,
                    firstName: "",
                    lastName: "",
                    dob: null,
                    sex: "",
                    country: "",
                    telephone1: "",
                    telephone2: "",
                    email: "",
                    districtOfRegistration: "",
                    agricRelQual: "",
                    highestQual: "",
                    otherHighestQual: "",
                    yearOfQual: "",
                    otherMainSuppSkillsTrain: "",
                    supportSkillA: "",
                    qualSupportSkillA: "",
                    durationSkillA: "",
                    supportSkillB: "",
                    qualSupportSkillB: "",
                    durationSkillB: "",
                    supportSkillC: "",
                    qualSupportSkillC: "",
                    durationSkillC: "",
                    workStatus: "",
                    wrkplaceOrg: "",
                    position: "",
                    sectorOfOrganization: "",
                    levOfOpnInPubSec:"",
                    agricFocus: "",
                    agricFocusValChainFocLev: [],
                    agricFocusEnterp: [],
                    areaOfOpsTargetDistricts: [],
                    centralDistricts: [],
                    eastDistricts: [],
                    westDistricts: [],
                    northDistricts: [],
                    keyBenef: [],
                    keyBenefFarmersCat: [],
                    keyBenefFarmersGender: [],
                    capacityNeedFirstChoice: "",
                    capacityNeedSecondChoice: "",
                    capacityNeedThirdChoice: ""
                  }}
                  onSubmit={async values => {
                    await dispatch(workerRegisteration(values))
                      .then(() => { })
                      .catch(() => {
                        console.log("AN ERROR OCCURED");
                      });
                  }}
                >
                  <FormikStep
                    label="Category"
                    validationSchema={Yup.object({
                      category: Yup.string()
                        .required("Required"),
                    })}
                  >
                    <Box>
                      <Field
                        component={RadioGroup}
                        name="category"
                        variant="inline"
                      >
                        <FormControlLabel
                          value="Individual (Professional/Paraprofessional)"
                          control={<Radio value="Individual (Professional/Paraprofessional)" />}
                          label="Individual (Professional/Paraprofessional)"
                        />
                        <FormControlLabel
                          value="Individual (Trained Practitioner/Trainer)"
                          control={<Radio value="Individual (Trained Practitioner/Trainer)" />}
                          label="Individual (Trained Practitioner/Trainer)"
                        />
                        <FormControlLabel
                          value="Practitioner"
                          control={<Radio value="Practitioner" />}
                          label="Private Practitioner"
                        />
                        <FormControlLabel
                          value="Foreigner"
                          control={<Radio value="Foreigner" />}
                          label="Foreigner"
                        />

                        <FormControlLabel
                          value="MAAIF"
                          control={<Radio value="MAAIF" />}
                          label="Ministry of Agriculture, Animal industry and Fisheries (MAAIF)"
                        />
                      </Field>
                    </Box>
                  </FormikStep>
                  <FormikStep
                    label="Personal Details"
                    validationSchema={Yup.object({
                      firstName: Yup.string()
                        .min(2, "Too Short!")
                        .max(50, "Too Long!")
                        .required("Required"),
                      lastName: Yup.string()
                        .min(2, "Too Short!")
                        .max(50, "Too Long!")
                        .required("Required"),
                      dob: Yup.date().nullable()
                        .max(new Date(), "Date of birth must be a past date")
                        .required("Date of Birth is required"),
                      sex: Yup.string()
                        .nullable()
                        .required("Select Gender")
                        .min(2, "Select Gender"),
                      country: Yup.string()
                    })}
                  >
                    <Box paddingBottom={2}>
                      <Field fullWidth>
                        {({
                          form: { values }
                        }) => (
                          <>
                            {values.category === "Individual (Professional/Paraprofessional)" ||
                              values.category === "Individual (Trained Practitioner/Trainer)" ||
                              values.category === "MAAIF" ||
                              values.category === "Practitioner" ?
                              <Field
                                fullWidth
                                size="small"
                                name="nin"
                                component={TextField}
                                variant="outlined"
                                label="National Identification Number"
                              /> : null
                            }

                            {values.category === "Foreigner" ?
                              <>
                                <Box paddingBottom={2}>
                                  <Field fullWidth name="country">
                                    {({
                                      meta,
                                      form: { setFieldValue, values }
                                    }) => (
                                      <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                          size="small"
                                          options={countries.map(option => option.name)}
                                          getOptionLabel={option => option}
                                          value={values.country}
                                          filterSelectedOptions
                                          onChange={(e: object, val: any) => {
                                            setFieldValue("country", val);
                                          }}
                                          renderInput={params => (
                                            <TField
                                              {...params}
                                              variant="outlined"
                                              label="Country"
                                              placeholder="Country"
                                            />
                                          )}
                                        />
                                        {meta.touched && meta.error && (
                                          <span className="error">{meta.error}</span>
                                        )}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                                <Box paddingBottom={2}>
                                  <Field
                                    fullWidth
                                    size="small"
                                    name="passportNo"
                                    component={TextField}
                                    variant="outlined"
                                    label="Passport Number"
                                  />
                                </Box>

                                <Box paddingBottom={2}>
                                  <Field
                                    fullWidth
                                    size="small"
                                    name="workPermitNo"
                                    component={TextField}
                                    variant="outlined"
                                    label="Work Permit Number"
                                  />
                                </Box>

                                <Box paddingBottom={2}>
                                  <Field
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    component={KeyboardDatePicker}
                                    label="Expiry Date of Work Permit"
                                    name="workPermitExpiryDate"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    InputAdornmentProps={{ position: "start" }}
                                  />
                                </Box>
                              </> : null
                            }
                          </>
                        )}
                      </Field>

                    </Box>
                    <Box paddingBottom={2}>
                      <Field
                        fullWidth
                        size="small"
                        name="firstName"
                        component={TextField}
                        label="First Name"
                        variant="outlined"
                      />
                    </Box>
                    <Box paddingBottom={2}>
                      <Field
                        fullWidth
                        size="small"
                        name="lastName"
                        component={TextField}
                        label="Last Name"
                        variant="outlined"
                      />
                    </Box>
                    <Box paddingBottom={2}>
                      <Field
                        variant="outlined"
                        fullWidth
                        size="small"
                        component={KeyboardDatePicker}
                        label="Date of Birth"
                        name="dob"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        InputAdornmentProps={{ position: "start" }}
                      />
                    </Box>
                    <Box paddingBottom={2}>
                      <Field fullWidth name="sex">
                        {({
                          meta,
                          form: { setFieldValue, values }
                        }) => (
                          <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                              size="small"
                              options={["Male", "Female"]}
                              getOptionLabel={option => option}
                              value={values.sex}
                              filterSelectedOptions
                              onChange={(e: object, val: any) => {
                                setFieldValue("sex", val);
                              }}
                              renderInput={params => (
                                <TField
                                  {...params}
                                  variant="outlined"
                                  label="Gender"
                                  placeholder="Gender"
                                />
                              )}
                            />
                            {meta.touched && meta.error && (
                              <span>
                                <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                              </span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Box>

                  </FormikStep>

                  <FormikStep
                    label="Contact Info"
                    validationSchema={Yup.object({
                      telephone1: Yup.mixed().required("Required"),
                      telephone2: Yup.mixed(),
                      email: Yup.string()
                        .email("Invalid email")
                        .required("Required"),
                      districtOfRegistration: Yup.string().required("Required")
                    })}
                  >
                    <Box paddingBottom={2}>
                      <Field
                        fullWidth
                        size="small"
                        name="telephone1"
                        component={TextField}
                        label="Telephone 1"
                        variant="outlined"
                      />
                    </Box>
                    <Box paddingBottom={2}>
                      <Field
                        fullWidth
                        size="small"
                        name="telephone2"
                        component={TextField}
                        label="Telephone 2"
                        variant="outlined"
                      />
                    </Box>
                    <Box paddingBottom={2}>
                      <Field
                        fullWidth
                        size="small"
                        type="email"
                        name="email"
                        component={TextField}
                        label="Email Address"
                        variant="outlined"
                      />
                    </Box>
                    <Box paddingBottom={2}>
                      <Field fullWidth name="districtOfRegistration">
                        {({
                          meta,
                          form: { setFieldValue, values }
                        }) => (
                          <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                              size="small"
                              options={districts}
                              getOptionLabel={option => option}
                              value={values.districtOfRegistration}
                              filterSelectedOptions
                              onChange={(e: object, val: any) => {
                                setFieldValue("districtOfRegistration", val);
                              }}
                              renderInput={params => (
                                <TField
                                  {...params}
                                  variant="outlined"
                                  label="District of Registration"
                                  placeholder="District"
                                />
                              )}
                            />
                            {meta.touched && meta.error && (
                              <span>
                                <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                              </span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                  </FormikStep>

                  <FormikStep
                    label="Qualifications"
                    validationSchema={Yup.object({
                      agricRelQual: Yup.string().nullable().required("Required"),
                      highestQual: Yup.string().nullable().required("Required"),
                      otherHighestQual: Yup.string(),
                      yearOfQual: Yup.number("Invalid year").nullable().required("Required"),
                      otherMainSuppSkillsTrain: Yup.string().nullable().required(
                        "Required"
                      ),
                      supportSkillA: Yup.string(),
                      qualSupportSkillA: Yup.string(),
                      durationSkillA: Yup.string(),
                      supportSkillB: Yup.string(),
                      qualSupportSkillB: Yup.string(),
                      durationSkillB: Yup.string(),
                      supportSkillC: Yup.string(),
                      qualSupportSkillC: Yup.string(),
                      durationSkillC: Yup.string()
                    })}
                  >
                    <Box paddingBottom={2}>
                      <Field fullWidth name="agricRelQual">
                        {({ meta, form: { setFieldValue, values } }) => (
                          <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                              size="small"
                              freeSolo
                              options={agricRelQualOptions}
                              getOptionLabel={option => option}
                              value={values.agricRelQual}
                              filterSelectedOptions
                              onChange={(e: object, val: any) => {
                                setFieldValue("agricRelQual", val);
                              }}
                              renderInput={params => (
                                <TField
                                  {...params}
                                  variant="outlined"
                                  label="Field of agricultural-related qualification"
                                />
                              )}
                            />
                            {meta.touched && meta.error && (
                              <span>
                                <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                              </span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    <Box paddingBottom={2}>
                      <Field fullWidth name="highestQual">
                        {({ meta, form: { setFieldValue, values } }) => (
                          <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                              size="small"
                              freeSolo
                              options={qualificationOptions}
                              getOptionLabel={option => option}
                              value={values.highestQual}
                              filterSelectedOptions
                              onChange={(e: object, val: any) => {
                                setFieldValue("highestQual", val);
                              }}
                              renderInput={params => (
                                <TField
                                  {...params}
                                  variant="outlined"
                                  label="Highest Qualification"
                                />
                              )}
                            />
                            {meta.touched && meta.error && (
                              <span>
                                <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                              </span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    <Box>
                      <Field>
                        {({ form: { values } }) => (
                          <>
                            {values.highestQual === "Other(specify)" ?
                              <Box paddingBottom={2}>
                                <Field
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                  name="otherHighestQual"
                                  component={TextField}
                                  label="Other highest qualification"
                                />
                              </Box> : null

                            }
                          </>
                        )}
                      </Field>
                    </Box>
                    <Box paddingBottom={2}>
                      <Field
                        fullWidth
                        size="small"
                        variant="outlined"
                        name="yearOfQual"
                        component={TextField}
                        label="Year of Qualification"
                      />
                    </Box>
                    <Box>
                      <Field
                        component={RadioGroup}
                        name="otherMainSuppSkillsTrain"
                        variant="inline"
                      >
                        <div>Other main support skills Training?</div>
                        <FormControlLabel
                          value="No"
                          control={<Radio value="No" />}
                          label="No"
                        // disabled={isSubmitting}
                        />
                        <FormControlLabel
                          value="Yes"
                          control={<Radio value="Yes" />}
                          label="Yes"
                        // disabled={isSubmitting}
                        />
                      </Field>
                    </Box>
                    <Box>
                      <Field>
                        {({ form: { values } }) => (
                          <>
                            {values.otherMainSuppSkillsTrain === "Yes" ? (
                              <>
                                <Box paddingBottom={2}>
                                  <Field fullWidth name="supportSkillA">
                                    {({ meta, form: { setFieldValue, values } }) => (
                                      <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                          size="small"
                                          freeSolo
                                          options={supportSkillOptions}
                                          getOptionLabel={option => option}
                                          value={values.supportSkillA}
                                          filterSelectedOptions
                                          onChange={(e: object, val: any) => {
                                            setFieldValue("supportSkillA", val);
                                          }}
                                          renderInput={params => (
                                            <TField
                                              {...params}
                                              variant="outlined"
                                              label="Support Skill A"
                                            />
                                          )}
                                        />
                                        {meta.touched && meta.error && (
                                          <span>
                                            <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                                          </span>
                                        )}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                                <Box paddingBottom={2}>
                                  <Field fullWidth name="qualSupportSkillA">
                                    {({ meta, form: { setFieldValue, values } }) => (
                                      <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                          size="small"
                                          freeSolo
                                          options={qualificationOptions}
                                          getOptionLabel={option => option}
                                          value={values.qualSupportSkillA}
                                          filterSelectedOptions
                                          onChange={(e: object, val: any) => {
                                            setFieldValue("qualSupportSkillA", val);
                                          }}
                                          renderInput={params => (
                                            <TField
                                              {...params}
                                              variant="outlined"
                                              label="Qualification Support Skill A"
                                            />
                                          )}
                                        />
                                        {meta.touched && meta.error && (
                                          <span>
                                            <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                                          </span>
                                        )}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                                <Box paddingBottom={2}>
                                  <div>Duration skill A</div>

                                  <Field
                                    component={RadioGroup}
                                    name="durationSkillA"
                                    variant="inline"
                                  >
                                    {skillsDurationOptions.map((val, index) => (
                                      <FormControlLabel
                                        key={index}
                                        value={val.value}
                                        control={<Radio value={val.value} />}
                                        label={val.value}
                                      />
                                    ))}
                                  </Field>
                                </Box>
                                <Box paddingBottom={2}>
                                  <Field fullWidth name="supportSkillB">
                                    {({ meta, form: { setFieldValue, values } }) => (
                                      <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                          size="small"
                                          freeSolo
                                          options={supportSkillOptions}
                                          getOptionLabel={option => option}
                                          value={values.supportSkillB}
                                          filterSelectedOptions
                                          onChange={(e: object, val: any) => {
                                            setFieldValue("supportSkillB", val);
                                          }}
                                          renderInput={params => (
                                            <TField
                                              {...params}
                                              variant="outlined"
                                              label="Support Skill B"
                                            />
                                          )}
                                        />
                                        {meta.touched && meta.error && (
                                          <span>
                                            <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                                          </span>
                                        )}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                                <Box paddingBottom={2}>
                                  <Field fullWidth name="qualSupportSkillB">
                                    {({ meta, form: { setFieldValue, values } }) => (
                                      <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                          size="small"
                                          freeSolo
                                          options={qualificationOptions}
                                          getOptionLabel={option => option}
                                          value={values.qualSupportSkillB}
                                          filterSelectedOptions
                                          onChange={(e: object, val: any) => {
                                            setFieldValue("qualSupportSkillB", val);
                                          }}
                                          renderInput={params => (
                                            <TField
                                              {...params}
                                              variant="outlined"
                                              label="Qualification Support Skill B"
                                            />
                                          )}
                                        />
                                        {meta.touched && meta.error && (
                                          <span>
                                            <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                                          </span>
                                        )}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                                <Box paddingBottom={2}>
                                  <Field
                                    component={RadioGroup}
                                    name="durationSkillB"
                                    variant="inline"
                                  >
                                    <FormControl variant="outlined" fullWidth>
                                      <div>Duration Skill B</div>
                                      {skillsDurationOptions.map(
                                        (val, index) => (
                                          <FormControlLabel
                                            key={index}
                                            value={val.value}
                                            control={
                                              <Radio value={val.value} />
                                            }
                                            label={val.value}
                                          />
                                        )
                                      )}
                                    </FormControl>
                                  </Field>
                                </Box>
                                <Box paddingBottom={2}>
                                  <Field fullWidth name="supportSkillC">
                                    {({ meta, form: { setFieldValue, values } }) => (
                                      <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                          size="small"
                                          freeSolo
                                          options={supportSkillOptions}
                                          getOptionLabel={option => option}
                                          value={values.supportSkillC}
                                          filterSelectedOptions
                                          onChange={(e: object, val: any) => {
                                            setFieldValue("supportSkillC", val);
                                          }}
                                          renderInput={params => (
                                            <TField
                                              {...params}
                                              variant="outlined"
                                              label="Support Skill C"
                                            />
                                          )}
                                        />
                                        {meta.touched && meta.error && (
                                          <span>
                                            <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                                          </span>
                                        )}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                                <Box paddingBottom={2}>
                                  <Field fullWidth name="qualSupportSkillC">
                                    {({ meta, form: { setFieldValue, values } }) => (
                                      <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                          size="small"
                                          freeSolo
                                          options={qualificationOptions}
                                          getOptionLabel={option => option}
                                          value={values.qualSupportSkillC}
                                          filterSelectedOptions
                                          onChange={(e: object, val: any) => {
                                            setFieldValue("qualSupportSkillC", val);
                                          }}
                                          renderInput={params => (
                                            <TField
                                              {...params}
                                              variant="outlined"
                                              label="Qualifications Support Skill C"
                                            />
                                          )}
                                        />
                                        {meta.touched && meta.error && (
                                          <span>
                                            <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                                          </span>
                                        )}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                                <Box paddingBottom={2}>
                                  <Field
                                    component={RadioGroup}
                                    name="durationSkillC"
                                    variant="inline"
                                  >
                                    <FormControl variant="outlined" fullWidth>
                                      <div>Duration Skill C</div>
                                      {skillsDurationOptions.map(
                                        (val, index) => (
                                          <FormControlLabel
                                            key={index}
                                            value={val.value}
                                            control={
                                              <Radio value={val.value} />
                                            }
                                            label={val.value}
                                          />
                                        )
                                      )}
                                    </FormControl>
                                  </Field>
                                </Box>
                              </>
                            ) : null}
                          </>
                        )}
                      </Field>
                    </Box>
                  </FormikStep>
                  <FormikStep
                    label="Work Info"
                    validationSchema={Yup.object({
                      workStatus: Yup.string().nullable().required("Required"),
                      wrkplaceOrg: Yup.string(),
                      position: Yup.string(),
                      sectorOfOrganization: Yup.string(),
                      levOfOpnInPubSec: Yup.string(),
                      agricFocus: Yup.string(),
                      agricFocusValChainFocLev: Yup.string(),
                      agricFocusEnterp: Yup.string(),
                      areaOfOpsTargetDistricts: Yup.string(),
                      centralDistricts: Yup.string(),
                      eastDistricts: Yup.string(),
                      westDistricts: Yup.string(),
                      northDistricts: Yup.string(),
                      keyBenef: Yup.string(),
                      keyBenefFarmersCat: Yup.string(),
                      keyBenefFarmersGender: Yup.string()
                    })}
                  >
                    <Box paddingBottom={2}>
                      <Field fullWidth name="workStatus">
                        {({ meta, form: { setFieldValue, values } }) => (
                          <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                              size="small"
                              freeSolo
                              options={workStatusOptions}
                              getOptionLabel={option => option}
                              value={values.workStatus}
                              filterSelectedOptions
                              onChange={(e: object, val: any) => {
                                setFieldValue("workStatus", val);
                              }}
                              renderInput={params => (
                                <TField
                                  {...params}
                                  variant="outlined"
                                  label="Work Status"
                                />
                              )}
                            />
                            {meta.touched && meta.error && (
                              <span>
                                <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                              </span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    <Box paddingBottom={2}>
                      <Field
                        fullWidth
                        size="small"
                        variant="outlined"
                        name="wrkplaceOrg"
                        component={TextField}
                        label="Workplace Organisation / Firm"
                      />
                    </Box>
                    <Box paddingBottom={2}>
                      <Field
                        fullWidth
                        size="small"
                        variant="outlined"
                        name="position"
                        component={TextField}
                        label="Position"
                      />
                    </Box>
                    <Box paddingBottom={2}>
                      <Field fullWidth name="sectorOfOrganization">
                        {({ meta, form: { setFieldValue, values } }) => (
                          <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                              size="small"
                              freeSolo
                              options={sectorOptions}
                              getOptionLabel={option => option}
                              value={values.sectorOfOrganization}
                              filterSelectedOptions
                              onChange={(e: object, val: any) => {
                                setFieldValue("sectorOfOrganization", val);
                              }}
                              renderInput={params => (
                                <TField
                                  {...params}
                                  variant="outlined"
                                  label="Sector of the Organisation/firm"
                                />
                              )}
                            />
                            {meta.touched && meta.error && (
                              <span>
                                <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                              </span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    <Box>
                      <Field>
                        {({ form: { values } }) => (
                          <>
                            {values.sectorOfOrganization === "Public" ? (
                              <Box paddingBottom={2}>
                                <Field fullWidth name="levOfOpnInPubSec">
                                  {({ meta, form: { setFieldValue, values } }) => (
                                    <FormControl variant="outlined" fullWidth>
                                      <Autocomplete
                                        size="small"
                                        options={levOfOpnInPubSecOptions}
                                        getOptionLabel={option => option}
                                        value={values.levOfOpnInPubSec}
                                        filterSelectedOptions
                                        onChange={(e: object, val: any) => {
                                          setFieldValue("levOfOpnInPubSec", val);
                                        }}
                                        renderInput={params => (
                                          <TField
                                            {...params}
                                            variant="outlined"
                                            label="Level of Operation in Public Sector"
                                          />
                                        )}
                                      />
                                      {meta.touched && meta.error && (
                                        <span>
                                          <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                                        </span>
                                      )}
                                    </FormControl>
                                  )}
                                </Field>
                              </Box>
                            ) : null}
                          </>
                        )}
                      </Field>
                    </Box>
                    <Box paddingBottom={2}>
                      <Field fullWidth name="agricFocus">
                        {({ meta, form: { setFieldValue, values } }) => (
                          <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                              size="small"
                              options={["General", "Specific"]}
                              getOptionLabel={option => option}
                              value={values.agricFocus}
                              filterSelectedOptions
                              onChange={(e: object, val: any) => {
                                setFieldValue("agricFocus", val);
                              }}
                              renderInput={params => (
                                <TField
                                  {...params}
                                  variant="outlined"
                                  label="Your Agricultural Focus"
                                />
                              )}
                            />
                            {meta.touched && meta.error && (
                              <span>
                                <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                              </span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    {/* If agricultural focus is specialized */}
                    <Box>
                      <Field>
                        {({ form: { values, setFieldValue } }) => (
                          <>
                            {values.agricFocus && values.agricFocus === "Specific" ? (
                              <>
                                <Box paddingBottom={2}>
                                  <Field fullWidth name="agricFocusValChainFocLev">
                                    {({ meta }) => (
                                      <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                          multiple
                                          size="small"
                                          options={agricFocusValChainFocLevOptions}
                                          getOptionLabel={option => option}
                                          value={values.agricFocusValChainFocLev}
                                          filterSelectedOptions
                                          onChange={(e: object, val: any) => {
                                            setFieldValue("agricFocusValChainFocLev", val);
                                          }}
                                          freeSolo
                                          renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                              <Chip
                                                variant="outlined"
                                                label={option}
                                                {...getTagProps({ index })}
                                              />
                                            ))
                                          }
                                          renderInput={params => (
                                            <TField
                                              {...params}
                                              variant="outlined"
                                              label="Value Chain Focus Level"
                                              placeholder="Focus Area"
                                            />
                                          )}
                                        />
                                        {meta.touched && meta.error && (
                                          <span>
                                            <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                                          </span>
                                        )}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                                <Box paddingBottom={2}>
                                  <Field fullWidth name="agricFocusEnterp">
                                    {({ meta }) => (
                                      <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                          multiple
                                          size="small"
                                          options={agricFocusEnterpOptions}
                                          value={values.agricFocusEnterp}
                                          getOptionLabel={option => option}
                                          freeSolo
                                          renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                              <Chip
                                                variant="outlined"
                                                label={option}
                                                {...getTagProps({ index })}
                                              />
                                            ))
                                          }
                                          onChange={(e: object, val: any) => {
                                            console.log(val);
                                            setFieldValue(
                                              "agricFocusEnterp",
                                              val.map(v => v)
                                            );
                                          }}
                                          filterSelectedOptions
                                          renderInput={params => (
                                            <TField
                                              {...params}
                                              variant="outlined"
                                              label="Focus Enterprises"
                                              placeholder="Enterprise"
                                            />
                                          )}
                                        />
                                        {meta.touched && meta.error && (
                                          <span>
                                            <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                                          </span>
                                        )}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                              </>
                            ) : null}
                          </>
                        )}
                      </Field>
                    </Box>
                    {/* Areas of operation target districts */}
                    <Box paddingBottom={2}>
                      <Field fullWidth name="areaOfOpsTargetDistricts">
                        {({ meta, form: { setFieldValue, values } }) => (
                          <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                              multiple
                              size="small"
                              options={areaOpnTrgtDistrictsOptions}
                              value={values.areaOfOpsTargetDistricts}
                              getOptionLabel={option => option}
                              freeSolo
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              onChange={(e: object, val: any) => {
                                setFieldValue(
                                  "areaOfOpsTargetDistricts",
                                  val
                                );
                              }}
                              filterSelectedOptions
                              renderInput={params => (
                                <TField
                                  {...params}
                                  variant="outlined"
                                  label="Area of Operation: Target Districts"
                                  placeholder="Region"
                                />
                              )}
                            />
                            {meta.touched && meta.error && (
                              <span>
                                <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                              </span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    {/* Districts in the target areas of operation */}
                    <Box>
                      <Field>
                        {({ form: { values } }) => (
                          <>
                            {/* if Central  */}
                            {(values.areaOfOpsTargetDistricts &&
                              values.areaOfOpsTargetDistricts.includes("Central")) ||
                              (values.areaOfOpsTargetDistricts &&
                                values.areaOfOpsTargetDistricts.includes("All")) ? (
                              <>
                                <Box paddingBottom={2}>
                                  <Field fullWidth name="centralDistricts">
                                    {({ meta, form: { setFieldValue, values } }) => (
                                      <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                          multiple
                                          size="small"
                                          options={centralDistrictsOptions}
                                          value={values.centralDistricts}
                                          getOptionLabel={option => option}
                                          freeSolo
                                          renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                              <Chip
                                                variant="outlined"
                                                label={option}
                                                {...getTagProps({ index })}
                                              />
                                            ))
                                          }
                                          onChange={(e: object, val: any) => {
                                            setFieldValue("centralDistricts", val);
                                          }}
                                          filterSelectedOptions
                                          renderInput={params => (
                                            <TField
                                              {...params}
                                              variant="outlined"
                                              label="Central Districts"
                                              placeholder="Districts"
                                            />
                                          )}
                                        />
                                        {meta.touched && meta.error && (
                                          <span className="error">{meta.error}</span>
                                        )}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                              </>
                            ) : null}

                            {/* if East */}
                            {(values.areaOfOpsTargetDistricts &&
                              values.areaOfOpsTargetDistricts.includes("East")) ||
                              (values.areaOfOpsTargetDistricts &&
                                values.areaOfOpsTargetDistricts.includes("All")) ? (
                              <>
                                <Box paddingBottom={2}>
                                  <Field fullWidth name="eastDistricts">
                                    {({ meta, form: { setFieldValue, values } }) => (
                                      <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                          multiple
                                          size="small"
                                          options={easternDistrictsOptions}
                                          value={values.eastDistricts}
                                          getOptionLabel={option => option}
                                          freeSolo
                                          renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                              <Chip
                                                variant="outlined"
                                                label={option}
                                                {...getTagProps({ index })}
                                              />
                                            ))
                                          }
                                          onChange={(e: object, val: any) => {
                                            setFieldValue("eastDistricts", val);
                                          }}
                                          filterSelectedOptions
                                          renderInput={params => (
                                            <TField
                                              {...params}
                                              variant="outlined"
                                              label="East Districts"
                                              placeholder="Districts"
                                            />
                                          )}
                                        />
                                        {meta.touched && meta.error && (
                                          <span>
                                            <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                                          </span>
                                        )}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                              </>
                            ) : null}

                            {/* if West */}
                            {(values.areaOfOpsTargetDistricts && values.areaOfOpsTargetDistricts.includes("West")) ||
                              (values.areaOfOpsTargetDistricts && values.areaOfOpsTargetDistricts.includes("All")) ? (
                              <>
                                <Box paddingBottom={2}>
                                  <Field fullWidth name="westDistricts">
                                    {({ meta, form: { setFieldValue, values } }) => (
                                      <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                          multiple
                                          size="small"
                                          options={westernDistrictsOptions}
                                          value={values.westDistricts}
                                          getOptionLabel={option => option}
                                          freeSolo
                                          renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                              <Chip
                                                variant="outlined"
                                                label={option}
                                                {...getTagProps({ index })}
                                              />
                                            ))
                                          }
                                          onChange={(e: object, val: any) => {
                                            setFieldValue("westDistricts", val);
                                          }}
                                          filterSelectedOptions
                                          renderInput={params => (
                                            <TField
                                              {...params}
                                              variant="outlined"
                                              label="Western Districts"
                                              placeholder="Districts"
                                            />
                                          )}
                                        />
                                        {meta.touched && meta.error && (
                                          <span>
                                            <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                                          </span>
                                        )}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                              </>
                            ) : null}

                            {/* if North */}
                            {(values.areaOfOpsTargetDistricts && values.areaOfOpsTargetDistricts.includes("North")) ||
                              (values.areaOfOpsTargetDistricts && values.areaOfOpsTargetDistricts.includes("All")) ? (
                              <>
                                <Box paddingBottom={2}>
                                  <Field fullWidth name="northDistricts">
                                    {({ meta, form: { setFieldValue, values } }) => (
                                      <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                          multiple
                                          size="small"
                                          options={northernDistrictsOptions}
                                          value={values.northDistricts}
                                          getOptionLabel={option => option}
                                          freeSolo
                                          renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                              <Chip
                                                variant="outlined"
                                                label={option}
                                                {...getTagProps({ index })}
                                              />
                                            ))
                                          }
                                          onChange={(e: object, val: any) => {
                                            setFieldValue("northDistricts", val);
                                          }}
                                          filterSelectedOptions
                                          renderInput={params => (
                                            <TField
                                              {...params}
                                              variant="outlined"
                                              label="Northern Districts"
                                              placeholder="Districts"
                                            />
                                          )}
                                        />
                                        {meta.touched && meta.error && (
                                          <span>
                                            <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                                          </span>
                                        )}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                              </>
                            ) : null}
                          </>
                        )}
                      </Field>
                    </Box>
                    {/* Key beneficiaries */}
                    <Box paddingBottom={2}>
                      <Field fullWidth name="keyBenef">
                        {({ meta, form: { setFieldValue, values } }) => (
                          <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                              multiple
                              size="small"
                              options={keyBenefOptions}
                              value={values.keyBenef}
                              getOptionLabel={option => option}
                              freeSolo
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              onChange={(e: object, val: any) => {
                                setFieldValue("keyBenef", val);
                              }}
                              filterSelectedOptions
                              renderInput={params => (
                                <TField
                                  {...params}
                                  variant="outlined"
                                  label="Key Beneficiaries"
                                  placeholder="Beneficiary"
                                />
                              )}
                            />
                            {meta.touched && meta.error && (
                              <span>
                                <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                              </span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    {/* If key beneficiaries are farmers */}
                    <Box>
                      <Field>
                        {({ form: { values } }) => (
                          <>
                            {values.keyBenef &&
                              values.keyBenef.includes("Farmers") ? (
                              <>
                                {/* Category of farmers */}
                                <Box paddingBottom={2}>
                                  <Field fullWidth name="keyBenefFarmersCat">
                                    {({ meta, form: { setFieldValue, values } }) => (
                                      <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                          multiple
                                          size="small"
                                          options={keyBenefFarmersCatOptions}
                                          value={values.keyBenefFarmersCat}
                                          getOptionLabel={option => option}
                                          freeSolo
                                          renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                              <Chip
                                                variant="outlined"
                                                label={option}
                                                {...getTagProps({ index })}
                                              />
                                            ))
                                          }
                                          onChange={(e: object, val: any) => {
                                            setFieldValue("keyBenefFarmersCat", val);
                                          }}
                                          filterSelectedOptions
                                          renderInput={params => (
                                            <TField
                                              {...params}
                                              variant="outlined"
                                              label="If farmers, which category"
                                              placeholder="Category"
                                            />
                                          )}
                                        />
                                        {meta.touched && meta.error && (
                                          <span>
                                            <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                                          </span>
                                        )}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                                {/* Gender of farmers */}
                                <Box paddingBottom={2}>
                                  <Field fullWidth name="keyBenefFarmersGender">
                                    {({ meta, form: { setFieldValue, values } }) => (
                                      <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                          multiple
                                          size="small"
                                          options={keyBenefFarmersGenderOptions}
                                          value={values.keyBenefFarmersGender}
                                          getOptionLabel={option => option}
                                          freeSolo
                                          renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                              <Chip
                                                variant="outlined"
                                                label={option}
                                                {...getTagProps({ index })}
                                              />
                                            ))
                                          }
                                          onChange={(e: object, val: any) => {
                                            setFieldValue("keyBenefFarmersGender", val);
                                          }}
                                          filterSelectedOptions
                                          renderInput={params => (
                                            <TField
                                              {...params}
                                              variant="outlined"
                                              label="If farmers, which gender?"
                                              placeholder="Category"
                                            />
                                          )}
                                        />
                                        {meta.touched && meta.error && (
                                          <span>
                                            <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                                          </span>
                                        )}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                              </>
                            ) : null}
                          </>
                        )}
                      </Field>
                    </Box>
                  </FormikStep>
                  <FormikStep
                    label="Capacity Needs"
                    validationSchema={Yup.object({
                      capacityNeedFirstChoice: Yup.string().nullable(),
                      capacityNeedSecondChoice: Yup.string().nullable(),
                      capacityNeedThirdChoice: Yup.string().nullable()
                    })}
                  >
                    {/* 1st choice */}
                    <Box paddingBottom={2}>
                      <Field fullWidth name="capacityNeedFirstChoice">
                        {({
                          meta,
                          form: { setFieldValue, values }
                        }) => (
                          <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                              size="small"
                              options={capacityNeedsOptions}
                              getOptionLabel={option => option}
                              value={values.capacityNeedFirstChoice}
                              filterSelectedOptions
                              onChange={(e: object, val: any) => {
                                setFieldValue("capacityNeedFirstChoice", val);
                              }}
                              renderInput={params => (
                                <TField
                                  {...params}
                                  variant="outlined"
                                  label="1st Choice"
                                  placeholder="Choice"
                                />
                              )}
                            />
                            {meta.touched && meta.error && (
                              <span>
                                <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                              </span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    {/* 2nd choice */}
                    <Box paddingBottom={2}>
                      <Field fullWidth name="capacityNeedSecondChoice">
                        {({
                          meta,
                          form: { setFieldValue, values }
                        }) => (
                          <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                              size="small"
                              options={capacityNeedsOptions}
                              getOptionLabel={option => option}
                              value={values.capacityNeedSecondChoice}
                              filterSelectedOptions
                              onChange={(e: object, val: any) => {
                                setFieldValue("capacityNeedSecondChoice", val);
                              }}
                              renderInput={params => (
                                <TField
                                  {...params}
                                  variant="outlined"
                                  label="2nd Choice"
                                  placeholder="Choice"
                                />
                              )}
                            />
                            {meta.touched && meta.error && (
                              <span>
                                <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                              </span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    {/* 3rd choice */}
                    <Box paddingBottom={2}>
                      <Field fullWidth name="capacityNeedThirdChoice">
                        {({
                          meta,
                          form: { setFieldValue, values }
                        }) => (
                          <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                              size="small"
                              options={capacityNeedsOptions}
                              getOptionLabel={option => option}
                              value={values.capacityNeedThirdChoice}
                              filterSelectedOptions
                              onChange={(e: object, val: any) => {
                                setFieldValue("capacityNeedThirdChoice", val);
                              }}
                              renderInput={params => (
                                <TField
                                  {...params}
                                  variant="outlined"
                                  label="3rd Choice"
                                  placeholder="Choice"
                                />
                              )}
                            />
                            {meta.touched && meta.error && (
                              <span>
                                <Typography variant="caption" className={classes.error}>{meta.error}</Typography>
                              </span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                  </FormikStep>
                  <FormikStep label="Summary">
                    <Box paddingBottom={2}>
                      <h1>Summary of the information</h1>
                      <Field>
                        {({ form: { values } }) => (
                          <>
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                aria-label="simple table"
                              >
                                <TableBody>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Category
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.category}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      FirstName
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.firstName}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      LastName
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.lastName}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      NIN
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.nin}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Date of Birth
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {moment(values.dob).format("DD/MM/YYYY")}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Gender
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.sex}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Country
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.country}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Telephone 1
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.telephone1}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Telephone 2
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.telephone2}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Email
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.email}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      District of Registration
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.districtOfRegistration}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Field of agricultural-related
                                      qualification
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.agricRelQual}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Highest Qualification
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.highestQual}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Year of Qualification
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.yearOfQual}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Other main support skills Training?
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.otherMainSuppSkillsTrain}
                                    </TableCell>
                                  </TableRow>
                                  {values.otherMainSuppSkillsTrain ===
                                    "Yes" && (
                                      <>
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            Support Skill A
                                        </TableCell>
                                          <TableCell component="th" scope="row">
                                            {values.supportSkillA}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            Qualification Support Skill A
                                        </TableCell>
                                          <TableCell component="th" scope="row">
                                            {values.qualSupportSkillA}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            Duration Skill A
                                        </TableCell>
                                          <TableCell component="th" scope="row">
                                            {values.durationSkillA}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            Support Skill B
                                        </TableCell>
                                          <TableCell component="th" scope="row">
                                            {values.supportSkillB}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            Qualification Support Skill B
                                        </TableCell>
                                          <TableCell component="th" scope="row">
                                            {values.qualSupportSkillB}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            Duration Skill B
                                        </TableCell>
                                          <TableCell component="th" scope="row">
                                            {values.durationSkillB}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            Support Skill C
                                        </TableCell>
                                          <TableCell component="th" scope="row">
                                            {values.supportSkillC}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            Qualification Support Skill C
                                        </TableCell>
                                          <TableCell component="th" scope="row">
                                            {values.qualSupportSkillC}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            Duration Skill C
                                        </TableCell>
                                          <TableCell component="th" scope="row">
                                            {values.durationSkillC}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    )}
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Work Status
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.workStatus}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Workplace Organisation / Firm
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.wrkplaceOrg}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Position
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.position}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Sector of the Organisation/firm
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.sectorOfOrganization}
                                    </TableCell>
                                  </TableRow>
                                  {values.sectorOfOrganization === "Public" && (
                                    <>
                                      <TableRow>
                                        <TableCell component="th" scope="row">
                                          Level of Operation in Public Sector
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {values.levOfOpnInPubSec}
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  )}
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Your Agricultural Focus
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.agricFocus}
                                    </TableCell>
                                  </TableRow>
                                  {values.agricFocus === "Specific" && (
                                    <>
                                      <TableRow>
                                        <TableCell component="th" scope="row">
                                          Value Chain Focus Level
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {values.agricFocusValChainFocLev.join()}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell component="th" scope="row">
                                          Focus Enterprises
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {values.agricFocusEnterp.join()}
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  )}
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Area of Operation: Target Districts
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.areaOfOpsTargetDistricts.join()}
                                    </TableCell>
                                  </TableRow>
                                  {values.areaOfOpsTargetDistricts.includes("All") ||
                                    (values.areaOfOpsTargetDistricts.includes("Central") && (
                                      <>
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            Central Districts
                                          </TableCell>
                                          <TableCell component="th" scope="row">
                                            {values.centralDistricts.join()}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}

                                  {values.areaOfOpsTargetDistricts.includes("All") ||
                                    (values.areaOfOpsTargetDistricts.includes("East") && (
                                      <>
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            East Districts
                                          </TableCell>
                                          <TableCell component="th" scope="row">
                                            {values.eastDistricts.join()}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}
                                  {values.areaOfOpsTargetDistricts.includes("All") ||
                                    (values.areaOfOpsTargetDistricts.includes("West") && (
                                      <>
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            West Districts
                                          </TableCell>
                                          <TableCell component="th" scope="row">
                                            {values.westDistricts.join()}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}
                                  {values.areaOfOpsTargetDistricts.includes("All") ||
                                    (values.areaOfOpsTargetDistricts.includes("North") && (
                                      <>
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            North Districts
                                          </TableCell>
                                          <TableCell component="th" scope="row">
                                            {values.northDistricts.join()}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Key Beneficiaries
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.keyBenef.join()}
                                    </TableCell>
                                  </TableRow>
                                  {values.keyBenef === "Farmers" && (
                                    <>
                                      <TableRow>
                                        <TableCell component="th" scope="row">
                                          If farmers, which category
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {values.keyBenefFarmersCat.join()}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell component="th" scope="row">
                                          If farmers, which gender?
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {values.keyBenefFarmersGender.join()}
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  )}
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      1st Choice
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.capacityNeedFirstChoice}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      2nd Choice
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.capacityNeedSecondChoice}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      3rd Choice
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.capacityNeedThirdChoice}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        )}
                      </Field>
                    </Box>
                  </FormikStep>
                </FormikStepper>
              </Grid>
              <Grid md={2} xs={12} item></Grid>
            </Grid>
          </div>
        </>
      </Grid>
    </Grid>
  
  </>
  );
}

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, "children" | "validationSchema"> {
  label: string;
}

export function FormikStep({ children }: FormikStepProps) {
  return <>{children}</>;
}

export function FormikStepper({
  children,
  ...props
}: FormikConfig<FormikValues>) {
  const childrenArray = React.Children.toArray(children) as React.ReactElement<
    FormikStepProps
  >[];
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step] as React.ReactElement<
    FormikStepProps
  >;
  const [completed, setCompleted] = useState(false);

  const uuid = useSelector(state => state.worker.workerID);
  const saved = useSelector(state => state.worker.saved);

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Formik
        {...props}
        validationSchema={currentChild.props.validationSchema}
        onSubmit={async (values, helpers) => {
          if (isLastStep()) {
            await props.onSubmit(values, helpers);
            setCompleted(true);
            //setStep(0)
          } else {
            setStep(s => s + 1);
          }
        }}
      >
        {({ isSubmitting, resetForm }) => (
          <Form autoComplete="off">
            <Stepper alternativeLabel activeStep={step}>
              {childrenArray.map((child, index) => (
                <Step
                  key={child.props.label}
                  completed={step > index || completed}
                >
                  <StepLabel>{child.props.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {currentChild}
            {completed && saved ? (
              <div>
                <Typography variant="body1">Generated Worker ID</Typography>
                <Typography variant="h3" color="primary">
                  {uuid}
                </Typography>
              </div>
            ) : null}
            {completed && (saved === false) ? (
              <div>
                <Typography variant="body1" color="error">
                  Something went wrong. Try again
                </Typography>
              </div>
            ) : null}
            {completed && saved ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  resetForm();
                  setCompleted(false);
                  setStep(0);
                }}
              >
                New Form
              </Button>
            ) : <Button
              disabled={isSubmitting || step === 0}
              variant="contained"
              color="primary"
              onClick={() => setStep(s => s - 1)}
            >
              Back
              </Button>}
            &nbsp;
            <Button
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitting || (completed && saved)}
              variant="contained"
              color="primary"
              type="submit"
            >
              {isSubmitting
                ? "Submitting..."
                : isLastStep()
                  ? "Submit"
                  : "Next"}
            </Button>
          </Form>
        )}
      </Formik>
    </MuiPickersUtilsProvider>
  );
}
