import {
    combineReducers
} from "redux";
import authReducer from "./auth";
import message from "./message";
import workerReducer from "./worker";
import organizationReducer from "./organization";
import userReducer from "./user"

export default combineReducers({
    auth: authReducer,
    worker: workerReducer,
    organization: organizationReducer,
    user: userReducer,
    message,
});