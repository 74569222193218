export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const START_USERS_FETCH = "START_USERS_FETCH";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const FETCH_ALL_USERS_FAIL = "FETCH_ALL_USERS_FAIL";
export const FINISH_USERS_FETCH = "FINISH_USERS_FETCH";

export const REGISTER_WORKER = "REGISTER_WORKER";
export const REGISTER_WORKER_SUCCESS = "REGISTER_WORKER_SUCCESS";
export const REGISTER_WORKER_FAIL = "REGISTER_WORKER_FAIL";

export const FETCH_ALL_WORKERS = "FETCH_ALL_WORKERS";
export const FETCH_ALL_WORKERS_SUCCESS = "FETCH_ALL_WORKERS_SUCCESS";
export const FETCH_ALL_WORKERS_FAIL = "FETCH_ALL_WORKERS_FAIL";

export const REGISTER_ORGANIZATION = "REGISTER_ORGANIZATION";
export const REGISTER_ORGANIZATION_SUCCESS = "REGISTER_ORGANIZATION_SUCCESS";
export const REGISTER_ORGANIZATION_FAIL = "REGISTER_ORGANIZATION_FAIL";

export const FETCH_ALL_ORGANIZATIONS = "FETCH_ALL_ORGANIZATIONS";
export const FETCH_ALL_ORGANIZATIONS_SUCCESS = "FETCH_ALL_ORGANIZATIONS_SUCCESS";
export const FETCH_ALL_ORGANIZATIONS_FAIL = "FETCH_ALL_ORGANIZATIONS_FAIL";

export const FETCH_ROLES = "FETCH_ROLES";
export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const FETCH_ROLES_FAIL = "FETCH_ROLES_FAIL";

export const FETCH_A_WORKER_BY_ID = "FETCH_A_WORKER_BY_ID";
export const FETCH_A_WORKER_BY_ID_SUCCESS = "FETCH_A_WORKER_BY_ID_SUCCESS";
export const FETCH_A_WORKER_BY_ID_FAIL = "FETCH_A_WORKER_BY_ID_FAIL";

export const FETCH_AN_ORGANIZATION_BY_ID = "FETCH_AN_ORGANIZATION_BY_ID";
export const FETCH_AN_ORGANIZATION_BY_ID_SUCCESS = "FETCH_AN_ORGANIZATION_BY_ID_SUCCESS";
export const FETCH_AN_ORGANIZATION_BY_ID_FAIL = "FETCH_AN_ORGANIZATION_BY_ID_FAIL";

export const FETCH_A_USER_BY_EMAIL = "FETCH_A_USER_BY_EMAIL";
export const FETCH_A_USER_BY_EMAIL_SUCCESS = "FETCH_A_USER_BY_EMAIL_SUCCESS";
export const FETCH_A_USER_BY_EMAIL_FAIL = "FETCH_A_USER_BY_EMAIL_FAIL";

export const FETCH_WORKER_PROFILE_FOR_GUEST_BY_ID = "FETCH_WORKER_PROFILE_FOR_GUEST_BY_ID";
export const FETCH_WORKER_PROFILE_FOR_GUEST_BY_ID_SUCCESS = "FETCH_WORKER_PROFILE_FOR_GUEST_BY_ID_SUCCESS";
export const FETCH_WORKER_PROFILE_FOR_GUEST_BY_ID_FAIL = "FETCH_WORKER_PROFILE_FOR_GUEST_BY_ID_FAIL";

export const FETCH_ORGANIZAION_PROFILE_FOR_GUEST_BY_ID = "FETCH_ORGANIZAION_PROFILE_FOR_GUEST_BY_ID";
export const FETCH_ORGANIZAION_PROFILE_FOR_GUEST_BY_ID_SUCCESS = "FETCH_ORGANIZAION_PROFILE_FOR_GUEST_BY_ID_SUCCESS";
export const FETCH_ORGANIZAION_PROFILE_FOR_GUEST_BY_ID_FAIL = "FETCH_ORGANIZAION_PROFILE_FOR_GUEST_BY_ID_FAIL";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const UPDATE_INDIVIDUAL_DETAILS = "UPDATE_INDIVIDUAL_DETAILS";
export const UPDATE_INDIVIDUAL_DETAILS_SUCCESS = "UPDATE_INDIVIDUAL_DETAILS_SUCCESS";
export const UPDATE_INDIVIDUAL_DETAILS_FAIL = "UPDATE_INDIVIDUAL_DETAILS_FAIL";

export const UPDATE_INDIVIDUAL_CONTACT = "UPDATE_INDIVIDUAL_CONTACT";
export const UPDATE_INDIVIDUAL_CONTACT_SUCCESS = "UPDATE_INDIVIDUAL_CONTACT_SUCCESS";
export const UPDATE_INDIVIDUAL_CONTACT_FAIL = "UPDATE_INDIVIDUAL_CONTACT_FAIL";

export const UPDATE_INDIVIDUAL_QUALIFICATIONS = "UPDATE_INDIVIDUAL_QUALIFICATIONS";
export const UPDATE_INDIVIDUAL_QUALIFICATIONS_SUCCESS = "UPDATE_INDIVIDUAL_QUALIFICATIONS_SUCCESS";
export const UPDATE_INDIVIDUAL_QUALIFICATIONS_FAIL = "UPDATE_INDIVIDUAL_QUALIFICATIONS_FAIL";

export const UPDATE_INDIVIDUAL_WORK_INFO = "UPDATE_INDIVIDUAL_WORK_INFO";
export const UPDATE_INDIVIDUAL_WORK_INFO_SUCCESS = "UPDATE_INDIVIDUAL_WORK_INFO_SUCCESS";
export const UPDATE_INDIVIDUAL_WORK_INFO_FAIL = "UPDATE_INDIVIDUAL_WORK_INFO_FAIL";

export const UPDATE_INDIVIDUAL_CAPACITY_NEEDS = "UPDATE_INDIVIDUAL_CAPACITY_NEEDS";
export const UPDATE_INDIVIDUAL_CAPACITY_NEEDS_SUCCESS = "UPDATE_INDIVIDUAL_CAPACITY_NEEDS_SUCCESS";
export const UPDATE_INDIVIDUAL_CAPACITY_NEEDS_FAIL = "UPDATE_INDIVIDUAL_CAPACITY_NEEDS_FAIL";

export const UPDATE_ORGANIZATION_ABOUT = "UPDATE_ORGANIZATION_ABOUT"
export const UPDATE_ORGANIZATION_ABOUT_SUCCESS = "UPDATE_ORGANIZATION_ABOUT_SUCCESS"
export const UPDATE_ORGANIZATION_ABOUT_FAIL = "UPDATE_ORGANIZATION_ABOUT_FAIL"

export const UPDATE_ORGANIZATION_HEAD_OFFICE = "UPDATE_ORGANIZATION_HEAD_OFFICE"
export const UPDATE_ORGANIZATION_HEAD_OFFICE_SUCCESS = "UPDATE_ORGANIZATION_HEAD_OFFICE_SUCCESS"
export const UPDATE_ORGANIZATION_HEAD_OFFICE_FAIL = "UPDATE_ORGANIZATION_HEAD_OFFICE_FAIL"

export const UPDATE_ORGANIZATION_CONTACT_PERSON = "UPDATE_ORGANIZATION_CONTACT_PERSON"
export const UPDATE_ORGANIZATION_CONTACT_PERSON_SUCCESS = "UPDATE_ORGANIZATION_CONTACT_PERSON_SUCCESS"
export const UPDATE_ORGANIZATION_CONTACT_PERSON_FAIL = "UPDATE_ORGANIZATION_CONTACT_PERSON_FAIL"

export const UPDATE_ORGANIZATION_STAFF = "UPDATE_ORGANIZATION_STAFF"
export const UPDATE_ORGANIZATION_STAFF_SUCCESS = "UPDATE_ORGANIZATION_STAFF_SUCCESS"
export const UPDATE_ORGANIZATION_STAFF_FAIL = "UPDATE_ORGANIZATION_STAFF_FAIL"

export const UPDATE_ORGANIZATION_SECTOR_INFO = "UPDATE_ORGANIZATION_SECTOR_INFO"
export const UPDATE_ORGANIZATION_SECTOR_INFO_SUCCESS = "UPDATE_ORGANIZATION_SECTOR_INFO_SUCCESS"
export const UPDATE_ORGANIZATION_SECTOR_INFO_FAIL = "UPDATE_ORGANIZATION_SECTOR_INFO_FAIL"

export const UPDATE_ORGANIZATION_CAPACITY_NEEDS = "UPDATE_ORGANIZATION_CAPACITY_NEEDS"
export const UPDATE_ORGANIZATION_CAPACITY_NEEDS_SUCCESS = "UPDATE_ORGANIZATION_CAPACITY_NEEDS_SUCCESS"
export const UPDATE_ORGANIZATION_CAPACITY_NEEDS_FAIL = "UPDATE_ORGANIZATION_CAPACITY_NEEDS_FAIL"