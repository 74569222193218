import { IconButton, Typography } from "@material-ui/core";
import { ArrowBackTwoTone } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { workersFetchDetailsForGuestById } from "../actions/user";
import PageHeader from "../components/PageHeader";
import WorkerProfileForGuest from "../components/workers/WorkerProfileForGuest";

function GuestDetailsForWorker(props) {
  const uuid = props.match.params.id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(workersFetchDetailsForGuestById(uuid))
      .then(() => {})
      .catch(() => {
        console.log("AN ERROR OCCURED");
      });
  }, []);
  const worker = useSelector((state) => state.worker.worker);
  const isFetching = useSelector((state) => state.worker.isFetching);

  return (
    <>
      {isFetching ? (
        <Typography variant="subtitle1">Fetching....</Typography>
      ) : null}
      <PageHeader
        title="Extension worker"
        subtitle="Details"
        icon={
          <IconButton>
            <a href="\workers">
              <ArrowBackTwoTone />
            </a>
          </IconButton>
        }
      />
      <WorkerProfileForGuest profile={worker} />
    </>
  );
}

export default GuestDetailsForWorker;
