import { makeStyles } from '@material-ui/core'
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react'
import * as Yup from "yup";
import { centralDistrictsOptions, easternDistrictsOptions, northernDistrictsOptions, westernDistrictsOptions } from '../../data/districts';
import { agricFocusEnterpOptions, agricFocusValChainFocLevOptions, agricSectorFocusOptions, areaOpnTrgtDistrictsOptions, keyBenefFarmersCatOptions, keyBenefFarmersGenderOptions, keyBenefOptions, sectorOptions } from '../../utils/options';
import Controls from '../controls/Controls';
import UpdateFormHOC from '../HOC/UpdateFormHOC';
import { useDispatch } from 'react-redux'
import { actionUpdateOrgSectorInfo } from '../../actions/organization';
const useStyles = makeStyles((theme) => ({
    tForm: {
        "& .MuiFormControl-root": {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
}));


function SectorInformationUpdateForm(props) {
    const { data, closePopup, history, reloadPage } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <UpdateFormHOC>
            <Formik
                initialValues={{
                    sector: data.Sector,
                    agricSectorFocus: data.AgricSectorFocus,
                    focusValueChain: data.FocusValueChain ? data.FocusValueChain.split(",") : [],
                    focusEnterprises: data.FocusEnterprises ? data.FocusEnterprises.split(",") : [],
                    mission: data.Mission,
                    areaOpnTrgtDistricts: data.AreaOpnTrgtDistricts ? data.AreaOpnTrgtDistricts.split(",") : [],
                    centralDistricts: data.CentralDistricts ? data.CentralDistricts.split(",") : [],
                    eastDistricts: data.EastDistricts ? data.EastDistricts.split(",") : [],
                    westDistricts: data.WestDistricts ? data.WestDistricts.split(",") : [],
                    northDistricts: data.NorthDistricts ? data.NorthDistricts.split(",") : [],
                    keyBenefs: data.KeyBenefs ? data.KeyBenefs.split(",") : [],
                    keyBeneFrmrsCateg: data.KeyBeneFrmrsCateg ? data.KeyBeneFrmrsCateg.split(",") : [],
                    keyBenefFarmersGender: data.KeyBeneFrmrsGender ? data.KeyBeneFrmrsGender.split(",") : []
                }}
                validationSchema={Yup.object({
                    sector: Yup.string().required("Required"),
                    agricSectorFocus: Yup.string().required("Required"),
                    focusValueChain: Yup.string(),
                    focusEnterprises: Yup.string(),
                    mission: Yup.string("Required"),
                    areaOpnTrgtDistricts: Yup.string().required("Required"),
                    centralDistricts: Yup.string(),
                    eastDistricts: Yup.string(),
                    westDistricts: Yup.string(),
                    northDistricts: Yup.string(),
                    keyBenefs: Yup.string(),
                    keyBeneFrmrsCateg: Yup.string(),
                    keyBenefFarmersGender: Yup.string()
                })}

                onSubmit={async (values, { setSubmitting }) => {

                    var hasCentral = values.areaOpnTrgtDistricts.includes("Central") || values.areaOpnTrgtDistricts.includes("All");
                    var hasEast = values.areaOpnTrgtDistricts.includes("East") || values.areaOpnTrgtDistricts.includes("All");
                    var hasWest = values.areaOpnTrgtDistricts.includes("West") || values.areaOpnTrgtDistricts.includes("All");
                    var hasNorth = values.areaOpnTrgtDistricts.includes("North") || values.areaOpnTrgtDistricts.includes("All");

                    if (values.areaOpnTrgtDistricts.length === 0) {
                        values = {
                            ...values,
                            centralDistricts: [],
                            eastDistricts: [],
                            westDistricts: [],
                            northDistricts: [],
                        }
                    }else{
                        if(!hasCentral ){
                            //centralDistricts = null
                            values = { ...values, centralDistricts: [] }
                        }
                        if(!hasEast){
                            //eastDistricts = null
                            values = { ...values, eastDistricts: [] }
                        }
                        if(!hasWest){
                            //westDistricts = null
                            values = { ...values, westDistricts: [] }
                        }
                        if(!hasNorth){
                            //northDistricts = null
                            values = { ...values, northDistricts: [] }
                        }
                    }


                    if (values.agricSectorFocus !== "Specific") {
                        // agricFocusValChainFocLev = null
                        // agricFocusEnterp = null
                        values = { ...values, focusValueChain: [], focusEnterprises: [] }
                    }
                    

                    if (!values.keyBenefs.includes("Farmers")) {
                        //keyBeneFrmrsCateg = null
                        //keyBenefFarmersGender = null
                        values = { ...values, keyBeneFrmrsCateg: [], keyBenefFarmersGender: [] }
                    }

                    await dispatch(actionUpdateOrgSectorInfo(data.Id, values))
                        .then(() => {
                            setSubmitting(false)
                            history.push(
                                `/manage-organization/` + data.Id + `/details`
                            );
                            reloadPage()
                            closePopup()
                        })
                        .catch((e) => {
                            setSubmitting(false)
                        })
                }}
            >
                {({ isSubmitting, isValid }) =>
                    <Form autoComplete="off" autoCapitalize="true" className={classes.tForm}>

                        <Field fullWidth name="sector">
                            {({
                                meta,
                                form: { setFieldValue, values }
                            }) => (
                                <Controls.FormikSelect
                                    name="sector"
                                    value={values.sector}
                                    options={sectorOptions}
                                    label="Sector"
                                    placeholder="Sector"
                                    setFieldValue={setFieldValue}
                                    meta={meta}
                                />
                            )}
                        </Field>


                        <Field fullWidth name="agricSectorFocus">
                            {({
                                meta,
                                form: { setFieldValue, values }
                            }) => (
                                <Controls.FormikSelect
                                    name="agricSectorFocus"
                                    value={values.agricSectorFocus}
                                    options={agricSectorFocusOptions}
                                    label="Agricultural sector focus"
                                    placeholder="Agricultural sector focus"
                                    setFieldValue={setFieldValue}
                                    meta={meta}
                                />
                            )}
                        </Field>

                        <Field>
                            {({ form: { values, setFieldValue } }) => (
                                <>
                                    {values.agricSectorFocus &&
                                        values.agricSectorFocus === "Specific" ? (
                                        <>
                                            <Field fullWidth name="focusValueChain">
                                                {({
                                                    meta,
                                                    form: { setFieldValue, values }
                                                }) => (
                                                    <Controls.FormikSelect
                                                        multiple={true}
                                                        freeSolo={true}
                                                        name="focusValueChain"
                                                        value={values.focusValueChain}
                                                        options={agricFocusValChainFocLevOptions}
                                                        label="Value Chain Focus Level"
                                                        placeholder="Focus Area"
                                                        setFieldValue={setFieldValue}
                                                        meta={meta}
                                                    />
                                                )}
                                            </Field>

                                            <Field fullWidth name="focusEnterprises">
                                                {({
                                                    meta,
                                                    form: { setFieldValue, values }
                                                }) => (
                                                    <Controls.FormikSelect
                                                        multiple={true}
                                                        freeSolo={true}
                                                        name="focusEnterprises"
                                                        value={values.focusEnterprises}
                                                        options={agricFocusEnterpOptions}
                                                        label="Focus Enterprises"
                                                        placeholder="Enterprise"
                                                        setFieldValue={setFieldValue}
                                                        meta={meta}
                                                    />
                                                )}
                                            </Field>
                                        </>) : null
                                    }
                                </>
                            )}
                        </Field>

                        <Field
                            fullWidth
                            size="small"
                            name="mission"
                            component={TextField}
                            variant="outlined"
                            label="Mission"
                            multiline
                        />

                        <Field fullWidth name="areaOpnTrgtDistricts">
                            {({
                                meta,
                                form: { setFieldValue, values }
                            }) => (
                                <Controls.FormikSelect
                                    multiple={true}
                                    name="areaOpnTrgtDistricts"
                                    value={values.areaOpnTrgtDistricts}
                                    options={areaOpnTrgtDistrictsOptions}
                                    label="Area of Operation: Target Districts"
                                    placeholder="Region"
                                    setFieldValue={setFieldValue}
                                    meta={meta}
                                    freeSolo={true}
                                />
                            )}
                        </Field>
                        <Field>
                            {({ form: { values } }) => (
                                <>
                                    {/* if Central  */}
                                    {(values.areaOpnTrgtDistricts &&
                                        values.areaOpnTrgtDistricts.includes("Central")) ||
                                        (values.areaOpnTrgtDistricts &&
                                            values.areaOpnTrgtDistricts.includes("All")) ? (
                                        <>
                                            <Field fullWidth name="centralDistricts">
                                                {({
                                                    meta,
                                                    form: { setFieldValue, values }
                                                }) => (
                                                    <Controls.FormikSelect
                                                        multiple={true}
                                                        name="centralDistricts"
                                                        value={values.centralDistricts}
                                                        options={centralDistrictsOptions}
                                                        label="Central Districts"
                                                        placeholder="Central Districts"
                                                        setFieldValue={setFieldValue}
                                                        meta={meta}
                                                        freeSolo={true}
                                                    />
                                                )}
                                            </Field>
                                        </>
                                    ) : null}

                                    {/* If East */}
                                    {(values.areaOpnTrgtDistricts &&
                                        values.areaOpnTrgtDistricts.includes("East")) ||
                                        (values.areaOpnTrgtDistricts &&
                                            values.areaOpnTrgtDistricts.includes("All")) ? (
                                        <>
                                            <Field fullWidth name="eastDistricts">
                                                {({
                                                    meta,
                                                    form: { setFieldValue, values }
                                                }) => (
                                                    <Controls.FormikSelect
                                                        multiple={true}
                                                        name="eastDistricts"
                                                        value={values.eastDistricts}
                                                        options={easternDistrictsOptions}
                                                        label="East Districts"
                                                        placeholder="Districts"
                                                        setFieldValue={setFieldValue}
                                                        meta={meta}
                                                        freeSolo={true}
                                                    />
                                                )}
                                            </Field>
                                        </>
                                    ) : null}

                                    {/* if West */}
                                    {(values.areaOpnTrgtDistricts &&
                                        values.areaOpnTrgtDistricts.includes("West")) ||
                                        (values.areaOpnTrgtDistricts &&
                                            values.areaOpnTrgtDistricts.includes("All")) ? (
                                        <>
                                            <Field fullWidth name="westDistricts">
                                                {({
                                                    meta,
                                                    form: { setFieldValue, values }
                                                }) => (
                                                    <Controls.FormikSelect
                                                        multiple={true}
                                                        name="westDistricts"
                                                        value={values.westDistricts}
                                                        options={westernDistrictsOptions}
                                                        label="West Districts"
                                                        placeholder="Districts"
                                                        setFieldValue={setFieldValue}
                                                        meta={meta}
                                                        freeSolo={true}
                                                    />
                                                )}
                                            </Field>
                                        </>
                                    ) : null}
                                    {/* if North */}
                                    {(values.areaOpnTrgtDistricts &&
                                        values.areaOpnTrgtDistricts.includes("North")) ||
                                        (values.areaOpnTrgtDistricts &&
                                            values.areaOpnTrgtDistricts.includes("All")) ? (
                                        <>
                                            <Field fullWidth name="northDistricts">
                                                {({
                                                    meta,
                                                    form: { setFieldValue, values }
                                                }) => (
                                                    <Controls.FormikSelect
                                                        multiple={true}
                                                        name="northDistricts"
                                                        value={values.northDistricts}
                                                        options={northernDistrictsOptions}
                                                        label="North Districts"
                                                        placeholder="Districts"
                                                        setFieldValue={setFieldValue}
                                                        meta={meta}
                                                        freeSolo={true}
                                                    />
                                                )}
                                            </Field>
                                        </>
                                    ) : null}
                                </>
                            )}
                        </Field>

                        {/* Key beneficiaries */}
                        <Field fullWidth name="keyBenefs">
                            {({
                                meta,
                                form: { setFieldValue, values }
                            }) => (
                                <Controls.FormikSelect
                                    multiple={true}
                                    name="keyBenefs"
                                    value={values.keyBenefs}
                                    options={keyBenefOptions}
                                    label="Key Beneficiaries"
                                    placeholder="Beneficiary"
                                    setFieldValue={setFieldValue}
                                    meta={meta}
                                    freeSolo={true}
                                />
                            )}
                        </Field>

                        {/* If key beneficiaries are farmers */}
                        <Field>
                            {({ form: { values } }) => (
                                <>
                                    {values.keyBenefs &&
                                        values.keyBenefs.includes("Farmers") ? (
                                        <>
                                            {/* Category of farmers */}
                                            <Field fullWidth name="keyBeneFrmrsCateg">
                                                {({
                                                    meta,
                                                    form: { setFieldValue, values }
                                                }) => (
                                                    <Controls.FormikSelect
                                                        multiple={true}
                                                        name="keyBeneFrmrsCateg"
                                                        value={values.keyBeneFrmrsCateg}
                                                        options={keyBenefFarmersCatOptions}
                                                        label="If farmers, which category"
                                                        placeholder="Category"
                                                        setFieldValue={setFieldValue}
                                                        meta={meta}
                                                        freeSolo={true}
                                                    />
                                                )}
                                            </Field>
                                            {/* Gender of farmers */}
                                            <Field fullWidth name="keyBenefFarmersGender">
                                                {({
                                                    meta,
                                                    form: { setFieldValue, values }
                                                }) => (
                                                    <Controls.FormikSelect
                                                        multiple={true}
                                                        name="keyBenefFarmersGender"
                                                        value={values.keyBenefFarmersGender}
                                                        options={keyBenefFarmersGenderOptions}
                                                        label="If farmers, which gender?"
                                                        placeholder="Gender"
                                                        setFieldValue={setFieldValue}
                                                        meta={meta}
                                                        freeSolo={true}
                                                    />
                                                )}
                                            </Field>
                                        </>
                                    ) : null}
                                </>
                            )}
                        </Field>

                        <Controls.SubmitButton
                            isSubmitting={isSubmitting}
                            isValid={isValid}
                        />
                    </Form>}

            </Formik>
        </UpdateFormHOC>
    )
}

export default SectorInformationUpdateForm
