export default function authHeader() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access_Token) {
        // For Spring Boot and .NET Core back-end
        return {
            Authorization: "Bearer " + user.access_Token
        };

        // for Node.js Express back-end
        // return {
        //     "x-access-token": user.accessToken
        // };
    } else {
        return {};
    }
}