import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { register } from "../actions/auth";
import { fetchAllRoles } from "../actions/user";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Form, Field, Formik } from "formik";
import { TextField as FTField } from "formik-material-ui";
import * as Yup from "yup";
import Passwordinput from "../components/passwordinput";
import PageHeader from "../components/PageHeader";
import { ArrowBackTwoTone } from "@material-ui/icons";
import Controls from "../components/controls/Controls";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  error: {
    color: "red",
  },
});
const RegisterUser = (props) => {
  const classes = useStyles();

  const { message } = useSelector((state) => state.message);
  const roles = useSelector((state) => state.user.roles);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllRoles())
      .then(() => {})
      .catch(() => {
        console.log("Error occured");
      });
  }, []);

  return (
    <>
      <PageHeader
        icon={
          <IconButton>
            <a href="\manage-users">
              <ArrowBackTwoTone />
            </a>
          </IconButton>
        }
        title="Register user"
        subtitle="Add a new user"
      />
      <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <Formik
            initialValues={{
              firstName: "",
              secondName: "",
              contact: "",
              email: "",
              password: "",
              confirmPassword: "",
              userRoles: [],
            }}
            validationSchema={Yup.object({
              firstName: Yup.string().required("Required"),
              secondName: Yup.string().required("Required"),
              contact: Yup.string().required("Required"),
              email: Yup.string().email("Invalid email").required("Required"),
              password: Yup.string()
                            .required("Required")
                            .matches(/[!@#$%^&*()_+\-=[\]{};':"\\/,.<>/?]+/,
                                      'Requires atleast one special character')
                            .matches(/[A-Z]+/,
                                      'Requires atleast one uppercase letter')
                            .matches(/[0-9]+/,
                                        'Requires atleast one number')
                            .min(6, "Must be atleast 6 characters"),
              confirmPassword: Yup.string()
                .required("Required")
                .test({
                  exclusive: false,
                  message: "Passwords don't match",
                  test: function (value) {
                    let confirmPass = value;
                    let pass = this.parent.password;

                    if (confirmPass === pass) return true;
                    else return false;
                  },
                }),
            })}
            onSubmit={async (values) => {
              await dispatch(
                register(
                  values.firstName,
                  values.secondName,
                  values.contact,
                  values.userRoles,
                  values.email,
                  values.password,
                  values.confirmPassword
                )
              )
                .then(() => {
                  props.history.push("/manage-users");
                  // window.location.reload();
                })
                .catch(() => {
                  console.log("an error for occured ");
                });
            }}
          >
            {({ isSubmitting, isValid }) => (
              <Form autoComplete="off">
                <Box paddingBottom={2}>
                  <Field
                    fullWidth
                    size="small"
                    name="firstName"
                    component={FTField}
                    variant="outlined"
                    label="FirstName"
                  />
                </Box>
                <Box paddingBottom={2}>
                  <Field
                    fullWidth
                    size="small"
                    name="secondName"
                    component={FTField}
                    variant="outlined"
                    label="Second Name"
                  />
                </Box>
                <Box paddingBottom={2}>
                  <Field
                    fullWidth
                    size="small"
                    name="contact"
                    component={FTField}
                    variant="outlined"
                    label="Contact"
                  />
                </Box>
                <Box paddingBottom={2}>
                  <Field
                    fullWidth
                    size="small"
                    name="email"
                    component={FTField}
                    variant="outlined"
                    label="Email"
                  />
                </Box>
                <Box paddingBottom={2}>
                  <Field fullWidth name="password">
                    {({ meta, form: { setFieldValue, values } }) => (
                      <FormControl variant="outlined" fullWidth>
                        <Passwordinput
                          label="Password"
                          name="password"
                          value={values.password}
                          onChange={(e) =>
                            setFieldValue("password", e.target.value)
                          }
                        />
                        {meta.error && (
                          <span className={classes.error}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box paddingBottom={2}>
                  <Field fullWidth name="confirmPassword">
                    {({ meta, form: { setFieldValue, values } }) => (
                      <FormControl variant="outlined" fullWidth>
                        <Passwordinput
                          label="Confirm Password"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={(e) =>
                            setFieldValue("confirmPassword", e.target.value)
                          }
                        />
                        
                        {meta.error && (
                          <span className={classes.error}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box paddingBottom={2}>
                  <Field fullWidth name="roles">
                    {({ meta, form: { setFieldValue, values } }) => (
                      <FormControl variant="outlined" fullWidth>
                        <Typography variant="body2">Roles</Typography>
                        {roles &&
                          roles.data &&
                          Object.entries(roles.data).map(([key, val]) => (
                            <React.Fragment key={key}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.userRoles.includes(val["Name"])}
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                    onChange={() => {
                                      const checked = values.userRoles.includes(
                                        val["Name"]
                                      );

                                      checked === false
                                        ? setFieldValue("userRoles", [
                                            ...values.userRoles,
                                            val["Name"],
                                          ])
                                        : setFieldValue(
                                            "userRoles",
                                            values.userRoles.filter(
                                              (sc) => sc !== val["Name"]
                                            )
                                          );
                                    }}
                                  />
                                }
                                label={val["Name"]}
                                labelPlacement="end"
                              />
                            </React.Fragment>
                          ))}

                        {meta.touched && meta.error && (
                          <span className={classes.error}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "left",
                    flexDirection: "column",
                  }}
                >
                  <Controls.SubmitButton
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                  />
                  {message && (
                    <Typography variant="caption" className={classes.error}>
                      {message.includes("400")
                        ? "Registration failed. Check your inputs"
                        : message}
                    </Typography>
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default RegisterUser;
