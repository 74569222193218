import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useClasses = makeStyles((theme) => ({
  menu: {
    display: "flex",
    background: "green",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    alignContent: "center",
    "& a": {
      textDecoration: "none",
      margin: theme.spacing(2),
      color: "white"
    },
  },
}));

function NavMenu(props) {
  const classes = useClasses();
  const { isLoggedIn, isAdmin } = props;

  return (
    <div className={classes.menu}>
      <Grid item>
        <Typography color="inherit" variant="subtitle2">
          <a href="/home">Home</a>
        </Typography>
      </Grid>
      {isLoggedIn ? (
        <>
          {isAdmin && (
            <>
              <Grid item>
                <Typography color="inherit" variant="subtitle2">
                  <a href="/manage-workers">Manage workers</a>
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="inherit" variant="subtitle2">
                  <a href="/manage-organizations">Manage organizations</a>
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="inherit" variant="subtitle2">
                  <a href="/manage-users">Manage users</a>
                </Typography>
              </Grid>
            </>
          )}
        </>
      ) : null}

      {/* <List component="nav">
            <ListItem component="div">
              <ListItemText inset>
                <Typography color="inherit" variant="caption">
                  <Link to={"/home"} className={classes.menuLink}>
                    Home
                  </Link>
                </Typography>
              </ListItemText>

              {showAdminBoard ? (
                <React.Fragment>
                  <ListItemText inset>
                    <Typography color="inherit" variant="caption">
                      <Link
                        to={"/register-worker"}
                        className={classes.menuLink}
                        onClick={async () => {
                          await dispatch(chkTokenIsValid());
                        }}
                      >
                        Register Worker
                      </Link>
                    </Typography>
                  </ListItemText>

                  <ListItemText inset>
                    <Typography color="inherit" variant="caption">
                      <Link
                        to={"/register-organization"}
                        className={classes.menuLink}
                        onClick={async () => {
                          await dispatch(chkTokenIsValid());
                        }}
                      >
                        Register Organization
                      </Link>
                    </Typography>
                  </ListItemText>
                </React.Fragment>
              ) : null}

              {currentUser ? (
                <ListItemText inset>
                  <Typography color="inherit" variant="caption">
                    <Link
                      to={"/user"}
                      className={classes.menuLink}
                      onClick={async () => {
                        await dispatch(chkTokenIsValid());
                      }}
                    >
                      Users
                    </Link>
                  </Typography>
                </ListItemText>
              ) : null}

              {currentUser ? (
                <>
                  <ListItemText inset>
                    <Typography color="inherit" variant="caption">
                      <Link
                        to={"/profile"}
                        className={classes.menuLink}
                        onClick={async () => {
                          await dispatch(chkTokenIsValid());
                        }}
                      >
                        {currentUser.userName}
                      </Link>
                    </Typography>
                    <IconButton
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                  </ListItemText>
                </>
              ) : (
                <>
                  <ListItemText inset>
                    <Typography color="inherit" variant="caption">
                      <Link to={"/login"} className={classes.menuLinkLogin}>
                        Login
                      </Link>
                    </Typography>
                  </ListItemText>
                </>
              )}
            </ListItem>
          </List> */}
    </div>
  );
}

export default NavMenu;
