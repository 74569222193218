export const districts = [
    "MAAIF",
    "Abim",
    "Adjumani",
    "Agago",
    "Alebtong",
    "Amolatar",
    "Amudat",
    "Amuria",
    "Amuru",
    "Apac",
    "Arua",
    "Budaka",
    "Bududa",
    "Bugiri",
    "Bugweri",
    "Buhweju",
    "Buikwe",
    "Bukedea",
    "Bukomansimbi",
    "Bukwa",
    "Bulambuli",
    "Buliisa",
    "Bundibugyo",
    "Bunyangabu",
    "Bushenyi",
    "Busia",
    "Butaleja",
    "Butambala",
    "Butebo",
    "Buvuma",
    "Buyende",
    "Dokolo",
    "Gomba",
    "Gulu",
    "Hoima",
    "Ibanda",
    "Iganga",
    "Isingiro",
    "Jinja",
    "Kaabong",
    "Kabale",
    "Kabarole",
    "Kaberamaido",
    "Kagadi",
    "Kakumiro",
    "Kalangala",
    "Kaliro",
    "Kalungu",
    "Kampala",
    "Kamuli",
    "Kamwenge",
    "Kanungu",
    "Kapchorwa",
    "Kapelebyong",
    "Karenga",
    "Kasanda",
    "Kasese",
    "Katakwi",
    "Kayunga",
    "Kazo",
    "Kibaale",
    "Kiboga",
    "Kibuku",
    "Kibuube",
    "Kiruhura",
    "Kiryandongo",
    "Kisoro",
    "Kitagwenda",
    "Kitgum",
    "Koboko",
    "Kole",
    "Kotido",
    "Kumi",
    "Kwania",
    "Kween",
    "Kyankwanzi",
    "Kyegegwa",
    "Kyenjojo",
    "Kyotera",
    "Lamwo",
    "Lira",
    "Lusot",
    "Luuka",
    "Luwero",
    "Lwengo",
    "Lyantonde",
    "Madi - Okollo",
    "Manafwa",
    "Maracha",
    "Masaka",
    "Masindi",
    "Mayuge",
    "Mbale",
    "Mbarara",
    "Mitooma",
    "Mityana",
    "Moroto",
    "Moyo",
    "Mpigi",
    "Mubende",
    "Mukono",
    "Nabilatuk",
    "Nakapiripirit",
    "Nakaseke",
    "Nakasongola",
    "Namayingo",
    "Namisindwa",
    "Namutumba",
    "Napak",
    "Nebbi",
    "Ngora",
    "Ntoroko",
    "Ntungamo",
    "Nwoya",
    "Obongi",
    "Omoro",
    "Otuke",
    "Oyam",
    "Pader",
    "Pakwach",
    "Pallisa",
    "Rakai",
    "Rubanda",
    "Rubirizi",
    "Rukiga",
    "Rukungiri",
    "Rwampara",
    "Sembabule",
    "Serere",
    "Sheema",
    "Sironko",
    "Soroti",
    "Tororo",
    "Wakiso",
    "Yumbe",
    "Zombo"

]

export const centralDistrictsOptions = [
    "All",
    "Buikwe",
    "Bukomansimbi",
    "Butambala",
    "Buvuma",
    "Gomba",
    "Kalangala",
    "Kalungu",
    "Kampala",
    "Kasanda",
    "Kayunga",
    "Kiboga",
    "Kyankwanzi",
    "Kyotera",
    "Luweero",
    "Lwengo",
    "Lyantonde",
    "Masaka",
    "Mityana",
    "Mpigi",
    "Mubende",
    "Mukono",
    "Nakaseke",
    "Nakasongola",
    "Rakai",
    "Sembabule",
    "Wakiso"
]

export const easternDistrictsOptions = [
    "All",
    "Amuria",
    "Budaka",
    "Bududa",
    "Bugiri",
    "Bugweri",
    "Bukedea",
    "Bukwa",
    "Bulambuli",
    "Busia",
    "Butaleja",
    "Butebo",
    "Buyende",
    "Iganga",
    "Jinja",
    "Kaberamaido",
    "Kaliro",
    "Kamuli",
    "Kapchorwa",
    "Kapelebyong",
    "Katakwi",
    "Kibuku",
    "Kumi",
    "Kween",
    "Luuka",
    "Manafwa",
    "Mayuge",
    "Mbale",
    "Namayingo",
    "Namisindwa",
    "Namutumba",
    "Ngora",
    "Pallisa",
    "Serere",
    "Sironko",
    "Soroti",
    "Tororo"
]

export const westernDistrictsOptions = [
    "All",
    "Buhweju",
    "Buliisa",
    "Bundibugyo",
    "Bunyangabu",
    "Bushenyi",
    "Hoima",
    "Ibanda",
    "Isingiro",
    "Kabale",
    "Kabarole",
    "Kagadi",
    "Kakumiro",
    "Kamwenge",
    "Kanungu",
    "Kasese",
    "Kazo",
    "Kibaale",
    "Kibuube",
    "Kiruhura",
    "Kiryandongo",
    "Kisoro",
    "Kitagwenda",
    "Kyegegwa",
    "Kyenjojo",
    "Masindi",
    "Mbarara",
    "Mitooma",
    "Ntoroko",
    "Ntungamo",
    "Rubanda",
    "Rubirizi",
    "Rukiga",
    "Rukungiri",
    "Rwampara",
    "Sheema"
]

export const northernDistrictsOptions = [
    "All",
    "Abim",
    "Adjumani",
    "Agago",
    "Alebtong",
    "Amolatar",
    "Amudat",
    "Amuru",
    "Apac",
    "Arua",
    "Dokolo",
    "Gulu",
    "Kaabong",
    "Karenga",
    "Kitgum",
    "Koboko",
    "Kole",
    "Kotido",
    "Kwania",
    "Lamwo",
    "Lira",
    "Lusot",
    "Madi - Okollo",
    "Maracha",
    "Moroto",
    "Moyo",
    "Nabilatuk",
    "Nakapiripirit",
    "Napak",
    "Nebbi",
    "Nwoya",
    "Obongi",
    "Omoro",
    "Otuke",
    "Oyam",
    "Pader",
    "Pakwach",
    "Terego",
    "Yumbe",
    "Zombo"
]