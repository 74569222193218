import { Grid, makeStyles } from "@material-ui/core";
import { AccountBox } from "@material-ui/icons";
import React from "react";
import { useTable } from ".././useTable";
import CollapsableSection from "../../components/CollapsableSection";
import GuestSectionHeader from "../GuestSectionHeader";
import useCollapse from "react-collapsed";

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(2),
  },
}));



export default function WorkerProfileForGuest(props) {
  const { profile } = props;
  const { SectionLongLabelValueRow, SectionLabelValueMultiRow } = useTable();
  const classes = useStyles();
  const biodata = [
    { label: "AEAS ID", value: profile.PUID },
    { label: "Name", value: profile.FirstName + " " + profile.LastName },
    { label: "Category", value: profile.Category },
    { label: "Email", value: profile.Email },
    { label: "NIN", value: profile.Nin },
    { label: "Telephone", value: profile.Telephone1 },
    { label: "Gender", value: profile.Sex },
  ];

  const contactInfo = [
    { label: "Telephone1", value: profile.Telephone1 },
    { label: "Telephone2", value: profile.Telephone2 },
    { label: "Email", value: profile.Email },
    {
      label: "District of registration",
      value: profile.DistrictOfRegistration,
    },
  ];

  const Qualifications = [
    {
      label: "Field of agriculture-related qualification",
      value: profile.AgricRelQual,
    },
    profile.OtherHighestQual !== "" && {
      label: "Highest qualification",
      value: profile.highestQual + " " + profile.OtherHighestQual,
    },
    { label: "Year of qualification", value: profile.YearOfQual },
    profile.OtherMainSuppSkillsTrain !== "" && {
      label: "Other main support skill training",
      value: profile.OtherMainSuppSkillsTrain,
    },
    profile.SupportSkillA !== "" && {
      label: "Support skill A",
      value: profile.SupportSkillA,
    },
    profile.QualSupportSkillA !== "" && {
      label: "Qualification suppor skill A",
      value: profile.QualSupportSkillA,
    },
    profile.DurationSkillA !== "" && {
      label: "Duration skill A",
      value: profile.DurationSkillA,
    },
    profile.SupportSkillB !== "" && {
      label: "Support skill B",
      value: profile.SupportSkillB,
    },
    profile.QualSupportSkillB !== "" && {
      label: "Qualification suppor skill B",
      value: profile.QualSupportSkillB,
    },
    profile.DurationSkillB !== "" && {
      label: "Duration skill B",
      value: profile.DurationSkillB,
    },
    profile.SupportSkillC !== "" && {
      label: "Support skill C",
      value: profile.SupportSkillC,
    },
    profile.QualSupportSkillC !== "" && {
      label: "Qualification suppor skill C",
      value: profile.QualSupportSkillC,
    },
    profile.DurationSkillC !== "" && {
      label: "Duration skill C",
      value: profile.DurationSkillC,
    },
  ];

  const workInfo = [
    { label: "Work status", value: profile.WorkStatus },
    { label: "Work place organization/ firm", value: profile.WrkplaceOrg },
    { label: "Position", value: profile.Position },
    {
      label: "Sector of the organization/ firm",
      value: profile.SectorOfOrganization,
    },
    profile.LevOfOpnInPubSec !== "" && {
      label: "Level of operation in public sector",
      value: profile.LevOfOpnInPubSec,
    },
    { label: "Your agricultural focus", value: profile.AgricFocus },
    {
      label: "Value chain focus level",
      value: profile.AgricFocusValChainFocLev,
    },
    { label: "Focus enterprise", value: profile.AgricFocusEnterp },
    {
      label: "Area of operations (Regions)",
      value: profile.AreaOfOpsTargetDistricts,
    },
    profile.CentralDistricts !== "" && {
      label: "Central districts",
      value: profile.CentralDistricts,
    },
    profile.EastDistricts !== "" && {
      label: "East districts",
      value: profile.EastDistricts,
    },
    profile.WestDistricts !== "" && {
      label: "Western districts",
      value: profile.WestDistricts,
    },
    profile.NorthDistricts !== "" && {
      label: "Northern districts",
      value: profile.NorthDistricts,
    },
    { label: "Key beneficiaries", value: profile.KeyBenef },
    profile.KeyBenefFarmersCat !== "" && {
      label: "If farmers, which category",
      value: profile.KeyBenefFarmersCat,
    },
    profile.KeyBenefFarmersGender !== "" && {
      label: "If farmers, which gender",
      value: profile.KeyBenefFarmersGender,
    },
  ];

  const capacityNeeds = [
    {
      label: "Capacity needs 1st choice",
      value: profile.CapacityNeedFirstChoice,
    },
    {
      label: "Capacity needs 2nd choice",
      value: profile.CapacityNeedSecondChoice,
    },
    {
      label: "Capacity needs 3rd choice",
      value: profile.CapacityNeedThirdChoice,
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12} className={classes.section}>
          {/* Profile */}
          {/*<GuestSectionHeader title="Personal Details" />*/}
          <h1>Personal Details</h1>
          <Grid container>
            <SectionLabelValueMultiRow data={biodata} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} className={classes.section}>
          <CollapsableSection title="Contact information" defaultExpanded="true" icon="fa-solid fa-message">
            <SectionLabelValueMultiRow data={contactInfo} />
            <br/><br/>
          </CollapsableSection>
        </Grid>
        <Grid item xs={12} md={12} className={classes.section}>
          <CollapsableSection title="Qualifications" defaultExpanded="true" icon="fa-solid fa-graduation-cap">
            <SectionLongLabelValueRow data={Qualifications} />
            <br/><br/>
          </CollapsableSection>
        </Grid>
        <Grid item xs={12} md={12} className={classes.section}>
          <CollapsableSection title="Work information" defaultExpanded="true" icon="fa-solid fa-briefcase">
            <SectionLongLabelValueRow data={workInfo} />
            <br/><br/>
          </CollapsableSection>
        </Grid>
        <Grid item xs={12} md={12} className={classes.section}>
          <CollapsableSection title="Capacity needs" defaultExpanded="true" icon="fa-solid fa-file-lines">
            <SectionLabelValueMultiRow data={capacityNeeds} />
            <br/><br/>
          </CollapsableSection>
        </Grid>
      </Grid>
    </>
  );
}

// const content = (props) => {
//   return <>
//     {
//       props.data.map((item, index) => )
//         <>
//           <div>eee</div>
//         </>
//     }
//
//   </>
// }


