import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";
import { organizationRegistration } from "../actions/user";

import { Field, Form, Formik, FormikConfig, FormikValues } from "formik";
import { TextField } from "formik-material-ui";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import * as Yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
  TextField as TField,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { countries } from "../data/countries";
import { capacityNeedsOptions, sectorOptions, agricSectorFocusOptions, agricFocusValChainFocLevOptions, agricFocusEnterpOptions, areaOpnTrgtDistrictsOptions, keyBenefOptions, keyBenefFarmersCatOptions, keyBenefFarmersGenderOptions } from "../utils/options";
import { centralDistrictsOptions, districts, easternDistrictsOptions, northernDistrictsOptions, westernDistrictsOptions } from "../data/districts";
import PageHeader from "../components/PageHeader";
import { ArrowBackTwoTone } from "@material-ui/icons";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  error: {
    color: "red"
  }
});

export default function RegisterOrganization() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => { }, []);
  return (
    <>
      <PageHeader
        icon={<IconButton> <a href="\manage-organizations"> <ArrowBackTwoTone /> </a> </IconButton>}
        title="Register organization"
        subtitle="Add a new organization"
      />

      <Grid container>
        <Grid md={12} xs={12} item>
          <>
            <div>
              <Grid container>
                <Grid md={2} xs={12} item></Grid>
                <Grid md={8} xs={12} item>
                  <FormikStepper
                    initialValues={{
                      name: "",
                      typeOfReg: "",
                      dateOfIncorp: "",
                      regNoEntity: "",
                      email: "",
                      country: "",
                      telephone: "",
                      website: "",
                      postalAddress: "",
                      headOfficeDistrict: "",
                      headOfficeDiv: "",
                      headOfficeVillage: "",
                      headOfficeStreet: "",
                      contName: "",
                      contPosition: "",
                      contTelephone: "",
                      contEmail: "",
                      numStaffMale: "",
                      numStaffFemale: "",
                      phdMales: "",
                      phdFemales: "",
                      mastrsMales: "",
                      mastrsFemales: "",
                      bscMale: "",
                      bscFemale: "",
                      dipMale: "",
                      dipFemale: "",
                      certMale: "",
                      certFemale: "",
                      sector: "",
                      agricSectorFocus: "",
                      focusValueChain: [],
                      focusEnterprises: [],
                      mission: "",
                      areaOpnTrgtDistricts: [],
                      centralDistricts: [],
                      eastDistricts: [],
                      westDistricts: [],
                      northDistricts: [],
                      keyBenef: [],
                      keyBeneFrmrsCateg: [],
                      keyBenefrmrsGender: [],
                      capacityNeedFirstChoice: "",
                      capacityNeedSecondChoice: "",
                      capacityNeedThirdChoice: ""
                    }}
                    onSubmit={async values => {
                      // alert(JSON.stringify(values));
                      await dispatch(organizationRegistration(values))
                        .then(() => { })
                        .catch(() => { });
                    }}
                  >
                    <FormikStep
                      label="About organization"
                      validationSchema={Yup.object({
                        name: Yup.string()
                          .min(2, "Too Short!")
                          .required("Required"),
                        typeOfReg: Yup.string()
                          .nullable()
                          .min(2, "Too Short!")
                          .required("Required"),
                        dateOfIncorp: Yup.date()
                          .max(
                            new Date(),
                            "Date of incorporation must be a past date"
                          )
                          .required("Required"),
                        regNoEntity: Yup.string()
                          .required("Required")
                          .min(2, "Too short!"),
                        telephone: Yup.string().required("Required"),
                        email: Yup.string()
                          .email("Invalid email")
                          .required("Required"),
                        website: Yup.string(),
                        postalAddress: Yup.string(),
                        country: Yup.string().required("Required")
                      })}
                    >
                      <Box paddingBottom={2}>
                        <Field
                          fullWidth
                          size="small"
                          name="name"
                          component={TextField}
                          variant="outlined"
                          label="Name of Organisation"
                        />
                      </Box>
                      <Box paddingBottom={2}>
                        <Field fullWidth name="typeOfReg">
                          {({
                            meta,
                            form: { setFieldValue, values }
                          }) => (
                            <FormControl variant="outlined" fullWidth>
                              <Autocomplete
                                size="small"
                                options={["Company", "NGO", "CBO"]}
                                getOptionLabel={option => option}
                                filterSelectedOptions
                                freeSolo
                                value={values.typeOfReg}
                                onChange={(e: object, val: any) => {
                                  setFieldValue("typeOfReg", val);
                                }}
                                renderInput={params => (
                                  <TField
                                    {...params}
                                    variant="outlined"
                                    label="Type of Registration"
                                    placeholder="Type of Registration"
                                  />
                                )}
                              />
                              {meta.touched && meta.error && (
                                <span className={classes.error}>{meta.error}</span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                        {/* <Field
                          name="typeOfReg"
                          options={["Company", "NGO", "CBO"].map(option => {
                            return { value: option, label: option };
                          })}
                          component={MUFAutocomplete}
                          size="small"
                          freeSolo
                          autoSelect
                          textFieldProps={{
                            label: "Type of Registration",
                            variant: "outlined"
                          }}
                        /> */}
                      </Box>
                      <Box paddingBottom={2}>
                        <Field
                          variant="outlined"
                          fullWidth
                          size="small"
                          component={KeyboardDatePicker}
                          label="Date of Incorporation/Registration"
                          name="dateOfIncorp"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          InputAdornmentProps={{ position: "start" }}
                        />
                      </Box>
                      <Box paddingBottom={2}>
                        <Field
                          fullWidth
                          size="small"
                          name="regNoEntity"
                          component={TextField}
                          variant="outlined"
                          label="Registration No. of Entity"
                        />
                      </Box>
                      <Box paddingBottom={2}>
                        <Field
                          fullWidth
                          size="small"
                          name="email"
                          component={TextField}
                          variant="outlined"
                          label="E-mail Address"
                          required={true}
                        />
                      </Box>
                      <Box paddingBottom={2}>
                        <Field fullWidth name="country">
                          {({
                            meta,
                            form: { setFieldValue, values }
                          }) => (
                            <FormControl variant="outlined" fullWidth>
                              <Autocomplete
                                size="small"
                                options={countries.map(option => option.name)}
                                getOptionLabel={option => option}
                                value={values.country}
                                filterSelectedOptions
                                onChange={(e: object, val: any) => {
                                  setFieldValue("country", val);
                                }}
                                renderInput={params => (
                                  <TField
                                    {...params}
                                    variant="outlined"
                                    label="Country"
                                    placeholder="Country"
                                  />
                                )}
                              />
                              {meta.touched && meta.error && (
                                <span className={classes.error}>{meta.error}</span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      <Box paddingBottom={2}>
                        <Field
                          fullWidth
                          size="small"
                          name="telephone"
                          component={TextField}
                          variant="outlined"
                          label="Telephone"
                          required={true}
                        />
                      </Box>
                      <Box paddingBottom={2}>
                        <Field
                          fullWidth
                          size="small"
                          name="website"
                          component={TextField}
                          variant="outlined"
                          label="Website (if any)"
                        />
                      </Box>
                      <Box paddingBottom={2}>
                        <Field
                          fullWidth
                          size="small"
                          name="postalAddress"
                          component={TextField}
                          variant="outlined"
                          label="Postal Address"
                        />
                      </Box>
                    </FormikStep>
                    <FormikStep
                      label="Head Office Location"
                      validationSchema={Yup.object({
                        headOfficeDistrict: Yup.string()
                          .min(2, "Too Short!")
                          .required("Required"),
                        headOfficeDiv: Yup.string()
                          .min(2, "Too Short!")
                          .required("Required"),
                        headOfficeVillage: Yup.string(),
                        headOfficeStreet: Yup.string()
                      })}
                    >
                      <Box paddingBottom={2}>
                        <Field fullWidth name="headOfficeDistrict">
                          {({
                            meta,
                            form: { setFieldValue, values }
                          }) => (
                            <FormControl variant="outlined" fullWidth>
                              <Autocomplete
                                size="small"
                                options={districts.map(option => option)}
                                getOptionLabel={option => option}
                                value={values.headOfficeDistrict}
                                filterSelectedOptions
                                onChange={(e: object, val: any) => {
                                  setFieldValue("headOfficeDistrict", val);
                                }}
                                renderInput={params => (
                                  <TField
                                    {...params}
                                    variant="outlined"
                                    label="District"
                                    placeholder="District"
                                  />
                                )}
                              />
                              {meta.touched && meta.error && (
                                <span className={classes.error}>{meta.error}</span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                        {/* <Field
                          name="headOfficeDistrict"
                          options={["Company", "NGO", "CBO"].map(option => {
                            return { value: option, label: option };
                          })}
                          component={MUFAutocomplete}
                          size="small"
                          textFieldProps={{
                            label: "District",
                            variant: "outlined"
                          }}
                        /> */}
                      </Box>
                      <Box paddingBottom={2}>
                        <Field
                          fullWidth
                          size="small"
                          name="headOfficeDiv"
                          component={TextField}
                          variant="outlined"
                          label="Sub county/Division"
                        />
                      </Box>
                      <Box paddingBottom={2}>
                        <Field
                          fullWidth
                          size="small"
                          name="headOfficeVillage"
                          component={TextField}
                          variant="outlined"
                          label="Village"
                        />
                      </Box>
                      <Box paddingBottom={2}>
                        <Field
                          fullWidth
                          size="small"
                          name="headOfficeStreet"
                          component={TextField}
                          variant="outlined"
                          label="Street"
                        />
                      </Box>
                    </FormikStep>
                    <FormikStep
                      label="Contact Person"
                      validationSchema={Yup.object({
                        contName: Yup.string()
                          .min(2, "Too Short!")
                          .required("Required"),
                        contPosition: Yup.string()
                          .min(2, "Too Short!")
                          .required("Required"),
                        contTelephone: Yup.string().required("Required"),
                        contEmail: Yup.string().email()
                      })}
                    >
                      <Box paddingBottom={2}>
                        <Field
                          fullWidth
                          size="small"
                          name="contName"
                          component={TextField}
                          variant="outlined"
                          label="Contact Name"
                        />
                      </Box>
                      <Box paddingBottom={2}>
                        <Field
                          fullWidth
                          size="small"
                          name="contPosition"
                          component={TextField}
                          variant="outlined"
                          label="Position"
                        />
                      </Box>
                      <Box paddingBottom={2}>
                        <Field
                          fullWidth
                          size="small"
                          name="contTelephone"
                          component={TextField}
                          variant="outlined"
                          label="Contact Telephone"
                        />
                      </Box>
                      <Box paddingBottom={2}>
                        <Field
                          fullWidth
                          size="small"
                          name="contEmail"
                          component={TextField}
                          variant="outlined"
                          label="Email"
                        />
                      </Box>
                    </FormikStep>

                    <FormikStep
                      label="AEAS staff"
                      validationSchema={Yup.object({
                        numStaffMale: Yup.number()
                          .required("Required")
                          .min(0, "Number should be greater or equal to zero"),
                        numStaffFemale: Yup.number()
                          .required("Required")
                          .min(0, "Number should be greater or equal to zero"),
                        phdMales: Yup.number()
                          .required("Required")
                          .min(0, "Number should be greater or equal to zero")
                          .test({
                            exclusive: false,
                            message: "Total Male staff exceeded",
                            test: function (value) {
                              let phd = value;
                              let mstr = this.parent.mastrsMales;
                              let bsc = this.parent.bscMale;
                              let dip = this.parent.dipMale;
                              let cert = this.parent.certMale;
                              phd = isNaN(phd) ? 0 : parseInt(phd);
                              mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                              bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                              dip = isNaN(dip) ? 0 : parseInt(dip);
                              cert = isNaN(cert) ? 0 : parseInt(cert);
                              if (
                                phd + mstr + bsc + dip + cert <=
                                parseInt(this.parent.numStaffMale)
                              )
                                return true;
                              else return false;
                            }
                          }),
                        phdFemales: Yup.number()
                          .required("Required")
                          .min(0, "Number should be greater or equal to zero")
                          .test({
                            exclusive: false,
                            message: "Total Female staff exceeded",
                            test: function (value) {
                              let phd = value;
                              let mstr = this.parent.mastrsFemales;
                              let bsc = this.parent.bscFemale;
                              let dip = this.parent.dipFemale;
                              let cert = this.parent.certFemale;
                              phd = isNaN(phd) ? 0 : parseInt(phd);
                              mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                              bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                              dip = isNaN(dip) ? 0 : parseInt(dip);
                              cert = isNaN(cert) ? 0 : parseInt(cert);
                              if (
                                phd + mstr + bsc + dip + cert <=
                                parseInt(this.parent.numStaffFemale)
                              )
                                return true;
                              else return false;
                            }
                          }),
                        mastrsMales: Yup.number()
                          .required("Required")
                          .min(0, "Number should be greater or equal to zero")
                          .test({
                            exclusive: false,
                            message: "Total Male staff exceeded",
                            test: function (value) {
                              let phd = this.parent.phdMales;
                              let mstr = value;
                              let bsc = this.parent.bscMale;
                              let dip = this.parent.dipMale;
                              let cert = this.parent.certMale;
                              phd = isNaN(phd) ? 0 : parseInt(phd);
                              mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                              bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                              dip = isNaN(dip) ? 0 : parseInt(dip);
                              cert = isNaN(cert) ? 0 : parseInt(cert);
                              if (
                                phd + mstr + bsc + dip + cert <=
                                parseInt(this.parent.numStaffMale)
                              )
                                return true;
                              else return false;
                            }
                          }),
                        mastrsFemales: Yup.number()
                          .required("Required")
                          .min(0, "Number should be greater or equal to zero")
                          .test({
                            exclusive: false,
                            message: "Total Female staff exceeded",
                            test: function (value) {
                              let phd = this.parent.phdFemales;
                              let mstr = value;
                              let bsc = this.parent.bscFemale;
                              let dip = this.parent.dipFemale;
                              let cert = this.parent.certFemale;
                              phd = isNaN(phd) ? 0 : parseInt(phd);
                              mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                              bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                              dip = isNaN(dip) ? 0 : parseInt(dip);
                              cert = isNaN(cert) ? 0 : parseInt(cert);
                              if (
                                phd + mstr + bsc + dip + cert <=
                                parseInt(this.parent.numStaffFemale)
                              )
                                return true;
                              else return false;
                            }
                          }),
                        bscMale: Yup.number()
                          .required("Required")
                          .min(0, "Number should be greater or equal to zero")
                          .test({
                            exclusive: false,
                            message: "Total Male staff exceeded",
                            test: function (value) {
                              let phd = this.parent.phdMales;
                              let mstr = this.parent.mastrsMales;
                              let bsc = value;
                              let dip = this.parent.dipMale;
                              let cert = this.parent.certMale;
                              phd = isNaN(phd) ? 0 : parseInt(phd);
                              mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                              bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                              dip = isNaN(dip) ? 0 : parseInt(dip);
                              cert = isNaN(cert) ? 0 : parseInt(cert);
                              if (
                                phd + mstr + bsc + dip + cert <=
                                parseInt(this.parent.numStaffMale)
                              )
                                return true;
                              else return false;
                            }
                          }),
                        bscFemale: Yup.number()
                          .required("Required")
                          .min(0, "Number should be greater or equal to zero")
                          .test({
                            exclusive: false,
                            message: "Total Female staff exceeded",
                            test: function (value) {
                              let phd = this.parent.phdFemales;
                              let mstr = this.parent.mastrsFemales;
                              let bsc = value;
                              let dip = this.parent.dipFemale;
                              let cert = this.parent.certFemale;
                              phd = isNaN(phd) ? 0 : parseInt(phd);
                              mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                              bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                              dip = isNaN(dip) ? 0 : parseInt(dip);
                              cert = isNaN(cert) ? 0 : parseInt(cert);
                              if (
                                phd + mstr + bsc + dip + cert <=
                                parseInt(this.parent.numStaffFemale)
                              )
                                return true;
                              else return false;
                            }
                          }),
                        dipMale: Yup.number()
                          .required("Required")
                          .min(0, "Number should be greater or equal to zero")
                          .test({
                            exclusive: false,
                            message: "Total Male staff exceeded",
                            test: function (value) {
                              let phd = this.parent.phdMales;
                              let mstr = this.parent.mastrsMales;
                              let bsc = this.parent.bscMale;
                              let dip = value;
                              let cert = this.parent.certMale;
                              phd = isNaN(phd) ? 0 : parseInt(phd);
                              mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                              bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                              dip = isNaN(dip) ? 0 : parseInt(dip);
                              cert = isNaN(cert) ? 0 : parseInt(cert);
                              if (
                                phd + mstr + bsc + dip + cert <=
                                parseInt(this.parent.numStaffMale)
                              )
                                return true;
                              else return false;
                            }
                          }),
                        dipFemale: Yup.number()
                          .required("Required")
                          .min(0, "Number should be greater or equal to zero")
                          .test({
                            exclusive: false,
                            message: "Total Female staff exceeded",
                            test: function (value) {
                              let phd = this.parent.phdFemales;
                              let mstr = this.parent.mastrsFemales;
                              let bsc = this.parent.bscFemale;
                              let dip = value;
                              let cert = this.parent.certFemale;
                              phd = isNaN(phd) ? 0 : parseInt(phd);
                              mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                              bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                              dip = isNaN(dip) ? 0 : parseInt(dip);
                              cert = isNaN(cert) ? 0 : parseInt(cert);
                              if (
                                phd + mstr + bsc + dip + cert <=
                                parseInt(this.parent.numStaffFemale)
                              )
                                return true;
                              else return false;
                            }
                          }),
                        certMale: Yup.number()
                          .required("Required")
                          .min(0, "Number should be greater or equal to zero")
                          .test({
                            exclusive: false,
                            message: "Total Male staff exceeded",
                            test: function (value) {
                              let phd = this.parent.phdMales;
                              let mstr = this.parent.mastrsMales;
                              let bsc = this.parent.bscMale;
                              let dip = this.parent.dipMale;
                              let cert = value;
                              phd = isNaN(phd) ? 0 : parseInt(phd);
                              mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                              bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                              dip = isNaN(dip) ? 0 : parseInt(dip);
                              cert = isNaN(cert) ? 0 : parseInt(cert);
                              if (
                                phd + mstr + bsc + dip + cert <=
                                parseInt(this.parent.numStaffMale)
                              )
                                return true;
                              else return false;
                            }
                          }),
                        certFemale: Yup.number()
                          .required("Required")
                          .min(0, "Number should be greater or equal to zero")
                          .test({
                            exclusive: false,
                            message: "Total Female staff exceeded",
                            test: function (value) {
                              let phd = this.parent.phdFemales;
                              let mstr = this.parent.mastrsFemales;
                              let bsc = this.parent.bscFemale;
                              let dip = this.parent.dipFemale;
                              let cert = value;
                              phd = isNaN(phd) ? 0 : parseInt(phd);
                              mstr = isNaN(mstr) ? 0 : parseInt(mstr);
                              bsc = isNaN(bsc) ? 0 : parseInt(bsc);
                              dip = isNaN(dip) ? 0 : parseInt(dip);
                              cert = isNaN(cert) ? 0 : parseInt(cert);
                              if (
                                phd + mstr + bsc + dip + cert <=
                                parseInt(this.parent.numStaffFemale)
                              )
                                return true;
                              else return false;
                            }
                          })
                      })}
                    >
                      <Box paddingBottom={2}>
                        <Field
                          fullWidth
                          size="small"
                          name="numStaffMale"
                          type="number"
                          component={TextField}
                          label="Male"
                          variant="outlined"
                        />
                      </Box>

                      <Box paddingBottom={2}>
                        <Field
                          fullWidth
                          size="small"
                          name="numStaffFemale"
                          type="number"
                          component={TextField}
                          label="Female"
                          variant="outlined"
                        />
                      </Box>

                      <Grid container>
                        <Grid item xs={6}>
                          <Box paddingBottom={2}>
                            <Field
                              fullWidth
                              size="small"
                              name="phdMales"
                              type="number"
                              component={TextField}
                              label="Males at PhD level"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box paddingBottom={2}>
                            <Field
                              fullWidth
                              size="small"
                              name="phdFemales"
                              type="number"
                              component={TextField}
                              label="Females at PhD level"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Box paddingBottom={2}>
                            <Field
                              fullWidth
                              size="small"
                              name="mastrsMales"
                              type="number"
                              component={TextField}
                              label="Males at Masters level"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box paddingBottom={2}>
                            <Field
                              fullWidth
                              size="small"
                              name="mastrsFemales"
                              type="number"
                              component={TextField}
                              label="Females at Masters level"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Box paddingBottom={2}>
                            <Field
                              fullWidth
                              size="small"
                              name="bscMale"
                              type="number"
                              component={TextField}
                              label="Males at Bsc level"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box paddingBottom={2}>
                            <Field
                              fullWidth
                              size="small"
                              name="bscFemale"
                              type="number"
                              component={TextField}
                              label="Females at Bsc level"
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Box paddingBottom={2}>
                            <Field
                              fullWidth
                              name="dipMale"
                              type="number"
                              component={TextField}
                              label="Males at Diploma level"
                              variant="outlined"
                              size="small"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box paddingBottom={2}>
                            <Field
                              fullWidth
                              name="dipFemale"
                              type="number"
                              component={TextField}
                              label="Females at Diploma level"
                              variant="outlined"
                              size="small"
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Box paddingBottom={2}>
                            <Field
                              fullWidth
                              name="certMale"
                              type="number"
                              component={TextField}
                              label="Males at Certificate level"
                              variant="outlined"
                              size="small"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box paddingBottom={2}>
                            <Field
                              fullWidth
                              name="certFemale"
                              type="number"
                              component={TextField}
                              label="Females at Certificate level"
                              variant="outlined"
                              size="small"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </FormikStep>

                    <FormikStep
                      label="Sector Info"
                      validationSchema={Yup.object({
                        sector: Yup.string().required("Required"),
                        agricSectorFocus: Yup.string().required("Required"),
                        focusValueChain: Yup.string(),
                        focusEnterprises: Yup.string(),
                        mission: Yup.string("Required"),
                        areaOpnTrgtDistricts: Yup.string().required("Required"),
                        centralDistricts: Yup.string(),
                        eastDistricts: Yup.string(),
                        westDistricts: Yup.string(),
                        northDistricts: Yup.string(),
                        keyBenef: Yup.string(),
                        keyBeneFrmrsCateg: Yup.string(),
                        keyBenefFarmersGender: Yup.string()
                      })}
                    >
                      <Box paddingBottom={2}>

                        <Field fullWidth name="sector">
                          {({
                            meta,
                            form: { setFieldValue, values }
                          }) => (
                            <FormControl variant="outlined" fullWidth>
                              <Autocomplete
                                size="small"
                                options={sectorOptions.map(option => option)}
                                getOptionLabel={option => option}
                                value={values.sector}
                                filterSelectedOptions
                                onChange={(e: object, val: any) => {
                                  setFieldValue("sector", val);
                                }}
                                renderInput={params => (
                                  <TField
                                    {...params}
                                    variant="outlined"
                                    label="Sector"
                                    placeholder="Sector"
                                  />
                                )}
                              />
                              {meta.touched && meta.error && (
                                <span className={classes.error}>{meta.error}</span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      <Box paddingBottom={2}>

                        <Field fullWidth name="agricSectorFocus">
                          {({
                            meta,
                            form: { setFieldValue, values }
                          }) => (
                            <FormControl variant="outlined" fullWidth>
                              <Autocomplete
                                size="small"
                                options={agricSectorFocusOptions.map(option => option)}
                                getOptionLabel={option => option}
                                value={values.agricSectorFocus}
                                filterSelectedOptions
                                onChange={(e: object, val: any) => {
                                  setFieldValue("agricSectorFocus", val);
                                }}
                                renderInput={params => (
                                  <TField
                                    {...params}
                                    variant="outlined"
                                    label="Agricultural sector focus"
                                    placeholder="Agricultural sector focus"
                                  />
                                )}
                              />
                              {meta.touched && meta.error && (
                                <span className={classes.error}>{meta.error}</span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </Box>

                      <Box>
                        <Field>
                          {({ form: { values, setFieldValue } }) => (
                            <>
                              {values.agricSectorFocus &&
                                values.agricSectorFocus === "Specific" ? (
                                <>
                                  <Box paddingBottom={2}>
                                    <Field fullWidth name="focusValueChain">
                                      {({ meta }) => (
                                        <FormControl
                                          variant="outlined"
                                          fullWidth
                                        >
                                          <Autocomplete
                                            multiple
                                            size="small"
                                            options={agricFocusValChainFocLevOptions}
                                            getOptionLabel={option => option}
                                            value={values.focusValueChain}
                                            filterSelectedOptions
                                            onChange={(e: object, val: any) => {
                                              setFieldValue("focusValueChain", val);
                                            }}
                                            freeSolo
                                            renderTags={(value, getTagProps) =>
                                              value.map((option, index) => (
                                                <Chip
                                                  variant="outlined"
                                                  label={option}
                                                  {...getTagProps({ index })}
                                                />
                                              ))
                                            }
                                            renderInput={params => (
                                              <TField
                                                {...params}
                                                variant="outlined"
                                                label="Value Chain Focus Level"
                                                placeholder="Focus Area"
                                              />
                                            )}
                                          />
                                          {meta.touched && meta.error && (
                                            <span className={classes.error}>
                                              {meta.error}
                                            </span>
                                          )}
                                        </FormControl>
                                      )}
                                    </Field>
                                  </Box>

                                  <Box paddingBottom={2}>
                                    <Field fullWidth name="focusEnterprises">
                                      {({
                                        meta,
                                        form: { setFieldValue, values }
                                      }) => (
                                        <FormControl
                                          variant="outlined"
                                          fullWidth
                                        >
                                          <Autocomplete
                                            multiple
                                            size="small"
                                            options={agricFocusEnterpOptions}
                                            value={values.focusEnterprises}
                                            getOptionLabel={option => option}
                                            freeSolo
                                            renderTags={(value, getTagProps) =>
                                              value.map((option, index) => (
                                                <Chip
                                                  variant="outlined"
                                                  label={option}
                                                  {...getTagProps({ index })}
                                                />
                                              ))
                                            }
                                            onChange={(e: object, val: any) => {
                                              console.log(val);
                                              setFieldValue(
                                                "focusEnterprises",
                                                val.map(v => v)
                                              );
                                            }}
                                            filterSelectedOptions
                                            renderInput={params => (
                                              <TField
                                                {...params}
                                                variant="outlined"
                                                label="Focus Enterprises"
                                                placeholder="Enterprise"
                                              />
                                            )}
                                          />
                                          {meta.touched && meta.error && (
                                            <span className={classes.error}>
                                              {meta.error}
                                            </span>
                                          )}
                                        </FormControl>
                                      )}
                                    </Field>
                                  </Box>
                                </>
                              ) : null}
                            </>
                          )}
                        </Field>
                      </Box>
                      <Box paddingBottom={2}>
                        <Field
                          fullWidth
                          size="small"
                          name="mission"
                          component={TextField}
                          variant="outlined"
                          label="Mission"
                          multiline
                        />
                      </Box>

                      {/* Target areas of operation */}
                      <Box paddingBottom={2}>
                        <Field fullWidth name="areaOpnTrgtDistricts">
                          {({
                            meta,
                            form: { setFieldValue, values }
                          }) => (
                            <FormControl variant="outlined" fullWidth>
                              <Autocomplete
                                multiple
                                size="small"
                                options={areaOpnTrgtDistrictsOptions}
                                value={values.areaOpnTrgtDistricts}
                                getOptionLabel={option => option}
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      variant="outlined"
                                      label={option}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                onChange={(e: object, val: any) => {
                                  setFieldValue(
                                    "areaOpnTrgtDistricts",
                                    val
                                  );
                                }}
                                filterSelectedOptions
                                renderInput={params => (
                                  <TField
                                    {...params}
                                    variant="outlined"
                                    label="Area of Operation: Target Districts"
                                    placeholder="Region"
                                  />
                                )}
                              />
                              {meta.touched && meta.error && (
                                <span className={classes.error}>{meta.error}</span>
                              )}
                            </FormControl>
                          )}
                        </Field>

                      </Box>
                      {/* Districts in the target areas of operation */}
                      <Box>
                        <Field>
                          {({ form: { values } }) => (
                            <>
                              {/* if Central  */}
                              {(values.areaOpnTrgtDistricts &&
                                values.areaOpnTrgtDistricts.includes("Central")) ||
                                (values.areaOpnTrgtDistricts &&
                                  values.areaOpnTrgtDistricts.includes("All")) ? (
                                <>
                                  <Box paddingBottom={2}>
                                    <Field fullWidth name="centralDistricts">
                                      {({ meta, form: { setFieldValue, values } }) => (
                                        <FormControl variant="outlined" fullWidth>
                                          <Autocomplete
                                            multiple
                                            size="small"
                                            options={centralDistrictsOptions}
                                            value={values.centralDistricts}
                                            getOptionLabel={option => option}
                                            freeSolo
                                            renderTags={(value, getTagProps) =>
                                              value.map((option, index) => (
                                                <Chip
                                                  variant="outlined"
                                                  label={option}
                                                  {...getTagProps({ index })}
                                                />
                                              ))
                                            }
                                            onChange={(e: object, val: any) => {
                                              setFieldValue("centralDistricts", val);
                                            }}
                                            filterSelectedOptions
                                            renderInput={params => (
                                              <TField
                                                {...params}
                                                variant="outlined"
                                                label="Central Districts"
                                                placeholder="Districts"
                                              />
                                            )}
                                          />
                                          {meta.touched && meta.error && (
                                            <span className={classes.error}>{meta.error}</span>
                                          )}
                                        </FormControl>
                                      )}
                                    </Field>
                                  </Box>
                                </>
                              ) : null}

                              {/* if East */}
                              {(values.areaOpnTrgtDistricts &&
                                values.areaOpnTrgtDistricts.includes("East")) ||
                                (values.areaOpnTrgtDistricts &&
                                  values.areaOpnTrgtDistricts.includes("All")) ? (
                                <>
                                  <Box paddingBottom={2}>
                                    <Field fullWidth name="eastDistricts">
                                      {({ meta, form: { setFieldValue, values } }) => (
                                        <FormControl variant="outlined" fullWidth>
                                          <Autocomplete
                                            multiple
                                            size="small"
                                            options={easternDistrictsOptions}
                                            value={values.eastDistricts}
                                            getOptionLabel={option => option}
                                            freeSolo
                                            renderTags={(value, getTagProps) =>
                                              value.map((option, index) => (
                                                <Chip
                                                  variant="outlined"
                                                  label={option}
                                                  {...getTagProps({ index })}
                                                />
                                              ))
                                            }
                                            onChange={(e: object, val: any) => {
                                              setFieldValue("eastDistricts", val);
                                            }}
                                            filterSelectedOptions
                                            renderInput={params => (
                                              <TField
                                                {...params}
                                                variant="outlined"
                                                label="East Districts"
                                                placeholder="Districts"
                                              />
                                            )}
                                          />
                                          {meta.touched && meta.error && (
                                            <span className={classes.error}>
                                              {meta.error}
                                            </span>
                                          )}
                                        </FormControl>
                                      )}
                                    </Field>
                                  </Box>
                                </>
                              ) : null}

                              {/* if West */}
                              {(values.areaOpnTrgtDistricts && values.areaOpnTrgtDistricts.includes("West")) ||
                                (values.areaOpnTrgtDistricts && values.areaOpnTrgtDistricts.includes("All")) ? (
                                <>
                                  <Box paddingBottom={2}>
                                    <Field fullWidth name="westDistricts">
                                      {({ meta, form: { setFieldValue, values } }) => (
                                        <FormControl variant="outlined" fullWidth>
                                          <Autocomplete
                                            multiple
                                            size="small"
                                            options={westernDistrictsOptions}
                                            value={values.westDistricts}
                                            getOptionLabel={option => option}
                                            freeSolo
                                            renderTags={(value, getTagProps) =>
                                              value.map((option, index) => (
                                                <Chip
                                                  variant="outlined"
                                                  label={option}
                                                  {...getTagProps({ index })}
                                                />
                                              ))
                                            }
                                            onChange={(e: object, val: any) => {
                                              setFieldValue("westDistricts", val);
                                            }}
                                            filterSelectedOptions
                                            renderInput={params => (
                                              <TField
                                                {...params}
                                                variant="outlined"
                                                label="Western Districts"
                                                placeholder="Districts"
                                              />
                                            )}
                                          />
                                          {meta.touched && meta.error && (
                                            <span className={classes.error}>
                                              {meta.error}
                                            </span>
                                          )}
                                        </FormControl>
                                      )}
                                    </Field>
                                  </Box>
                                </>
                              ) : null}

                              {/* if North */}
                              {(values.areaOpnTrgtDistricts && values.areaOpnTrgtDistricts.includes("North")) ||
                                (values.areaOpnTrgtDistricts && values.areaOpnTrgtDistricts.includes("All")) ? (
                                <>
                                  <Box paddingBottom={2}>
                                    <Field fullWidth name="northDistricts">
                                      {({ meta, form: { setFieldValue, values } }) => (
                                        <FormControl variant="outlined" fullWidth>
                                          <Autocomplete
                                            multiple
                                            size="small"
                                            options={northernDistrictsOptions}
                                            value={values.northDistricts}
                                            getOptionLabel={option => option}
                                            freeSolo
                                            renderTags={(value, getTagProps) =>
                                              value.map((option, index) => (
                                                <Chip
                                                  variant="outlined"
                                                  label={option}
                                                  {...getTagProps({ index })}
                                                />
                                              ))
                                            }
                                            onChange={(e: object, val: any) => {
                                              setFieldValue("northDistricts", val);
                                            }}
                                            filterSelectedOptions
                                            renderInput={params => (
                                              <TField
                                                {...params}
                                                variant="outlined"
                                                label="Northern Districts"
                                                placeholder="Districts"
                                              />
                                            )}
                                          />
                                          {meta.touched && meta.error && (
                                            <span className={classes.error}>
                                              {meta.error}
                                            </span>
                                          )}
                                        </FormControl>
                                      )}
                                    </Field>
                                  </Box>
                                </>
                              ) : null}
                            </>
                          )}
                        </Field>
                      </Box>
                      <Box paddingBottom={2}>
                        <Field fullWidth name="keyBenef">
                          {({ meta, form: { setFieldValue, values } }) => (
                            <FormControl variant="outlined" fullWidth>
                              <Autocomplete
                                multiple
                                size="small"
                                options={keyBenefOptions}
                                value={values.keyBenef}
                                getOptionLabel={option => option}
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      variant="outlined"
                                      label={option}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                onChange={(e: object, val: any) => {
                                  setFieldValue("keyBenef", val);
                                }}
                                filterSelectedOptions
                                renderInput={params => (
                                  <TField
                                    {...params}
                                    variant="outlined"
                                    label="Key Beneficiaries"
                                    placeholder="Beneficiary"
                                  />
                                )}
                              />
                              {meta.touched && meta.error && (
                                <span className={classes.error}>{meta.error}</span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      <Box>
                        <Field>
                          {({ form: { values } }) => (
                            <>
                              {/* if farmer is key beneficiary  */}
                              {values.keyBenef &&
                                values.keyBenef.includes("Farmers") ? (
                                <>
                                  {/* Category of farmers */}
                                  <Box paddingBottom={2}>
                                    <Field fullWidth name="keyBeneFrmrsCateg">
                                      {({ meta, form: { setFieldValue, values } }) => (
                                        <FormControl variant="outlined" fullWidth>
                                          <Autocomplete
                                            multiple
                                            size="small"
                                            options={keyBenefFarmersCatOptions}
                                            value={values.keyBeneFrmrsCateg}
                                            getOptionLabel={option => option}
                                            freeSolo
                                            renderTags={(value, getTagProps) =>
                                              value.map((option, index) => (
                                                <Chip
                                                  variant="outlined"
                                                  label={option}
                                                  {...getTagProps({ index })}
                                                />
                                              ))
                                            }
                                            onChange={(e: object, val: any) => {
                                              setFieldValue("keyBeneFrmrsCateg", val);
                                            }}
                                            filterSelectedOptions
                                            renderInput={params => (
                                              <TField
                                                {...params}
                                                variant="outlined"
                                                label="If farmers, which category"
                                                placeholder="Category"
                                              />
                                            )}
                                          />
                                          {meta.touched && meta.error && (
                                            <span className={classes.error}>{meta.error}</span>
                                          )}
                                        </FormControl>
                                      )}
                                    </Field>
                                  </Box>
                                  {/* Gender of farmers */}
                                  <Box paddingBottom={2}>
                                    <Field fullWidth name="keyBenefrmrsGender">
                                      {({ meta, form: { setFieldValue, values } }) => (
                                        <FormControl variant="outlined" fullWidth>
                                          <Autocomplete
                                            multiple
                                            size="small"
                                            options={keyBenefFarmersGenderOptions}
                                            value={values.keyBenefrmrsGender}
                                            getOptionLabel={option => option}
                                            freeSolo
                                            renderTags={(value, getTagProps) =>
                                              value.map((option, index) => (
                                                <Chip
                                                  variant="outlined"
                                                  label={option}
                                                  {...getTagProps({ index })}
                                                />
                                              ))
                                            }
                                            onChange={(e: object, val: any) => {
                                              setFieldValue("keyBenefrmrsGender", val);
                                            }}
                                            filterSelectedOptions
                                            renderInput={params => (
                                              <TField
                                                {...params}
                                                variant="outlined"
                                                label="If farmers, which gender?"
                                                placeholder="Category"
                                              />
                                            )}
                                          />
                                          {meta.touched && meta.error && (
                                            <span className={classes.error}>
                                              {meta.error}
                                            </span>
                                          )}
                                        </FormControl>
                                      )}
                                    </Field>
                                  </Box>
                                </>
                              ) : null}
                            </>
                          )}
                        </Field>
                      </Box>
                    </FormikStep>
                    {/* Capacity Needs */}
                    <FormikStep
                      label="Capacity Needs"
                      validationSchema={Yup.object({
                        capacityNeedFirstChoice: Yup.string(),
                        capacityNeedSecondChoice: Yup.string(),
                        capacityNeedThirdChoice: Yup.string()
                      })}
                    >
                      {/* 1st choice */}
                      <Box paddingBottom={2}>
                        <Field fullWidth name="capacityNeedFirstChoice">
                          {({
                            meta,
                            form: { setFieldValue, values }
                          }) => (
                            <FormControl variant="outlined" fullWidth>
                              <Autocomplete
                                size="small"
                                options={capacityNeedsOptions}
                                getOptionLabel={option => option}
                                value={values.capacityNeedFirstChoice}
                                filterSelectedOptions
                                onChange={(e: object, val: any) => {
                                  setFieldValue("capacityNeedFirstChoice", val);
                                }}
                                renderInput={params => (
                                  <TField
                                    {...params}
                                    variant="outlined"
                                    label="1st Choice"
                                    placeholder="Choice"
                                  />
                                )}
                              />
                              {meta.touched && meta.error && (
                                <span className={classes.error}>{meta.error}</span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      {/* 2nd choice */}
                      <Box paddingBottom={2}>
                        <Field fullWidth name="capacityNeedSecondChoice">
                          {({
                            meta,
                            form: { setFieldValue, values }
                          }) => (
                            <FormControl variant="outlined" fullWidth>
                              <Autocomplete
                                size="small"
                                options={capacityNeedsOptions}
                                getOptionLabel={option => option}
                                value={values.capacityNeedSecondChoice}
                                filterSelectedOptions
                                onChange={(e: object, val: any) => {
                                  setFieldValue("capacityNeedSecondChoice", val);
                                }}
                                renderInput={params => (
                                  <TField
                                    {...params}
                                    variant="outlined"
                                    label="2nd Choice"
                                    placeholder="Choice"
                                  />
                                )}
                              />
                              {meta.touched && meta.error && (
                                <span className={classes.error}>{meta.error}</span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      {/* 3rd choice */}
                      <Box paddingBottom={2}>
                        <Field fullWidth name="capacityNeedThirdChoice">
                          {({
                            meta,
                            form: { setFieldValue, values }
                          }) => (
                            <FormControl variant="outlined" fullWidth>
                              <Autocomplete
                                size="small"
                                options={capacityNeedsOptions}
                                getOptionLabel={option => option}
                                value={values.capacityNeedThirdChoice}
                                filterSelectedOptions
                                onChange={(e: object, val: any) => {
                                  setFieldValue("capacityNeedThirdChoice", val);
                                }}
                                renderInput={params => (
                                  <TField
                                    {...params}
                                    variant="outlined"
                                    label="3rd Choice"
                                    placeholder="Choice"
                                  />
                                )}
                              />
                              {meta.touched && meta.error && (
                                <span className={classes.error}>{meta.error}</span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                    </FormikStep>

                    {/* Summary of the information */}
                    <FormikStep
                      label="Summary"
                      validationSchema={Yup.object({})}
                    >
                      <h1>Summary of the information</h1>
                      <Field>
                        {({ form: { values } }) => (
                          <>
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                aria-label="simple table"
                              >
                                <TableBody>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Name of organization
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.name}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Type of Registration
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.typeOfReg}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Date of Incorporation/Registration
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {moment(values.dateOfIncorp).format("DD/MM/YYYY")}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Registration No. of Entity
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.regNoEntity}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Email Address
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.email}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Country
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.country}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Telephone
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.telephone}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Website
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.website}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Head Office District
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.headOfficeDistrict}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Head Office Division
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.headOfficeDiv}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Head Office Village
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.headOfficeVillage}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Head Office Street
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.headOfficeStreet}
                                    </TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Contact Name
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.contName}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Contact Position
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.contPosition}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Contact Telephone
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.contTelephone}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Contact Email
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.contEmail}
                                    </TableCell>
                                  </TableRow>

                                  {/* Staff members */}

                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Male Staff
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.numStaffMale}
                                    </TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Female Staff
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.numStaffFemale}
                                    </TableCell>
                                  </TableRow>

                                  {/* PHD Staff members */}
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      PHD Male
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.phdMales}
                                    </TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      PHD Female
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.phdFemales}
                                    </TableCell>
                                  </TableRow>

                                  {/* Masters Staff members */}

                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Masters Male
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.mastrsMales}
                                    </TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Masters Female
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.mastrsFemales}
                                    </TableCell>
                                  </TableRow>

                                  {/* Bsc Staff members */}
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Bsc Males
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.bscMale}
                                    </TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Bsc Females
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.bscFemale}
                                    </TableCell>
                                  </TableRow>

                                  {/* Dip Staff members */}
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Diploma Males
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.dipMale}
                                    </TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Diploma Females
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.dipFemale}
                                    </TableCell>
                                  </TableRow>

                                  {/* Certificate Staff members */}
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Certificate staff Male
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.certMale}
                                    </TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Certificate staff Female
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.certMale}
                                    </TableCell>
                                  </TableRow>
                                  {/* 
                                  sector: "",
                      agricSectorFocus: "",
                      focusValueChain: [],
                      focusEnterprises: [],
                      mission: "",
                      areaOpnTrgtDistricts: [],
                      centralDistricts: [],
                      eastDistricts: [],
                      westDistricts: [],
                      northDistricts: [],
                                  
                                  */}

                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Sector
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.sector}
                                    </TableCell>
                                  </TableRow>

                                  {values.sector.includes("Specific") && (
                                    <>
                                      <TableRow>
                                        <TableCell component="th" scope="row">
                                          Value chain focus level
                                    </TableCell>
                                        <TableCell component="th" scope="row">
                                          {values.agricSectorFocus}
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell component="th" scope="row">
                                          Focus Enterprise
                                    </TableCell>
                                        <TableCell component="th" scope="row">
                                          {values.agricFocusEnterp}
                                        </TableCell>
                                      </TableRow>
                                    </>)
                                  }

                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Mission
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.mission}
                                    </TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Area of Operation: Target Districts
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.areaOpnTrgtDistricts.join()}
                                    </TableCell>
                                  </TableRow>

                                  {values.areaOpnTrgtDistricts.includes("All") ||
                                    (values.areaOpnTrgtDistricts.includes("Central") && (
                                      <>
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            Central Districts
                                          </TableCell>
                                          <TableCell component="th" scope="row">
                                            {values.centralDistricts.join()}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}

                                  {values.areaOpnTrgtDistricts.includes("All") ||
                                    (values.areaOpnTrgtDistricts.includes("East") && (
                                      <>
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            East Districts
                                      </TableCell>
                                          <TableCell component="th" scope="row">
                                            {values.eastDistricts.join()}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}
                                  {values.areaOpnTrgtDistricts.includes("All") ||
                                    (values.areaOpnTrgtDistricts.includes("West") && (
                                      <>
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            West Districts
                                      </TableCell>
                                          <TableCell component="th" scope="row">
                                            {values.westDistricts.join()}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}
                                  {values.areaOpnTrgtDistricts.includes("All") ||
                                    (values.areaOpnTrgtDistricts.includes("North") && (
                                      <>
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            North Districts
                                      </TableCell>
                                          <TableCell component="th" scope="row">
                                            {values.northDistricts.join()}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}

                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Key Beneficiaries
                              </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.keyBenef.join()}
                                    </TableCell>
                                  </TableRow>

                                  {values.keyBenef === "Farmers" && (
                                    <>
                                      <TableRow>
                                        <TableCell component="th" scope="row">
                                          If farmers, which category
                                    </TableCell>
                                        <TableCell component="th" scope="row">
                                          {values.keyBeneFrmrsCateg.join()}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell component="th" scope="row">
                                          If farmers, which gender?
                                    </TableCell>
                                        <TableCell component="th" scope="row">
                                          {values.keyBenefrmrsGender.join()}
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  )}

                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      1st Choice
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.capacityNeedFirstChoice}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      2nd Choice
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.capacityNeedSecondChoice}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      3rd Choice
                                </TableCell>
                                    <TableCell component="th" scope="row">
                                      {values.capacityNeedThirdChoice}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>

                        )}
                      </Field>
                    </FormikStep>
                  </FormikStepper>
                </Grid>
              </Grid>
            </div>
          </>
        </Grid>
      </Grid>
    </>
  );
}

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, "children" | "validationSchema"> {
  label: string;
}

export function FormikStep({ children }: FormikStepProps) {
  return <>{children}</>;
}

export function FormikStepper({
  children,
  ...props
}: FormikConfig<FormikValues>) {
  const childrenArray = React.Children.toArray(children) as React.ReactElement<
    FormikStepProps
  >[];
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step] as React.ReactElement<
    FormikStepProps
  >;
  const [completed, setCompleted] = useState(false);

  const uuid = useSelector(state => state.organization.organizationID);
  const saved = useSelector(state => state.organization.saved);

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Formik
        {...props}
        validationSchema={currentChild.props.validationSchema}
        onSubmit={async (values, helpers) => {
          if (isLastStep()) {
            await props.onSubmit(values, helpers);
            setCompleted(true);
            //setStep(0)
          } else {
            setStep(s => s + 1);
          }
        }}
      >
        {({ isSubmitting, resetForm }) => (
          <Form autoComplete="off">
            <Stepper alternativeLabel activeStep={step}>
              {childrenArray.map((child, index) => (
                <Step
                  key={child.props.label}
                  completed={step > index || completed}
                >
                  <StepLabel>{child.props.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {currentChild}
            {completed && saved ? (
              <div>
                <Typography variant="body1">Generated Worker ID</Typography>
                <Typography variant="h3" color="primary">
                  {uuid}
                </Typography>
              </div>
            ) : null}
            {completed && (saved === false) ? (
              <div>
                <Typography variant="body1" color="error">
                  Something went wrong. Try again
                </Typography>
              </div>
            ) : null}
            {/* {step > 0 && !completed ? (
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                onClick={() => setStep(s => s - 1)}
              >
                Back
              </Button>
            ) : null} */}
            {completed && saved ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  resetForm();
                  setCompleted(false);
                  setStep(0);
                }}
              >
                New Form
              </Button>
            ) : <Button
              disabled={isSubmitting || step === 0}
              variant="contained"
              color="primary"
              onClick={() => setStep(s => s - 1)}
            >
              Back
              </Button>}
            &nbsp;
            <Button
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitting || (completed && saved)}
              variant="contained"
              color="primary"
              type="submit"
            >
              {isSubmitting
                ? "Submitting..."
                : isLastStep()
                  ? "Submit"
                  : "Next"}
            </Button>
          </Form>
        )}
      </Formik>
    </MuiPickersUtilsProvider>
  );
}
